import {ChartOptions, ChartType, ChartDataSets} from "chart.js";
import {Label} from "ng2-charts";
import {TimeGroupTip} from "./time-group-tip";
import {DatePipe} from "@angular/common";
//import * as pluginDataLabels from 'chartjs-plugin-data-laebls';
export class BarGraphModel {
	
	private timeGroupTips:Array<TimeGroupTip>;
	private currentCurrency:string = "";
	public conversionObject:any;
	private liteCoinPriceUSD:number;
	constructor(private timeGroupTipsIn:Array<TimeGroupTip>, private datePipe:DatePipe)
	{
		console.log("Here at bargraph Model");
		//this.timeGroupTips = Object.assign([], this.timeGroupTipsIn); 
		this.timeGroupTips = new Array<TimeGroupTip>();
		console.log(this.timeGroupTipsIn.length);
		var dateAdd:Date = null;
		for(var i = 0; i < this.timeGroupTipsIn.length; i++)
		{
			this.timeGroupTips.push(new TimeGroupTip(this.timeGroupTipsIn[i].getFromTipDate(),this.timeGroupTipsIn[i].getToTipDate(),this.timeGroupTipsIn[i].getActualTipAmount()));
			//console.log("i is " + i);
			console.log(this.datePipe.transform(timeGroupTipsIn[i].getFromTipDate(),"short") + " - " + this.datePipe.transform(timeGroupTipsIn[i].getToTipDate(),"short"));
			//this.timeGroupTips.push(this.timeGroupTipsIn[i]);
			if(dateAdd !=  this.timeGroupTipsIn[i].getFromTipDate() || dateAdd == null)
			{
		     	this.barChartLabels.push("" + this.datePipe.transform(timeGroupTipsIn[i].getFromTipDate(),"short") + " - " + this.datePipe.transform(timeGroupTipsIn[i].getToTipDate(),"short") + "" );
		        dateAdd = null;
			}
			if(dateAdd == null)
			{
				dateAdd = this.timeGroupTipsIn[i].getFromTipDate();
			}				
		}
		console.log(this.timeGroupTips);
         //this.updateBarChart();		
	}
	public barChartDataSets:ChartDataSets[] = [{
		data:[],
		label:"Tips Information"
	}];
	setCurrentCurrency(currency:string)
	{
		this.currentCurrency = currency;
	}
	setLiteCoinPriceUSD(price:number){
		this.liteCoinPriceUSD = price;
	}
	setConversionObject(object: any)
	{
		this.conversionObject = object;
	}
	convertCurrencyToAnother(currencyToConvertFrom:string,index_ofTip:number, index:number,ratio:number)
	{
		return ((this.timeGroupTipsIn[index_ofTip].getActualTipAmount() / ratio).toFixed(2));
	}
	updateBarChart()
	{
		this.barChartDataSets[0].data = [];
		var dataArray:Array<number> = new Array<number>();
		var tipDate:Date = null;
		var tipTotal:number = 0.0;
		var addEnter = false;
		for(var i =0; i < this.timeGroupTips.length; i++)
		{
			if(tipDate == null || tipDate !=  this.timeGroupTipsIn[i].getFromTipDate())
			{
				
				tipDate = this.timeGroupTipsIn[i].getFromTipDate();
				console.log(this.timeGroupTipsIn[i].getFromTipDate());
				if(i != 0)
				{
					addEnter = true;
				}
			}
			if((i + 1) == this.timeGroupTipsIn.length)
			{
				addEnter = true;
			}
			if(addEnter == true)
			{
			  	
			  dataArray.push(parseFloat(tipTotal.toFixed(2)));
			  tipTotal = 0.0;
			}
			if(tipDate == this.timeGroupTipsIn[i].getFromTipDate())
			{
				if(this.currentCurrency.toLowerCase() != "usd"){
                    console.log(this.liteCoinPriceUSD);
					tipTotal += (this.timeGroupTipsIn[i].getActualTipAmount() * this.liteCoinPriceUSD) *
					((this.conversionObject[this.currentCurrency.toLowerCase()])["rate"]);
				 }
			     else{
					tipTotal +=  this.timeGroupTipsIn[i].getActualTipAmount() * this.liteCoinPriceUSD;
				 }
				//tipTotal += this.timeGroupTipsIn[i].getTipAmount();
				/*for(var x = 0; x < this.conversionObject.length; x++)
				{
					var object = this.conversionObject[x];
					//console.log("Object currency is " + object["currency"]);
					//console.log(this.timeGroupTipsIn[i].getShortCurrency());
					if(object["currency"] == this.timeGroupTipsIn[i].getShortCurrency() )
					{
						console.log("currency is " + object["currency"] );
						console.log("rate is " + object["rate"]);
						
						if(object["rate"] != null)
						{
							console.log(Number((this.convertCurrencyToAnother(this.timeGroupTipsIn[i].getShortCurrency(),i,x, (object["rate"] as number)))));
							tipTotal +=  Number((this.convertCurrencyToAnother(this.timeGroupTipsIn[i].getShortCurrency(),i,x, (object["rate"] as number))));
						}
						else
						{
							tipTotal += Number(this.convertCurrencyToAnother(this.timeGroupTipsIn[i].getShortCurrency(),i,x,1));
						}
					}
				}*/
				
			}
			
			
			
			addEnter = false;
		    
		}
		this.barChartDataSets[0].data = Object.assign([],dataArray);
		console.log("data is " + dataArray);
	}
	public barChartOptions:ChartOptions ={
		responsive:true,
		scales :{xAxes:[{display:false,ticks:{fontSize:9}}], yAxes:[{display:true,ticks:{fontSize:15}}]},
		plugins:{
			datalabels:{
				anchor:'end',
				align:'end',
			}
		}
	};
	
	public barChartLabels :Label[] = [];
	public barChartType:ChartType = "bar";
	
	public barChartLegend = true;
	//public barChartPlugins = [pluginDataLabels];
	
	
	
}
