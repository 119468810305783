import{trigger,transition,state,style,animate} from "@angular/animations";

export function slide()
{
 return trigger("slideLeft",[
                state('*', style({position:'absolute',width:'100%'})),
				state('void',style({position:'absolute',width:'100%'})),
				transition("* => void",[
				          style({transform:'translateX(0%)', opacity:1}),
						  animate('1s ease-in-out',style({transform:'translateX(-100%)',opacity:1}))]),
				transition("void => *", [
						  style({transform:'translateX(-100%)',opacity:1}),
						  animate('1s ease-in-out',style({transform:'translateX(0%)',opacity:1}))
				])
			]);
}
export function slide2()
{
	return trigger("slideRight",[
	               state('*', style({position:'absolute',width:'100%'})),
				   state('void', style({position:'absolute',width:'100%'})),
				   transition('* => void', [
				              style({transform:'translateX(100%',opacity:1}),
							  animate('4s ease-in-out', style({transform:'translateX(0%)',opacity:1}))		  
				   ]),
				   transition('void => *',[
					                      style({transform:'translateX(100%)',opacity:0}),
                                          animate('4s ease-in-out', style({transform:'translateX(0%)',opacity:1}))										  
				   ])
	]);
}
export function slideLeft()
{
  return slide();
}
export function slideRight()
{
	return slide2();
}