import { Component, OnInit,Input,ElementRef,ViewChild } from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";
import {Subject} from 'rxjs';
import {urlStanderizer} from "./../../exportedValues/verify-url-functions";
@Component({
  selector: 'website-form',
  templateUrl: './website-form.component.html',
  styleUrls: ['./website-form.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class WebsiteFormComponent implements OnInit {

  @Input() showReceiver:Subject<String>;
  @ViewChild("errorLi",{static:false}) errorLi:ElementRef; 
  public webSite:string = "";
  public timeVerify:Number;
  constructor(private ngxSmartModalService:NgxSmartModalService) {
     
  }

  ngOnInit() {
  }
  ngOnChanges(changes)
  {
	  if(changes['showReceiver'] && this.showReceiver != null)
	  {
		  this.showReceiver.subscribe((value:String) =>{
			  switch(value)
			  {
				  case "open":
				              this.ngxSmartModalService.getModal("websitesubmissionForm").open(); 
							  break;
			  }
			
		  });
	  }
  }
  
  submitForm()
  {
	  var errors = "";
	  if(this.timeVerify <= 0 ||  this.timeVerify == null)
	  {
		 errors += "Please enter minutes greater than 0 <br/>";
	  }
	  console.log(this.webSite);
	  var standerizedWebsite = urlStanderizer(this.webSite);
	  if(standerizedWebsite != null)
	  {
		  this.webSite = standerizedWebsite;
	  }
	  console.log(this.webSite);
	  if(standerizedWebsite == "null")
	  {
		   errors += "This is not a valid website please check the video located above for information on valid websites <br/>";
	  }
	  this.errorLi.nativeElement.innerHTML = errors;
	  if(errors == "")
	  {
		this.ngxSmartModalService.getModal("websitesubmissionForm").close();
		var jsonObject:any = {"website":this.webSite,
		                       "timeVerify":this.timeVerify};
		console.log(jsonObject);					   
		this.showReceiver.next(JSON.stringify(jsonObject));
		this.ngxSmartModalService.getModal("websitesubmissionForm").close(); 
	  }
  }

}
