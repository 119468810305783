import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import {slideRight,slideLeft} from "./../../../animations/SlideAnimationsComponentTransition.animation";
import {popper} from "./../../../animations/popanimation.animation";
import { NgxSmartModalService } from 'ngx-smart-modal';
import {Inject} from "@angular/core";
import{UserInformationService} from "./../../services/user-information.service";
import {Router,ActivatedRoute}  from "@angular/router";
import{TipplyContentpara} from "./../../exportedValues/tipply-contentpara";
import {Subject} from "rxjs";
import {DOCUMENT} from "@angular/common";
import { LoadingBarService } from '@ngx-loading-bar/core';
import {timer} from "rxjs";
import * as Freezeframe from "freezeframe/dist/freezeframe.min.js";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
//import * as SuperGif from 'libgif';

@Component({
  selector: 'app-landing',
  templateUrl: './landing2.component.html',
  styleUrls: ['./landing.component.css','./style.css','./../../../../css/semantic-ui-css-master/semantic.min.css'],
  animations:[slideRight(),popper()]//,
 // host: {'[@slideRight]':''}
})
export class LandingComponent implements OnInit {

  classB:String ="yellow";
  classC:String ="black";
  pop:String = "popin";
  popArray:Array<String>;
  PopupContent:String = "dfsdf";
  tipplyContentpara:TipplyContentpara;
  loginModalClasses:Array<string> = ["large-modal"];
  connectionToErrorModal:Subject<{[key:string]:string}>;
  connectionToMiscellaneousModal:Subject<{[key:string]:string}>;
  loginErrors:string;
  logo:any;
  modalPrompt:string = "Log into tiplly with google";
  modalHeader:string = "Log into tiplly";
  @ViewChild("errorLi",{static:false}) errorLi:ElementRef;
  @ViewChild("preElem",{static:false}) preElem:ElementRef;
  constructor(@Inject(DOCUMENT)private documente, public loadingBarService:LoadingBarService, public ngxSmartModalService: NgxSmartModalService,private userInformationService:UserInformationService, private router:Router, private route:ActivatedRoute) 
  {
	
    this.tipplyContentpara = new TipplyContentpara();
	this.popArray = new Array<String>();
	for(var i = 0; i < 8; i++)
	{
		this.popArray.push("popin");
	}
	this.connectionToErrorModal = new Subject<{[key:string]:string}>();
    this.connectionToMiscellaneousModal = new Subject<{[key:string]:string}>();	
  }
  
  ngAfterViewInit()
  {
	  
	  window.scrollTo(0,0);	
	  this.route.queryParams.subscribe((value) => {
		  console.log(value);
		   if(value["reason"])
	     {
		  var reason = ((value["reason"] as unknown) as string);
		  console.log("reason is " + reason);
		  if(reason == "timeout")
		  {
			this.connectionToErrorModal.next({"show":"SessionExpired"});
		  }
		 
		 }
		  if(value["mode"] == "successfulReturn")
		  {
			  console.log("sent emission");
			  this.connectionToMiscellaneousModal.next({"show":"successfulReturn"});
		  }
	  });
	  console.log(this.documente);
	  console.log(this.documente.getElementById("dive1"));
	
  }
  
  ngOnInit() {
   AOS.init( {
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 300, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
 //   AOS.refresh();
   this.userInformationService.getUserInformationSubject().subscribe((result) => {
     
	 if(result["signedIn"] == "complete")
	 {
	  this.loadingBarService.complete();	 
	  this.router.navigate(["/home/profile/tipllyInformation"]);
	 }
	 else if (result["signedIn"] == "started")
	 {
		  this.logo.start();
		  this.logo.start();
		  this.loadingBarService.start();
		  this.errorLi.nativeElement.innerHTML ="";
	 }
	  else if (result["err"])
	 {
		  this.logo.stop();
		  this.loadingBarService.complete();
		  this.errorLi.nativeElement.innerHTML = "<span style = 'vertical-align:left'>Failed to sign in / sign up. <br/> Try again later.</span>";
	 }
   });

	  
  }
  openModal(which:string)
  {
	   if(which == "signup"){
		  this.modalPrompt = "Sign up with tiplly through google";
		  this.modalHeader = "Sign up to use tiplly";
	  }
	  else{
		  this.modalPrompt = "Login into tiplly through google";
		  this.modalHeader = "Log into tiplly";
	  }
	  this.ngxSmartModalService.get("LoginModal").open();
	  window.scrollTo(0,0);
	  timer(25).subscribe(() =>{
		if(this.logo == null){  
		 this.logo = new Freezeframe('.freezeframe', {
			  trigger: false
		  });
		}
		  console.log(this.logo);
		  this.logo.stop();
	  });
  }
  signInwithGoogle()
  {
	 
	  
	  this.userInformationService.signInWithGoogle();
	  console.log("called1");
  }
  
  onchangeb(i:number)
  {
	  this.classC = this.classC == "black" ? "yellow" : "black";
	  this.popArray[i] = this.popArray[i] == "popin" ? "popout" : "popin"; 
  }
  onchange()
  {
	  this.classB = this.classB == "yellow" ? "black" : "yellow";
  }
  onchanged()
  {
	  
  }
  
  navigateToChromePlugin()
  {
	  window.location.href = "https://chrome.google.com/webstore/detail/tiplly-plugin/jhpndhocaaadnedgppococbneomfgfoo";
  }

}
