import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import {FormGroup,FormArray} from "@angular/forms";
import {Subject} from "rxjs";
import {NgxSmartModalService} from "ngx-smart-modal";
@Component({
  selector: 'show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.css']
})
export class ShowErrorsComponent implements OnInit {

  ErrorsArray:Array<String> = new Array<String>();
  @Input() formArray:Subject<Array<FormGroup>>;
  @Output() emitToServer:EventEmitter<string>;
  constructor(private ngxSmartModalService:NgxSmartModalService) {
   this.emitToServer = new EventEmitter<string>();    
  }

  ngOnInit() {
  }
  ngOnChanges(changes)
  {
	
	 if(changes["formArray"] && this.formArray)
	 {
		 
		 this.formArray.subscribe((value) => {
			  this.ErrorsArray = [];
		 	 console.log(value); 
			 for(var i = 0; i < 3; i++){	
            // var values = value[i +""];	
             var formGroup = value[i];			 
		     for(const field in  ((formGroup) as FormGroup).controls )
			 {
				 console.log(field);
				 const control = ((formGroup) as FormGroup).get(field);
				 if(control != null){
			     console.log("Control is"); 
			     console.log(control);		 
				 if(control.status == "INVALID")
				 {
					 console.log("dirty");
					 this.ErrorsArray.push("enter a value for " + field + ": value " );
				 }
				 }
			 }
			 }
			if(this.ErrorsArray.length > 0)
			{
			 this.ngxSmartModalService.getModal('ErrorModal').open();
			  console.log("called open modal");
			}
			else{
			 this.emitToServer.emit("affirmative");
			}
		 });
		 
	 }
  }
}
