import { Injectable,Inject } from '@angular/core';
import {Response} from "@angular/http";
import {HttpClient} from "@angular/common/http";
import {UserInformationService} from "./user-information.service";
import {SecurityService} from "./security.service";
import {Website} from "./../Models/website.model";
import {PendingWebsite} from "./../Models/pending-website.model";
import {Subject} from "rxjs";

@Injectable({providedIn: 'root'})
export class WebsiteService {
   
  pushWebsitearray:Subject<Array<Website>>; 
  pushWebsiteCount:Subject<Number>;
  pushPendingWebsiteCount:Subject<Number>;
  pushPendingWebsitearray:Subject<Array<PendingWebsite>>;
  pushFailedWebsiteCount:Subject<Number>;
  pushFailedWebsiteArray:Subject<Array<Website>>;
  websiteErrorSubject:Subject<{[key:string]:string}>;
  websiteSuccessSubject:Subject<{[key:string]:string}>;
  baseUrl_:string = "";
  constructor(private userInformationService:UserInformationService, private securityService:SecurityService,private http:HttpClient, @Inject("base_href") base_href:string) {
    this.pushWebsitearray = new Subject<Array<Website>>();
	this.pushWebsiteCount = new Subject<Number>();
	this.pushPendingWebsiteCount = new Subject<Number>();
	this.pushFailedWebsiteCount = new Subject<Number>();
	this.pushPendingWebsitearray = new Subject<Array<PendingWebsite>>();
	this.pushFailedWebsiteArray = new Subject<Array<Website>>();
	this.websiteErrorSubject = new Subject<{[key:string]:string}>();
	this.websiteSuccessSubject = new Subject<{[key:string]:string}>();
	this.baseUrl_ = `${base_href}/website`;
  }
  
  public getWebsiteSuccessSubject()
  {
	  return this.websiteSuccessSubject;
  }
  
  public getSubjectwebsiteObservable()
  {
	  return this.pushWebsitearray;
  }
  public getFailedWebsitesObservable()
  {
	  return this.pushFailedWebsiteArray;
  }
  public getFailedWebsiteCountObservable()
  {
	  return this.pushFailedWebsiteCount;
  }
  public getWebsiteCountObservable()
  {
	  return this.pushWebsiteCount;
  }
  public getPendingWebsiteCount()
  {
	  return this.pushPendingWebsiteCount;
  }
  public getPendingWebsiteObservable()
  {
	  return this.pushPendingWebsitearray;
  }
  public getWebsiteErrorObservable()
  {
	  return this.websiteErrorSubject;
  }
  public deleteUserWebsite(website:string)
  {
	  var username = this.userInformationService.getTipUsername();
	  var url = `${this.baseUrl_}/DeleteWebsite?username=${username}&website=${website}`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.delete(url,{ headers:headerOptions, observe: "response"}).subscribe((success) =>{
		 console.log(success); 
		 this.websiteSuccessSubject.next({"deleteWebsite":"success"});
	  },(err) =>{
		  if(err.status == 417 )
		  {
		    this.websiteErrorSubject.next({"DeleteWebsiteError":"Error"});
		  }
		  else 
		  {
			   this.websiteErrorSubject.next({"DeleteWebsiteError":"ServerError"});
		  }
		  console.log(err);
	  });
  }
  public getUserFailedWebsites(page:number)
  {
	  var username = this.userInformationService.getTipUsername();
	  var url = page == 1 ? `${this.baseUrl_}/getFailedAuthenticatedWebsites/${username}` : `http://localhost:8080/Website/getFailedAuthenticationWebsites?page=${page}`;
      var listOfFailedWebsites:Array<Website> = new Array<Website>();
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(url, { headers:headerOptions }).subscribe((success) =>{
		  console.log(success);
		  var body = ((success as unknown) as any);//["_body"];
		  if(body != null){
		   var websiteJson = /*JSON.parse(*/body;//);
		  
		  for(var i = 0; i < websiteJson.length; i++)
		  {
			  var website:Website = new Website(websiteJson[i].website,websiteJson[i].verified,websiteJson[i].website_code);
			  website.setDate(new Date(websiteJson[i].added_when));
			  listOfFailedWebsites.push(website);
		  }
		  }
		 this.pushFailedWebsiteArray.next(listOfFailedWebsites);
	  },
	  (error) =>{
		  this.websiteErrorSubject.next({"UserFailedWebsites":"Failed to retrive user websites"});
		  console.log(error);
	  }
	  );
  }
  public getUserCountOfFailedWebsites()
  {
	  var username = this.userInformationService.getTipUsername();
	  var url = `${this.baseUrl_}/GetCountOfFailedAuthenticatedWebsites/${username}`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(url,{ headers:headerOptions }).subscribe((success) =>{
		  var count = ((success as unknown) as number);//["_body"];
		  console.log(success);
		  this.pushFailedWebsiteCount.next(count);
	  });
  }
  public getUserCountOfOwnedWebsites()
  {
	  var username = this.userInformationService.getTipUsername();
	  var url = `${this.baseUrl_}/GetCountOfWebsites/${username}`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(url,{ headers:headerOptions }).subscribe((success) =>{
		  var count:Number = ((success as unknown) as number);//["_body"];
		  console.log("Number of websites are :");
		  console.log(success);
		  console.log(success["_body"]);
		  this.pushWebsiteCount.next(count);
		 
	  });
	  
	  
  }
  public getUserCountOfPendingWebsites()
  {
	  var username = this.userInformationService.getTipUsername();
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  var url = `${this.baseUrl_}/GetCountOfPendingWebsites/${username}`;
	  this.http.get(url,{ headers:headerOptions }).subscribe((success) => {
		 var count:Number = ((success as unknown) as number);//["_body"];
         console.log("Number of pending websites are:");
         console.log(success);
         console.log(success["_body"]);
		 this.pushPendingWebsiteCount.next(count);
             		 
	  });
	  
  }
  public getUserPendingVerificationWebsites(page:Number)
  {
	  var username = this.userInformationService.getTipUsername();
	  var websiteList:Array<PendingWebsite> = new Array<PendingWebsite>();
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  var url = page == 1 ? `${this.baseUrl_}/GetPendingWebsites/${username}` : `${this.baseUrl_}/GetPendingWebsites/${username}?page=${page}`;
       console.log(url);	   
  	 this.http.get(url,{ headers:headerOptions }).subscribe((success) => {
		  var websiteJson = /*JSON.parse(*/success as unknown as any;//["_body"]//);
		  console.log("Pending website is");
		  console.log(websiteJson);
		  for(var i = 0; i < websiteJson.length; i++)
		  {
			  var temp_website:PendingWebsite = new PendingWebsite(websiteJson[i].websiteName, new Date( websiteJson[i].addedWhen), websiteJson[i].websiteCode, (websiteJson[i].minutesToCheck as number));
			  
			  console.log("Pending website is");
			  console.log(temp_website);
			  websiteList.push(temp_website);
		  }
		  this.pushPendingWebsitearray.next(websiteList);
	  },(err) =>{
		  this.websiteErrorSubject.next({"userPendingWebsite":"Failed to retrieve pending websites"});
	  });
	  
	 
  }
  public getUserWebsites(page:Number):Array<Website>
  {
	  var username = this.userInformationService.getTipUsername();
	  var websiteList:Array<Website> = new Array<Website>();
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  console.log(headerOptions);
	  var url = page == 1 ?  `${this.baseUrl_}/GetVerifiedWebsites/${username}` :`${this.baseUrl_}/GetVerifiedWebsites/${username}?page=${page}` ;
     
	 this.http.get(url,{ headers:headerOptions }).subscribe((success) => {
	         var websiteJson = /*JSON.parse(*/success as unknown as any;//["_body"]);
			 console.log("Json object is");
			 console.log(websiteJson);
			 for(var i = 0; i < websiteJson.length; i++)
			 {
				 var temp_website:Website = new Website(websiteJson[i].website,websiteJson[i].verified,websiteJson[i].website_code);
				 console.log("temp website is");
				 console.log(temp_website);
				 websiteList.push(temp_website);
			 }
       		 console.log("Got websites");
			 console.log(success);
			 console.log("Website List is");
			 console.log(websiteList);
	  this.pushWebsitearray.next(websiteList);
	  },(err) =>{
		 this.websiteErrorSubject.next({"UserWebsites":"Failed to get User websites"}); 
		 if(err.status == 404)
		 {
			 console.log("no more websites");
		 }			 
	     console.log("Err getting websites");
		 console.log(err);
	  });
	 
	  return websiteList;
  }
  public addNewWebsite(website:string,time:number)
  {
	  console.log("called");
	  var result:Number;
	  var username = this.userInformationService.getTipUsername();
	  var jsonObject = {"username":username,"website":website};
	  console.log(jsonObject);
	  var headerOptions = this.securityService.generateAndReturnPostHeaders();
	  
	  var url = `${this.baseUrl_}/addWebsite?minutes=${time}`;
	 this.http.post(url,JSON.stringify(jsonObject),{ headers:headerOptions }).subscribe((success) => {
	                      console.log(success);	 
						  result = 1;
	   this.websiteSuccessSubject.next({"addedWebsite":"success"});
	 }, (err) => {
		 if(err.status == 409)
		 {
			 this.websiteErrorSubject.next({"AddedWebsite":"Conflict"}); 
		 }
		 else if (err.status == 423)
		 {
			 this.websiteErrorSubject.next({"AddedWebsite":"Overloaded"});
		 }
		 else {
		     this.websiteErrorSubject.next({"AddedWebsite":"Server Error"}); 
		 }
		
		 
	}, () =>{
  
  
	})
	
  }
  
  public checkIfUserOwnsWebsite(website:string)
  {
	  var username = this.userInformationService.getTipUsername();
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  var url = `${this.baseUrl_}/checkIfUserHasWebsite?username=${username}&website=${website}`;
	  console.log(url);
	  this.http.get(url).subscribe((success) =>{
		  console.log(success);
		  this.websiteSuccessSubject.next({"checkWebsite":"success"});
	  },(err) =>{
		  console.log(err);
		  this.websiteErrorSubject.next({"checkWebsite":"Error"}); 
	  });
  }
}
