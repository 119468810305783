import { Component, OnInit,EventEmitter,Input, ViewChild, ElementRef } from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";
@Component({
  selector: 'input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class InputModalComponent implements OnInit {
  @Input() eventEmitterConnector:EventEmitter<{[key:string]:string}>;
  @ViewChild("coinAddressBox",{static:false}) inputBox:ElementRef;
  errors:string;
  constructor(private ngxSmartModalService:NgxSmartModalService) { 
   
  }

  ngOnInit() {
     
  }
  ngOnChanges(changes){
   	  
   if(changes["eventEmitterConnector"]){
     this.eventEmitterConnector.subscribe((value) =>{
		console.log(value); 
		if(value["modal"] == "close"){
			this.ngxSmartModalService.get("inputModal").close();
		}
        else{
			this.ngxSmartModalService.get("inputModal").open();
		} 		
	 });	  
   }
  }
  sendResults(address:string):void
  {
	  console.log(address);
	  this.errors = "";
	  if(address.length > 0){
		  this.eventEmitterConnector.next({"finished":address});
		  this.ngxSmartModalService.get("inputModal").close();
	  }
	  else{
	    this.errors = "please input litecoin address";
	  }
  }
}
