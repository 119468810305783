import { Component, OnInit,Input } from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";
import {Subject, Observable} from "rxjs";
@Component({
  selector: 'loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class LoadingModalComponent implements OnInit {
  
  public loadingText:string;
  public closable:boolean = false;
  public isLoadingText:boolean;
  @Input() public subjectObservable:Subject<{[key:string]:string}>;
  @Input() public updatesToLoadingObservable:Subject<string>;
  constructor(private ngxSmartModalService:NgxSmartModalService) { 
     
  }
  ngOnChanges(changes)
  {
	  if(changes["subjectObservable"])
	  {
		console.log("subject Observable changed");  
		this.subjectObservable.subscribe((value:{[key:string]:string}) => {
			this.loadingText = value["Loading"];
			console.log("loading called");
			console.log(`loading ${this.loadingText}`);
			this.isLoadingText = true;
			this.ngxSmartModalService.get("loadingModal").open();
			
			if(this.loadingText == "PlainLoading")
			{
				console.log("loading called");
				this.isLoadingText = false;
			}
			
		});  
	  }
	  if(changes["updatesToLoadingObservable"])
	  {
		  this.updatesToLoadingObservable.subscribe((value:string) =>{
			 this.loadingText = value;
		     this.isLoadingText = true;		
			 if(value == "GotInformationNowClose")
			 {
				 console.log("here at closing");
				 this.ngxSmartModalService.get("loadingModal").close();
			 }
		  });
	  }
  }
  ngOnInit() {
  }

}
