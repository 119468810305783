
export function urlStanderizer(url:string):string
{
	console.log(url);
	if(url.includes("youtube.com"))
	{
		return youtubeStanderizer(url);
	}
	else if(url.includes("bilibili.com"))
	{
		return bilibiliStanderizer(url);
	}
	else if (url.includes("iqyiy.com"))
	{
		return iqyiyStanderizer(url);
	}
	else if (url.includes("veoh.com"))
	{
		return veohStanderizer(url);
	}
	else if (url.includes("metacafe.com"))
	{
		return metacafeStanderizer(url);
	}
	else if (url.includes("pandora.tv"))
	{
		return pandoraTvStanderizer(url);
	}
	else if (url.includes("tv.naver.com"))
	{
		return tvNaverStanderizer(url);
	}
	else if (url.includes("vqq.com"))
	{
		return vqqStanderizer(url);
	}
	else {
	  return "null";
	}
}
function youtubeStanderizer(url:string):string
{
	console.log(url);
	if(url.includes("http://")  && (!url.includes("https://")))
	{
		url.replace("http://","https://");
	}
	var youtube_video_id = url.match(/(v=[0-9a-zA-Z_-])\S[^&]+/)[0];
	var youtube_url = "https://www.youtube.com/watch?" + youtube_video_id;
	return youtube_url;
}

export function bilibiliStanderizer(url:string):string
{
	if(url.includes("http://") && (!url.includes("https://")))
	{
		url.replace("http://","https://");
	}
	var bilibiliurl = url.replace(/\/[?][a-z_=0-9.]+/g,"");
	return bilibiliurl;
}

function iqyiyStanderizer(url:string):string
{
	if(url.includes("http://") && (!url.includes("https://")))
	{
		url.replace("http://","https://");
	}
	var iqyiy_url = url.replace(/\?(?<=\?).*$/,"");
	return iqyiy_url;
}

function veohStanderizer(url:string):string
{
   if(url.includes("http://") && (!url.includes("https://")))
	{
		url.replace("http://","https://");
	}
   return url;   
}
function metacafeStanderizer(url:string):string
{
  if(url.includes("http://") && (!url.includes("https://")))
	{
		url.replace("http://","https://");
	}
   return url;   
}
 function pandoraTvStanderizer(url:string):string
{
	if(url.includes("http://") && (!url.includes("https://")))
	{
		url.replace("http://","https://");
	}
	var pandoraTvUrl = url.replace(/#[0-9]+[0-9]+_new+/ ,"");
	return pandoraTvUrl;
}
 function tvNaverStanderizer(url:string):string
{
   if(url.includes("http://") && (!url.includes("https://")))
	{
		url.replace("http://","https://");
	}
   var tvNaverUrl = url.replace(/\/list[\/][0-9]+/g,"");
   
   return tvNaverUrl;   
}
 function vqqStanderizer(url:string):string
{
	 if(url.includes("http://") && (!url.includes("https://")))
	{
		url.replace("http://","https://");
	}
   return url;
}
