import { Component, OnInit,Input } from '@angular/core';
import {TipExecutionService} from "./../../services/tip-execution-service.service";
import {ChargeTip} from "./../../Models/charge-tip.model";
import {Receiptant} from "./../../Models/receiptant.model";
import {Receipt} from "./../../Models/receipt.model";
import {Subject} from "rxjs";
@Component({
  selector: 'receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class ReceiptComponent implements OnInit {
  
  buttonClass:Array<string> = ['ui','basic','blue','button'];
  _tip:ChargeTip;
  connectionToInputDialog:Subject<{[key:string]:string}>;
  connectionToUpdatesObservable:Subject<string>;
  connectionToLoadingObservable:Subject<{[key:string]:string}>;
  connectionToSuccessObservable:Subject<{[key:string]:string}>;
  connectionToErrorObservable:Subject<{[key:string]:string}>;
  receipt:Receipt;
  constructor(private tipExecutionService:TipExecutionService) {
     this.connectionToInputDialog = new Subject<{[key:string]:string}>();
	 this.connectionToUpdatesObservable = new Subject<string>();
	 this.connectionToLoadingObservable = new Subject<{[key:string]:string}>();
     this.connectionToSuccessObservable = new Subject<{[key:string]:string}>();
     this.connectionToErrorObservable = new Subject<{[key:string]:string}>();
 }

  ngOnInit() {
	   this.tipExecutionService.gettipExecutionServiceSubjectObservable().subscribe((value) =>{
      console.log("we have received value from tipExecution");  
      this.connectionToUpdatesObservable.next("GotInformationNowClose"); 	  
	if(value["emailReceipt"]){	  
	   
	 }	
	
	});
	
	{
	  var chargeTipJson= JSON.parse(this.tipExecutionService.getReceipt());//JSON.parse(((value["chargeTip"])));
	  this._tip = new ChargeTip(chargeTipJson["tip_uuid"],chargeTipJson["tip_amount"],chargeTipJson["currency"], chargeTipJson["tip_note"],chargeTipJson["website"]);
	  var array_of_Receipitants:Array<Receiptant> = new Array<Receiptant>();
	  var receiptant:Receiptant = new Receiptant("Direct Tip", (chargeTipJson["tip_amount"] as number));
	  array_of_Receipitants.push(receiptant);
	  console.log(array_of_Receipitants);
	  this.receipt = new Receipt(chargeTipJson["tip_uuid"],chargeTipJson["currency"],array_of_Receipitants);
	  console.log(this.receipt);	
	}
	
	this.tipExecutionService.gettipExecutionServiceSubjectObservable().subscribe((value) =>{
		console.log(value);
		if(value["emailReceipt"])
		{
			 this.connectionToSuccessObservable.next({"show":"open"});
		} else if(value["error"])
	 {
		 this.connectionToErrorObservable.next({"show":"retrieveError"});
	 }
	});
	this.connectionToInputDialog.subscribe((value) =>{
		if(value["returnValue"])
		{
			var emailToSend:string = (value["returnValue"] as string);
			console.log(emailToSend);
			this.connectionToLoadingObservable.next({"Loading":"Attempting To Send Email"});
			console.log(this.receipt);
			this.tipExecutionService.emailReceipt(this.receipt,emailToSend);
		}
	});
  }
  openEmailDialog()
  {
	  console.log("we have sent next");
	  this.connectionToUpdatesObservable.next("Email");
	  this.connectionToInputDialog.next({"InputDialog":"show"});
	  
  }
  buttonHovered()
  {
	  this.buttonClass = ['ui','blue','button'];
  }
  buttonOffHovered()
  {
	  this.buttonClass = ['ui','basic','blue','button'];
  }
}
