import { Component, OnInit,Output,EventEmitter,ViewChild,ElementRef } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {ArticleService} from "./../../services/article-service.service";
import {Article} from "./../../Models/article.model";
import {Miscellaneous} from "./../../exportedValues/miscellaneous";
import {Subject} from "rxjs";

@Component({
  selector: 'article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class ArticleListComponent implements OnInit {

  currentPageOfArticles:number;
  count_of_articles:number;
  array_of_pages_of_articles:Array<number>;
  current_array_of_articles:Array<Article>;
  isSearch:boolean = false;
  connectionToModal:Subject<{[key:string]:string}>;
  updatesToModal:Subject<string>;
  @Output("ArticleEvent") articleEvent:EventEmitter<string>;
  @Output("articleCount") articleCountEvent:EventEmitter<number>;
  @ViewChild("articleInput",{static:false}) inputElementRef:ElementRef;
  showPagination:boolean = true;
  miscellaneous:Miscellaneous;
  constructor(private articleService:ArticleService, private router:Router, private route:ActivatedRoute) 
  {
	  this.array_of_pages_of_articles = new Array<number>();
	  this.articleEvent = new EventEmitter<string>();
	  this.articleCountEvent = new EventEmitter<number>();
	  this.connectionToModal = new Subject<{[key:string]:string}>();
	  this.updatesToModal = new Subject<string>();
	  this.miscellaneous = new Miscellaneous();
  }

  ngOnInit() {
	  
	  this.articleService.getArticleInformationSubject().subscribe((value) => {
		  
		  if(value["count_of_articles"])
		  {
			  console.log("received count of articles");
			  this.array_of_pages_of_articles = [];
			  var pages_of_articles = (((value["count_of_articles"] as unknown) as number)/5);
			  this.count_of_articles =((value["count_of_articles"] as unknown) as  number);
			  this.articleCountEvent.emit(this.count_of_articles);
			  for(var i = 0; i < pages_of_articles; i++)
			  {
				  this.array_of_pages_of_articles.push(1);
			  }
		  }
		  
	  });
	  this.articleService.getArticleListSubject().subscribe((value) =>{
		 
	   	 this.current_array_of_articles = [];
		  this.updatesToModal.next("GotInformationNowClose");
		 this.current_array_of_articles = (value as Array<Article>).map((value) =>{
			return value; 
		 });
  		 
	  })
	  this.articleService.getCountOfArticles();
	  this.articleService.getListOfArticles(1);
	  
  }
  changePagesOfArticles(page:number)
  {
	  this.currentPageOfArticles = page;
	  this.articleService.getListOfArticles(page);
	 
  }
  resetArticles()
  {
	  this.currentPageOfArticles = 1;
	  this.connectionToModal.next({"Loading":"PlainLoading"});
	  this.inputElementRef.nativeElement.value = "";
	  this.articleService.getListOfArticles(1);
	  this.showPagination = true;
  }
  getArticlesBySearchQuery(query:string)
  {
	  this.articleService.getArticlesBySearchQuery(query);
	  this.connectionToModal.next({"Loading":"PlainLoading"});
	  this.showPagination = false;
  }
  receiveArticle(e:string)
  {
	  this.router.navigate(["/","pdfView",e]);
	  console.log(e);
  }

}
