import {TimeGroupTip} from "./time-group-tip";
import{ChartDataSets,ChartOptions} from "chart.js";
import {DatePipe} from "@angular/common";
import {Label,Color} from "ng2-charts";
export class LineGraphModel {
	private timeGroupTips:Array<TimeGroupTip>;
	private liteCoinPriceUSD:number;
	public  lineChartData:ChartDataSets[] = [
	{data:[0],label:'Tips'}
	];
	public lineChartType:string = "line";
	public lineChartLegend:boolean = true;
	public lineChartColors:Color[] = [{borderColor:'black',
									   backgroundColor:'rgba(255,0,0,0.3)'}];
	public lineChartOptions:ChartOptions ={
		responsive:true,
		scales :{xAxes:[{display:false,ticks:{fontSize:9}}], yAxes:[{display:true,ticks:{fontSize:15}}]},
		plugins:{
			datalabels:{
				anchor:'end',
				align:'end',
			}
		}
	};
	public lineChartLabels:Label[] = [];
	public current_currency:string ="";
	public conversionObject:any;
	constructor(private timeGroupTipsIn:Array<TimeGroupTip>, private datePipe:DatePipe)
	{
      this.timeGroupTips = new Array<TimeGroupTip>();//Object.assign([],this.timeGroupTipsIn);
	  var tipDate = null;
      for(var i = 0; i < this.timeGroupTipsIn.length;i++)
	  {
		  this.timeGroupTips.push(new TimeGroupTip(this.timeGroupTipsIn[i].getFromTipDate(),this.timeGroupTipsIn[i].getToTipDate(),this.timeGroupTipsIn[i].getActualTipAmount()));
		  if(tipDate == null || tipDate != this.timeGroupTipsIn[i].getFromTipDate())
		  {
			 this.lineChartLabels.push("" + this.datePipe.transform(this.timeGroupTipsIn[i].getFromTipDate(),"short") + " - " + this.datePipe.transform(timeGroupTipsIn[i].getToTipDate(),"short") + ""); 
		     tipDate = this.timeGroupTipsIn[i].getFromTipDate();
		  }
		  
		  
	  
	  }
      console.log(this.timeGroupTips);	  
      //this.updateLineChartData();	  
	}
	setLiteCoinUSDPice(liteCoinPrice:number){
      this.liteCoinPriceUSD = liteCoinPrice;
	}
	setConversionObject(object: any)
	{
		this.conversionObject = object;
	}
	setCurrentCurrency(currency:string)
	{
		this.current_currency = currency;
	}
	
	convertCurrencyToAnother(currencyToConvertTo:string,index_ofTip:number, index:number,ratioAmount:number)
	{
		   console.log(this.timeGroupTipsIn[index_ofTip].getTipCurrency());
		 //  console.log(currencyToConvertFrom);
		   console.log(currencyToConvertTo);
		   console.log(ratioAmount);
		   return ((this.timeGroupTipsIn[index_ofTip].getActualTipAmount() / ratioAmount).toFixed(2));
		   
		   //this.updateLineChartData();	
	 }
         
	
	updateLineChartData()
	{
		this.lineChartData[0].data = [];
		var dataArray:Array<number> = new Array<number>();
		var tipDate:Date = null;
		var tipTotal = 0;
		var addEnter = false;
		for(var i =0; i < this.timeGroupTips.length; i++)
		{
			if(tipDate == null || tipDate !=  this.timeGroupTipsIn[i].getFromTipDate())
			{
				
				tipDate = this.timeGroupTipsIn[i].getFromTipDate();
				console.log(this.timeGroupTipsIn[i].getFromTipDate());
				if(i != 0)
				{
					addEnter = true;
				}
			}
			if((i + 1) == this.timeGroupTipsIn.length)
			{
				addEnter = true;
			}
			if(addEnter == true)
			{
			  	
			  dataArray.push(parseFloat(tipTotal.toFixed(2)));
			  tipTotal = 0.0;
			}
			if(tipDate == this.timeGroupTipsIn[i].getFromTipDate())
			{
				//tipTotal += this.timeGroupTipsIn[i].getTipAmount();

				 //Number((this.convertCurrencyToAnother(this.timeGroupTipsIn[i].getShortCurrency(),i,x, (object["rate"] as number))));
				if(this.current_currency.toLowerCase() != "usd"){
					console.log(this.current_currency.toLowerCase());
                   tipTotal += (this.timeGroupTipsIn[i].getActualTipAmount() * this.liteCoinPriceUSD) *
				   ((this.conversionObject[this.current_currency.toLowerCase()])["rate"]);
				}
		    else{
				   tipTotal +=  this.timeGroupTipsIn[i].getActualTipAmount() * this.liteCoinPriceUSD;
			    }
				//tipTotal += Number(this.convertCurrencyToAnother(this.timeGroupTipsIn[i].getShortCurrency(),i,x,1));
			/*  for(var x = 0; x < this.conversionObject.length; x++)
				{
					var object = this.conversionObject[x];
					//console.log("Object currency is " + object["currency"]);
					//console.log(this.timeGroupTipsIn[i].getShortCurrency());
					if(object["currency"] == this.timeGroupTipsIn[i].getShortCurrency() )
					{
						console.log("currency is " + object["currency"] );
						console.log("rate is " + object["rate"]);
						
						if(object["rate"] != null)
						{
							console.log(Number((this.convertCurrencyToAnother(this.timeGroupTipsIn[i].getShortCurrency(),i,x, (object["rate"] as number)))));
							tipTotal +=  Number((this.convertCurrencyToAnother(this.timeGroupTipsIn[i].getShortCurrency(),i,x, (object["rate"] as number))));
						}
						else
						{
							tipTotal += Number(this.convertCurrencyToAnother(this.timeGroupTipsIn[i].getShortCurrency(),i,x,1));
						}
					}
				} */
				
			}
			
			
			
			addEnter = false;
		    
		}
		this.lineChartData[0].data = Object.assign([],dataArray);
		console.log(dataArray);
	}
	
	
}
