export class HelpQuery {

  private _date:Date;
  constructor(private helpQuestion:string, private helpCategory:string)
  {
	  
  }
  setHelpQueryDate(inDate:Date)
  {
	  this._date = inDate;
  }
}
