import { Component, OnInit,Input } from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";
import {Subject} from "rxjs";
@Component({
  selector: 'success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements OnInit {
  @Input() connectionToSuccessSubject:Subject<{[key:string]:string}>;
  public extraData:string = "";
  constructor(private ngxSmartModalService:NgxSmartModalService) 
  { 
    this.connectionToSuccessSubject = new Subject<{[key:string]:string}>();
  }
  ngOnChanges(changes)
  {
	  if(changes["connectionToSuccessSubject"])
	  {
		  this.connectionToSuccessSubject.subscribe((value) =>{
			 if(value["show"] == "open")
			 {
				 this.extraData = "";
				 this.ngxSmartModalService.get("successModal").open();
			 }
             else if(value["extraData"])
			 {
				 this.extraData = value["extraData"];
			 }				 
		  });
	  }
  }
  ngOnInit() {
  }

}
