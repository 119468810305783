import { AfterViewInit, Component, OnInit, ViewChild,ElementRef, Inject } from '@angular/core';
import { Subject, timer } from 'rxjs';
import {FormBuilder, Validators} from "@angular/forms";
import { FormGroup } from '@angular/forms';
import { TipllyPluginService } from '../../services/tiplly-plugin-service.service';
import { HeadingContext } from '../../exportedValues/heading-context.model';
@Component({
  selector: 'app-plugin-user-information',
  templateUrl: './plugin-user-information.component.html',
  styleUrls: ['./plugin-user-information.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class PluginUserInformationComponent implements OnInit,AfterViewInit {
 
  @ViewChild("errorsUl",{static:false}) errorsUl:ElementRef;
  @ViewChild("authenticatorQRLink",{static:false}) authenticatorQRLink:ElementRef;
  @ViewChild("walletAddressesLink",{static:false}) walletAddressesLink:ElementRef;
  private connectionToLoadingModal:Subject<{[key:string]:any}>;
  private updatesToLoadingModal:Subject<String>;
  private fg_:FormGroup;
  emailAddress_:any = null;
  user_information:any = null;
  qr_link:string = "";
  authenticator_link:string = "";
  headingContext:HeadingContext;
  past50Tips:Array<any>;
  past50TipsShowing:Array<any> = [];
  currentPageOfTips:number;
  linkToAuthenticatorCode:string = "";
  linkToWalletAddresses:string = "";
  constructor(private fb_:FormBuilder, private tipllyPluginService:TipllyPluginService, @Inject("base_href")private base_href:string) { 
    this.fg_ = this.fb_.group({emailAddress:["",Validators.compose([Validators.required,Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)])],
                              code:["",Validators.compose([Validators.required])]});
    this.connectionToLoadingModal = new Subject<{[key:string]:any}>();
    this.past50TipsShowing = new Array<any>();
    this.updatesToLoadingModal = new Subject<String>();
    this.headingContext = new HeadingContext();
    this.past50Tips = new Array<any>();
  }
  submitEmailForPlugin(){
    console.log(this.fg_.controls["emailAddress"].value);
    if(this.fg_.controls["emailAddress"].valid){
      var emailAddress = this.fg_.controls["emailAddress"].value;
      this.connectionToLoadingModal.next({"Loading":"Completing Task ..."});
      this.emailAddress_ = emailAddress;
      this.tipllyPluginService.sendEmailToUserForPlugin(emailAddress);
     
      this.errorsUl.nativeElement.innerHTML = "";
    }else{
      console.log(this.errorsUl);
      this.errorsUl.nativeElement.innerHTML = "<li style = 'color:red'>Please enter a valid email address.</li>";
    }
  }
  submitCodeForPlugin(){
    console.log(this.fg_);
    if(this.fg_.controls["code"].valid){
      var code_ = this.fg_.controls["code"].value;
      this.connectionToLoadingModal.next({"Loading":"Completing Task ..."});
      this.tipllyPluginService.submitEmailCode(this.emailAddress_,code_);
    }
  }
  ngOnInit() {
    this.tipllyPluginService.getConnectionToTipllyPluginService().subscribe((value) =>{
        this.updatesToLoadingModal.next("GotInformationNowClose");
        console.log(value);
        if(value.hasOwnProperty("past50Tips")){
          if(value["past50Tips"] != "server_error" && value["past50Tips"] != "timedOut"){
            this.past50Tips = value["past50Tips"] as any;
            this.past50TipsShowing = this.past50Tips.slice( 0, 7);
            console.log(this.past50TipsShowing);
          }
          
        }
        console.log(typeof(value["submitEmailCode"]) );
        if(value.hasOwnProperty("submitEmailCode") && typeof(value["submitEmailCode"]) == "object"){
          console.log(this.base_href);
          if(this.base_href.includes("/api")){
            this.linkToAuthenticatorCode = `${this.base_href.substring(0,this.base_href.indexOf("/api"))}/#/viewAuthenticatorInformation?uuid_=${value["submitEmailCode"].user_uuid}`;
            this.linkToWalletAddresses = `${this.base_href.substring(0,this.base_href.indexOf("/api"))}/#/pluginUserWalletAddresses?username=${this.emailAddress_}`;
          }else{
            this.linkToAuthenticatorCode = `http://localhost:4200/#/viewAuthenticatorInformation?uuid_=${value["submitEmailCode"].user_uuid}`;
            this.linkToWalletAddresses = `http://localhost:4200/#/pluginUserWalletAddresses?username=${this.emailAddress_}`;
            this.authenticatorQRLink.nativeElement.href = this.linkToAuthenticatorCode;
            this.walletAddressesLink.nativeElement.href = this.linkToWalletAddresses;

          }
          console.log(this.base_href.substring(0,this.base_href.indexOf("/api")));
          this.tipllyPluginService.getPast50TipsForPluginUser(this.emailAddress_);
        
         
        }
        var timer_ = timer(3000);
        timer_.subscribe(() =>{this.updatesToLoadingModal.next("GotInformationNowClose");});
        console.log(value);
    });
  }
  changeShowingTips(page:number){
    this.currentPageOfTips = page;
    this.past50TipsShowing = this.past50Tips.slice( ((page - 1) * 7), (page * 7));
  }
  ngAfterViewInit(){
  
   
  }

}
