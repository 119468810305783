import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import {UserInformationService} from "./../services/user-information.service";
@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {
  
  constructor(private userInformationService:UserInformationService, private router:Router)
  {
	  
  }  
	
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
	  var difference:number = Math.round((((new Date().getTime()) - this.userInformationService.getLastNavigationDate().getTime())/1000)/60);
	  
	  if(difference > 35)
	  {
		this.router.navigate(["/","landing"],{queryParams: {reason:'timeout'}});  
	  }
	  else{
	this.userInformationService.setLastNavigation(new Date());	  
		return true;
	  }
  }
  
}
