import { Injectable,Inject } from '@angular/core';
import {Response} from "@angular/http";
import {HttpClient} from "@angular/common/http";
import{Subject} from "rxjs";
import {UserInformationService} from "./user-information.service";
import {SecurityService} from "./security.service";
@Injectable({
  providedIn: 'root'
})
export class MiscellaneousService {
  private resultsSubject:Subject<{[key:string]:string}>;
  private href_:string;
  
  constructor(private http:HttpClient, @Inject("base_href")private base_href:string, private securityService:SecurityService) {
    this.resultsSubject = new Subject<{[key:string]:string}>();
    this.href_ = this.base_href + "/user"; 
  }
  public getResultsSubject():any {
    return this.resultsSubject;

  }
  public getAllWalletAddresses(username:string)
  { 
     this.href_ = this.href_ + `/getAllTipUserWalletAddresses?emailAddress=${username}`;
     var headerOptions = this.securityService.generateAndReturnHeaders();
     this.http.get(this.href_,{headers:headerOptions,observe:'response'}).subscribe((success)=>{
         console.log(success);
         if(success["status"] == 200){
           var result = success["body"] as any;
           if(result["result"] == "successful"){
            this.resultsSubject.next({"result":"successful","body":result,"purpose":"wallets"});
           }else {
            this.resultsSubject.next({"results":"error","purpose":"wallets"});
           }
         }
     }, (err) =>{
       console.log(err);
       this.resultsSubject.next({"result":"failure","purpose":"wallets"});
     });
  }

  


}
