export class TipHelpers {
	
	constructor()
	{
		
	}
	public CardHelpersArray:Array<String> = ["Enter your 16 digit card number","Select the expiration month on your card","Enter the expiration year on your card", "Enter the three digit cvc number on the back of your card"];
	public TipHelpersArray:Array<String> = ["Select the Currency your Country uses","Enter the amount of your tip","You can leave a tip note for the person you are tipping"];
    public monthsHelperArray:Array<String> = ["01","02","03","04","05","06","07","08","09","10","11","12"];
	public yearsHelperArray:Array<String> = ["2019","2020","2021","2022","2023","2024","2025","2026","2027","2028","2029","2030","2031","2032","2033","2034","2035","2036","2037","2038","2039","2040",
	                                         "2041","2042","2043","2044","2045","2046","2047","2048","2049","2050","2051","2052","2053","2054","2055","2056","2057","2058","2059","2060","2061","2062",
											 "2063","2064","2065","2066","2067","2068","2069","2070","2071","2072","2073","2074","2075","2076","2077","2078","2079","2080","2081","2082","2083","2084",
											 "2085","2086","2087","2088","2089","2090","2091","2092","2093","2094","2095","2096","2097","2098","2099","2100"];
	public CurrencyHelperArray:Array<Object> = [{"currency":"United States Dollar - USD","img":"dollar sign icon"},
												{"currency":"Euro - EUR","img":"euro sign icon"},
												{"currency":"Pound - GBP","img":"pound sign icon"},
												{"currency":"Yen - JPY","img":"yen sign icon"},
												{"currency":"Swiss Franc - CHF","img":"&#8355;"},
												{"currency":"Canadian Dollar - CAD","img":"&#36;"},
												{"currency":"Czech Koruna - CZK","img":"&#36;"},
											    {"currency":"Polish Zloty - PLN","img":"&#36"},
												{"currency":"Singapore Dollar - SGD","img":""},
												{"currency":"Sweedish Krona - KR","img":""},
												{"currency":"New Zealand Dollar - NZD","img":""},
												{"currency":"Romanian Leu - RON","img":""},
												{"currency":"Australian Dollar - AUD","img":""},
												{"currency":"Hong Kong Dollar - HKD","img":""}];
	public toolTipHelperArray:Array<String> = ["Click for example of where card number is located on your card ","Click for example of where the expiration month is located on your card","Click for example of where the expiration year is located on your card"
	                                            ,"Click for example of where the cvv is located on your card."];
	
												
	
	
}
