export class HeadingContext {
  
  constructor()
  {
  }
 public tipllyHeadingArray:Array<String> = ["Incoming Tips","Recent Tips","Tips Between Two Dates","Generate Report"];
 public tipllyHeadingArrayContext:Array<String> = ["Tips that are currently incoming","Recent Tips ", "Searchable tips between two Dates", "Total tips and report generator between two datess","Generate Report of total amount of tips between two dates"];
 public tipllyWebsiteHeadingArray:Array<String> = ["Websites you own", "Websites Authentication Failed","Websites Pending Authentication"];
 public tipllyWebsiteArrayContext:Array<String> = ["Number of websites you own are currently : ", "Number of failed websites currently are : ", "Number of pending websites are: "];
 public tipllyHelpHeadingArray:Array<String> = ["Support","Articles","Tickets","New Tickets"];
 public tipllyHelpArrayContext:Array<String> = ["Where you find can answers to your problems","Help articles to solve common issues","Your help tickets"];
 public tipllyPluginTableHeaders:Array<String> = ["Tip Amount", "Website/App","Date Recorded"];
}