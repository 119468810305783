import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {VideoService} from "./../../services/video-service.service";
@Component({
  selector: 'app-website-integration-tutorial',
  templateUrl: './website-integration-tutorial.component.html',
  styleUrls: ['./website-integration-tutorial.component.css']
})
export class WebsiteIntegrationTutorialComponent  {
  connectionToVideoModal:Subject<{[key:string]:string}>;
  constructor(private videoService:VideoService) {
    this.connectionToVideoModal = new Subject<{[key:string]:string}>();
   }
  

 
  openVideoModal(){
	  var video_url = this.videoService.getUrlOfVideo("Website Integration");
    this.connectionToVideoModal.next({"show":`${video_url}`});
  }
}
