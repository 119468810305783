export class TimeGroupTip {
   private fromTipDate:Date;
   private   toTipDate:Date;
   private tipAmount:number;
   private convertedTipAmount:number = 0;
 
   
   constructor(fromTipDate:Date, toTipDate:Date, tipAmount:number)
   {
	   this.fromTipDate = fromTipDate;
	   this.toTipDate = toTipDate;
	   this.tipAmount = tipAmount;
	   
   }
   
   getFromTipDate()
   {
	   return this.fromTipDate;
   }
   
   getTipCurrency()
   {
	   return null;
   }
   getShortCurrency()
   {
	   return null;
   }
   getToTipDate()
   {
	   return this.toTipDate;
   }
   getActualTipAmount()
   {
	   return this.tipAmount;
   }
   getTipAmount()
   {
	   return this.convertedTipAmount;
   }
   
  setTipAmount(tipAmount:number)
  {
	  this.convertedTipAmount = tipAmount;
	  console.log("set tip Amount called");
  }
}
