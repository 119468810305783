import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {Location} from "@angular/common";
import{ArticleService} from "./../../services/article-service.service";
import{ActivatedRoute, Router} from "@angular/router";
import {LoadingBarService} from "@ngx-loading-bar/core";
@Component({
  selector: 'pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class PdfViewerComponent implements OnInit {

  private pdfUUID:string;
  private pdfBytes:any;
  @ViewChild("pdfViewer",{static:false}) pdfViewerDirect:any;
  constructor(private articleService:ArticleService, private router:Router, private activatedRoute:ActivatedRoute, private location:Location, private loadingBarService:LoadingBarService) 
  { 
    this.activatedRoute.params.subscribe((params) =>{
	  this.pdfUUID = params["pdfId"];	
	  console.log(this.pdfUUID);
	});
  }
  goBack()
  {
	  this.location.back();
  }
  
  ngAfterViewInit()
  {
	  this.loadingBarService.start();
	  window.scrollTo(0,0);
  }

  ngOnInit() {
	  this.articleService.getArticleBytesSubject().subscribe((value) => {
		 
		 console.log(value);
		 this.pdfBytes = value;
         this.pdfViewerDirect.pdfSrc = value;
		 this.pdfViewerDirect.refresh();
		 this.loadingBarService.complete(); 
		 
	  });
	  this.articleService.getArticle(this.pdfUUID);
	  
  }

}
