import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UserInformationService} from "./../../services/user-information.service";
import { timer } from 'rxjs';
@Component({
  selector: 'app-third-party-authenticator',
  templateUrl: './third-party-authenticator.component.html',
  styleUrls: ['./third-party-authenticator.component.css']
})
export class ThirdPartyAuthenticatorComponent implements OnInit {
  private uuid_:string;
  private hasQueryParam:boolean;
  private loading = false;
  private timer:any;
  private result:any;
  private QRLink:any;
  // qrData = 'otpauth://totp/Tiplly3rdPartyAuthenticator:steveboy199@gmail.com@https://tiplly.com?secret=BFYA6YM4P4======&issuer=Tiplly&algorithm=SHA1&digits=6&period=300';
  constructor(private route:ActivatedRoute, private userInformationService:UserInformationService) {
    this.timer = timer(1000);
   }

  ngOnInit() {
    this.userInformationService.getUserInformationSubject().subscribe((value) =>{
      if(value["TipllyPluginUsersQRInformation"] != "Error"){
        console.log(value["TipllyPluginUsersQRInformation"]);
        this.result = value["TipllyPluginUsersQRInformation"];
        this.setupUrl(this.result);
      }else{
       this.result = "Error";
       this.QRLink = null;
      }
    });
    this.route.queryParams.subscribe((value) =>{
      this.hasQueryParam = false;   
      if(value["uuid_"]){
          this.uuid_ = ((value["uuid_"] as any) as string);
          this.hasQueryParam = true;
          this.timer.subscribe(() =>{
            if(this.hasQueryParam){
              this.userInformationService.getTipllyPluginUsersQRInformation(this.uuid_);
              this.loading = true;
            }
          });
      }else{
        this.result = "Error";    
      }
       
    });
  }
  setupUrl(object_:any){
    var auth_length = object_["authenticator_length"];
    var time_length = object_["authenticator_timer"];
    var identifier = object_["tiplly_identifier"];
    var algorithm = object_["authenticator_algorithm"];
    var issuer = object_["issuer"];
    var emailAddress = object_["emailAddress"];
    this.QRLink = `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=200x200&chld=M|0&cht=qr&chl=otpauth://totp/Tiplly3rdPartyAuthenticator:${emailAddress}@${issuer}?secret=${identifier}&issuer=Tiplly&algorithm=${algorithm}&digits=${auth_length}&period=${time_length}`;
    this.QRLink = `otpauth://totp/Tiplly3rdPartyAuthenticator:${emailAddress}@${issuer}?secret=${identifier}&issuer=Tiplly&algorithm=${algorithm}&digits=${auth_length}&period=${time_length}`;
  }

}
