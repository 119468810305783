import { Injectable,Inject } from '@angular/core';
import {HttpClient} from "@angular/common/http";
@Injectable()
export class VideoService {

  private baseUrl_:string;
  private listOfVideos:Array<{[key:string]:string}>;
  constructor(private httpClient:HttpClient, @Inject("base_href")base_href:string) 
  { 
     this.listOfVideos = new Array<{[key:string]:string}>();
	 this.baseUrl_ = `${base_href}/user/getTipllysVideos`;
  }
  
  getListOfVideos()
  {
	  this.listOfVideos = [];
	  this.httpClient.get(this.baseUrl_).subscribe((success) =>{
		 var videoList = ((success as unknown) as any);
		 for(var i =0; i < videoList.length; i++)
		 {
			 var jsonObject = {
				"videoName":`${videoList[i].video_name}` ,
				"videoUrl":`${videoList[i].video_url}`
			 };
			 this.listOfVideos.push(jsonObject);
		 }
		 console.log(this.listOfVideos);
	  });
  }
  
  getUrlOfVideo(which:string):string
  {
	  console.log(this.listOfVideos);
	   var result:string;
	   for(var i =0; i < this.listOfVideos.length; i++)
	   {
		 
			var object = this.listOfVideos[i];
			if(object["videoName"].includes(which))
			{
				result = (object)["videoUrl"];
			}				
		 
	   }
	   return result;
  }
}
