import { Injectable,Inject } from '@angular/core';
import {Response,Headers} from "@angular/http";
import{HttpClient} from "@angular/common/http";

import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";

import { SocialUser } from "angularx-social-login";
import { Subject,BehaviorSubject,Observable} from 'rxjs';
import {TipUser} from "./../Models/TipUser.model";
import {Card} from "./../Models/card.model";
import {BankAccount} from "./../Models/bank-account.model";
import {CurrencyConversionService} from "./currency-conversion-service.service";
import {SecurityService} from "./security.service";
import {VideoService} from "./video-service.service";
@Injectable()
export class UserInformationService  {

   private tipUser:TipUser;
   public userInformation:Subject<{[key:string]:string}>;
   private isSignedIn:Number;
   private isSignedIn2:Number;
   private last_navigation:Date;
   private basehref_:string = "";
   private basehrefu_:string = "";
   
  constructor(private http:HttpClient,@Inject("base_href")base_href:string, private authService: AuthService , private currencyConversionService:CurrencyConversionService,private securityService:SecurityService, private videoService:VideoService) 
  {  
     this.userInformation = new Subject<{[key:string]:string}>();
	 //this.authService.signOut();
	 this.isSignedIn = -1;
	 this.basehref_ = `${base_href}/user`;
	 this.basehrefu_ = `${base_href}/stripeTip`;
	
	
  }
  getTipllyCodeForLink()
  {
	return this.tipUser.tipUUID;  
  }
  clearLastNavigationDate()
  {
	  this.last_navigation = null;
  }
  setLastNavigation(date:Date)
  {
	  this.last_navigation = date;
  }
  getUserInformationSubject()
  {
	  return this.userInformation;
  }
  getLastNavigationDate():Date
  {
	  return this.last_navigation;
  }
  
  getTipUsername():string{
	  return this.tipUser.username;
  }
  getTipUserIsVerified()
  {
	  return this.tipUser.verified;
  }
  getTipUserLink():string{
	  return this.tipUser.tipply_link;
  }
  getTipUserWalletAddress():string{
	  return this.tipUser.outWalletAddress;
  }
  getTipUser():TipUser
  {
	  return this.tipUser;
  }
  acceptStripeTOS()
  {
	  var username = this.getTipUsername();
	  var httpHeaders = this.securityService.generateAndReturnHeaders();
	  this.http.post(`${this.basehref_}/acceptStripeTOS?username=${username}`,{headers:httpHeaders, responseType:"text", observe: "response"}).subscribe((success) =>{
			   console.log(success);
			   var resultsLink = ((success["body"] as unknown) as string);
			   //this.userInformation.next({"redirectLink":resultsLink});
		  },(err) =>{
			  console.log(err);
			  //this.userInformation.next({"err":"failed to get users link"});
		  });
  }
  createUsersStripeAccount(country:string)
  {
	  var username = this.getTipUsername();
	  var httpHeaders = this.securityService.generateAndReturnHeaders();
	  console.log(`${this.basehref_}/createStripeAccount?country=${country}&username=${username}`);
	  this.http.post(`${this.basehref_}/createStripeAccount?country=${country}&username=${username}`,null,{headers:httpHeaders, responseType:"text", observe: "response"}).subscribe((success) =>{
		console.log(success);
	  this.http.get(`${this.basehref_}/getLinkForUpdate?username=${username}`,{headers:httpHeaders, responseType:"text", observe: "response"}).subscribe((success) =>{
			   console.log(success);
			   var resultsLink = ((success["body"] as unknown) as string);
			   this.userInformation.next({"redirectLink":resultsLink});
			  
		  },(err) =>{ 
			  
		   this.userInformation.next({"err":"failed to get users link"});
			  
		  }); 
		
		  
	  },(err) =>{
		 if(err.status == 418)
		{
				  this.userInformation.next({"err":"failedToDelete"});  
		}
	  });
  }
  shouldShowWelcomeModal():boolean
  {
	  var shouldShow:boolean = false;
	  console.log(this.tipUser.join_date);
	  var timeDiff = (((new Date()).getTime()) - (this.tipUser.join_date.getTime()));
	  console.log(timeDiff);
	  timeDiff = Math.round(timeDiff / 60000);
	  console.log(timeDiff);
	  if(timeDiff < 60)
	  {
		  console.log("should show is true");
		  shouldShow = true;
	  }
	  else{
	    shouldShow = false;
	  }
	  return shouldShow;
  }
 
  
  initialize()
  {
	  
	 
	  this.authService.authState.subscribe((user) => {
		 this.videoService.getListOfVideos();			
		 if(this.isSignedIn != -1){
			 this.isSignedIn = -1;
			  console.log(user);
		  var username = user.email;
		  this.userInformation.next({"signedIn":"started"});
		 this.http.post((`${this.basehref_}/loginOrcreate/${username}` ),JSON.stringify(user)).subscribe(
		                (success) => {
							
							//this.currencyConversionService.setUp();
							console.log(success);
							//var userJson = JSON.parse(success["_body"]);
							var userJson = ((success as unknown) as any);
							console.log(userJson);
							this.tipUser = new TipUser(userJson.username,new Date(userJson.join_date),userJson.verified,userJson.tipply_link,userJson.tipply_uuid, userJson.hasBankAccountAttached, userJson.country, userJson.wallet_address,userJson.tipCode_uuid, userJson.preferred_currency);
							console.log(this.tipUser);
							this.securityService.setUserNameAndCode(userJson.username,userJson.security_uuid);
							this.currencyConversionService.setCurrencyObject(JSON.parse(userJson.jsonCurrencyConversion));
							this.currencyConversionService.setLTCUSDPrcie(userJson.ltcPriceUSD)
							console.log("s");
							this.userInformation.next({"signedIn":"complete"});
							this.last_navigation = new Date();
							
							},
						(err) =>{
							console.log("err is" + err);
							this.userInformation.next({"err":"Failed to sign in"});
						},
						() =>{
						   console.log("got user information");	
						}
			 );
	   
	      }
		  else
		  {
			this.isSignedIn = 0; 
			console.log("changed");
             			
		  }
		});
	
  }
  
   signInWithGoogle()
  {
	  this.initialize();
	 this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
	  console.log("called2");
  }
  signOut()
  {
	 // this.authService.signOut();
  }
  updateStripeUsersBankInformation(bankAccount:BankAccount)
  {
	  var username = this.getTipUsername();
	  console.log(bankAccount);
	  var httpHeaders = this.securityService.generateAndReturnHeaders();
	  this.http.post(`${this.basehref_}/updateBankAccount?username=${username}`, bankAccount,{headers:httpHeaders, responseType:"text", observe: "response"}).subscribe((success) => {
		  console.log(success);
		  this.userInformation.next({"bankAccount":"successful"});
		  this.tipUser.hasBankAccountAttached = 2;
	  },(err) => {
		  console.log(err);
		  this.userInformation.next({"err":"Could not set or update bank account"});
	  })
	  
  }
  updateStripeUserDebitCardPaymentInformation(token:string)
  {
	  var username = this.getTipUsername();
	  console.log(username);
	  var httpHeaders = this.securityService.generateAndReturnHeaders();
	  this.http.post(`${this.basehref_}/updateCardAccount?username=${username}&token=${token}`, null,{headers:httpHeaders, responseType:"text", observe: "response"}).subscribe((success) =>{
		 console.log(success); 
		 this.userInformation.next({"cardAccount":"successful"});
	  },(err) =>{
		  console.log(err);
		  this.userInformation.next({"err":"Could not set or update payment account to  card "});
	  });
  }
  submitFilesForVerification(files:any,which:boolean)
  {
	  var username = this.getTipUsername();
	  let formData:FormData = new FormData();
	  for (var x = 0; x < files.length; x++) {
			formData.append("files", files[x]);
       }
      //formData.append('files', files,  "files");
	  console.log(files);
	   var httpHeaders = this.securityService.generateAndReturnHeaders();
	  this.http.post(`${this.basehrefu_}/upload?username=${username}&isIdentityVerification=${which}`,formData).subscribe((success) =>{
		 this.userInformation.next({"uploadProof":"successful"});  
		console.log(success);  
	  },(err) =>{
		   this.userInformation.next({"err":"Could not successfully submit files t stripe"});
	  })
  }

  submitLiteCoinAddress(address:string)
  {
	  var username = this.getTipUsername();
	  this.http.post(`${this.basehref_}/addOrUpdateWalletAddressForWebsiteUser?username=${username}&liteCoinAddress=${address}`,null).subscribe((success) => {
		  console.log(success);
		  this.tipUser.outWalletAddress = address;
		  this.userInformation.next({"updateLiteCoinAddress":"successful"});
		  
	  },(err) => {
		    console.log(err);
		    this.userInformation.next({"err":"Could not successfully updateLiteCoinAddress"});
      }, () => {
	  
      });
  }
  getTipllyPluginUsersQRInformation(identifier:string){
	var url = `${this.basehref_}/getTipUsersAuthenticatorLink?tipllyPluginUserIdentifier=${identifier}`;
	this.http.get(url,{observe:"response"}).subscribe((success) =>{
     console.log(success);
     var result = ((success["body"] as any) as string);
	 this.userInformation.next({"TipllyPluginUsersQRInformation":result});
	},(err) =>{
		this.userInformation.next({"TipllyPluginUsersQRInformation":"Error"});
	});
  }
  
  setUsersPreferredCurrency(currency:string){
   var username = this.getTipUsername();
   var url = `${this.basehref_}/updatePreferredCurrency`;
   var jsonObject = {};
   jsonObject["username"] = username;
   jsonObject["preferred_currency"] = currency;

   this.http.put(url,jsonObject).subscribe((success) =>{
   	this.getTipUser().preferredCurrency = currency;
      this.userInformation.next({"updatePreferredCurrency":"successful"});

   }, (err) =>{
   	this.userInformation.next({"err":"could not update users preferred currency"});
   })
  }
}
