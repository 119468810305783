import { Component, OnInit,AfterContentChecked,AfterViewInit, ViewChild,ElementRef,ChangeDetectorRef } from '@angular/core';
import {Tip} from "./../../Models/tip.model";
import {TipInformationService} from "./../../services/tip-information-service.service";
import {CurrencyConversionService} from "./../../services/currency-conversion-service.service";
import {UserInformationService} from "./../../services/user-information.service";
import {flippingInOnXaxis,lightSpeedInOut} from "./../../../animations/entryexitTipsAnimations.animation";
import {Subject} from "rxjs";
import {timer} from "rxjs";
import {Miscellaneous} from "./../../exportedValues/miscellaneous";
import {HeadingContext} from "./../../exportedValues/heading-context.model";
import {ExcelJob} from "./../../Models/excel-job.model";
import {rotation} from "./../../../animations/rotateAnimation.animation";
import {urlStanderizer} from "./../../exportedValues/verify-url-functions";
import { DatePipe } from '@angular/common'
import {VideoService} from "./../../services/video-service.service";
import {Router} from "@angular/router";
import { TimeGroupTip } from '../../Models/time-group-tip';
//import * as pluginAnnotations from 'chartjs-plugin-annotation';
@Component({
  selector: 'app-tip-information',
  templateUrl: './tip-information.component.html',
  animations:[flippingInOnXaxis(),lightSpeedInOut(),rotation()],
  styleUrls: ['./tip-information.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class TipInformationComponent implements OnInit, AfterContentChecked, AfterViewInit {

  countOfUserTips:Array<Number>;
  pageOfUserTips :Number = 1;
  pageOfUserTipsBetweenTwoDates:Number = 1;
  countOfUserTipsBetweenDates:Array<Number>;
  arrayOfIncomingUserTips:Array<Tip>;
  arrayOfUserTips:Array<Tip>;
  arrayOfUserTipsIncoming:Array<Tip>;
  arrayOfTipsBetweenTwoDates:Array<Tip>;
  LoadingSubjectObservable:Subject<{[key:string]:string}>;
  UpdatesToLoadingSubjectObservable:Subject<string>;
  ShowGraphObservable:Subject<string>;
  firstDateTipSearch:Date;
  secondDateTipSearch:Date;
  firstDateTipTotal:Date;
  secondDateTipTotal:Date;
  @ViewChild("totalTipsErrorDiv",{static:false})totalTipsErrorDiv:ElementRef;
  time = {hour: 13, minute: 30};
  totalTipAmountBetweenTwoDates:number;
  miscellaneous:Miscellaneous;
  currentSearchCurrency = "please select currency";
  splitCurrency = "";
  defaultSettingTime:string = ""
  queryErrors:string = "";
  buttonQueryClassNonActive:Array<String>  = ["ui","blue","button"];
  nextTipTimer:any;
  waitTipTimer:any;
  settings12hr = {
	  bigBanner:true,
	  timePicker:true,
	  format:'dd-MMM-yyyy hh:mm:ss a'
  }
  settings24hr = {
	  bigBanner:true,
	  timePicker:true,
	  format: 'dd-MMM-yyyy HH:MM:SS'
  }
  barbackgroundGraphUrl:string = "./../../../assets/images/graphs/graph-green.png";
  lineGraphUrl:string =  "./../../../assets/images/graphs/line-graph-red.png";
  lineGraphBackgroundStyle:string = "graphBackground";  
  headingContext:HeadingContext;
  rotationArray:Array<string> = ["default","default","default"];
  continueRotationArray:Array<boolean> = [true,true,true];
  errorModalConnector:Subject<{[key:string]:string}>;
  connectionToNumberCounterModal:Subject<{[key:string]:string}>;
  connectionToInputDialog:Subject<{[key:string]:string}>;
  connectionToUpdatesToInputDialog:Subject<string>;
  connectionToSuccessModal:Subject<{[key:string]:string}>;
  connectionToVideoModal:Subject<{[key:string]:string}>;
  inputedLiteCoinPrice:number;
  lastExcelFileUploadDate:Date;
  security_code_for_file:string;
  //miscellaneous:Miscellaneous;
  constructor(private cdr:ChangeDetectorRef, private userInformationService:UserInformationService,private tipInformationService:TipInformationService, private datePipe:DatePipe,private router:Router, private currencyConversionService:CurrencyConversionService, private videoService:VideoService) {
     this.countOfUserTips = new Array<Number>();
	 this.countOfUserTipsBetweenDates = new Array<Number>();
	 this.arrayOfIncomingUserTips = new Array<Tip>();
     this.arrayOfUserTips = new Array<Tip>();	 
     this.arrayOfTipsBetweenTwoDates = new Array<Tip>();
	// console.log(this.datePipe.transform(new Date(),"EEE MMM dd yyyy HH:mm:ss 'GMT'Z").toString());
	// console.log(new Date(this.datePipe.transform(new Date(),"EEE MMM dd yyyy HH:mm:ss 'GMT'Z (zzzz)").toString()));
	 this.firstDateTipSearch = new Date(this.datePipe.transform(new Date(),"EEE MMM dd yyyy HH:mm:ss 'GMT'Z").toString());
	 this.secondDateTipSearch = new Date(this.datePipe.transform(new Date(),"EEE MMM dd yyyy HH:mm:ss 'GMT'Z").toString());
	 this.firstDateTipTotal = new Date(this.datePipe.transform(new Date(),"EEE MMM dd yyyy HH:mm:ss 'GMT'Z").toString());
	 this.secondDateTipTotal = new Date(this.datePipe.transform(new Date(),"EEE MMM dd yyyy HH:mm:ss 'GMT'Z").toString());
	 this.miscellaneous = new Miscellaneous();
	 this.LoadingSubjectObservable = new Subject<{[key:string]:string}>();
	 this.UpdatesToLoadingSubjectObservable = new Subject<string>();
	 this.connectionToSuccessModal = new Subject<{[key:string]:string}>();
	 this.connectionToUpdatesToInputDialog = new Subject<string>();
	 this.ShowGraphObservable = new Subject<string>();
	 this.defaultSettingTime = "settings12hr";
	 this.headingContext = new HeadingContext();
	  this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
	 this.nextTipTimer = timer(5000,5000);
	 this.connectionToInputDialog = new Subject<{[key:string]:string}>();
	 this.errorModalConnector = new Subject<{[key:string]:string}>();
	 this.connectionToNumberCounterModal = new Subject<{[key:string]:string}>();
	 this.connectionToVideoModal = new Subject<{[key:string]:string}>();
     this.inputedLiteCoinPrice = (this.getPriceOfLiteCoinInCurrency() as any) as number;
  
	 //this.miscellaneous = new Miscellaneous();
	// this.waitTipTimer = timer(2000,2000);
	// this.nextTipTimer.subscribe(()=>{
	//  if(this.router.url == "/home/tipinformation"){	 
	//	var plainTip:Tip = this.arrayOfIncomingUserTips[0];
	//	 console.log(plainTip);
	//	this.tipInformationService.getNextTipAfterTip(plainTip.timeStamp,plainTip.tip_uuid,null);
       // this.arrayOfIncomingUserTips.pop();		
	//	console.log("sent check tip for new tip");
	//  }
	// });
	 this.connectionToInputDialog.subscribe((value) =>{
		 if(value["returnValue"])
		 {
			this.LoadingSubjectObservable.next({"Loading":"PlainLoading"}); 
		    var result = ((value["returnValue"] as unknown) as string);
			result = urlStanderizer(result);
            this.tipInformationService.getTipTotalForWebsite(result, this.firstDateTipSearch, this.secondDateTipSearch);			
		 }  
	 });
	 this.tipInformationService.getTipErrorSubjectObservable().subscribe((value) => {
		 console.log(value);
		 this.UpdatesToLoadingSubjectObservable.next("GotInformationNowClose");
		 if(value["CreateExcelJob"] == "Must wait at least 24 hours from your past generate excel report request.")
		 {
			 timer(400).subscribe(() => {
			   this.errorModalConnector.next({"reset":"waitError"});	
			   this.errorModalConnector.next({"show":"waitError"});
			 }); 
		 }
		 else
		 {
			 timer(400).subscribe(() => {
			   this.errorModalConnector.next({"reset":"retrieveError"});		
			   this.errorModalConnector.next({"show":"retrieveError"});
			   this.UpdatesToLoadingSubjectObservable.next("GotInformationNowClose");
			 });
		 }
	 });
	 //Calling tipInformationService Methods
	 this.tipInformationService.getCountOfUserTipsSubjectObservable().subscribe((value:{[key:string]:number}) =>{
		 if(value["countOfUserTipsOverall"])
		 {
			 var countOfUserTipsTemp = value["countOfUserTipsOverall"];
			 this.countOfUserTips = [];
			 for(var x = 0 ; x < countOfUserTipsTemp; x++)
			 {
				 this.countOfUserTips.push(x);
			 }
		     console.log("count of user tips in component are "  + countOfUserTipsTemp);
			
		 }
		 else if (value["countOfTipsBetweenTwoDates"])
		 {
			 var countOfUserTipsBetweenTwoDates= value["countOfTipsBetweenTwoDates"];
			 this.countOfUserTipsBetweenDates = [];
			 for(var x = 0; x < countOfUserTipsBetweenTwoDates; x++)
			 {
				 this.countOfUserTipsBetweenDates.push(x);
			 }
			 console.log("Count of user tips between the two dates are " + countOfUserTipsBetweenTwoDates);
		 }
		 
	 });
	 this.tipInformationService.getCountOfUserTips();
	 this.tipInformationService.getUserTipsSubjectObservable().subscribe((value:{[key:string]:Array<Tip>}) =>{
		
		 
		 if(value["UserTipsTipArray"])
		 {
			 this.UpdatesToLoadingSubjectObservable.next("GotInformationNowClose");
			 this.arrayOfUserTips = [];
			 console.log("received tips in tip information component are " + value["UserTipsTipArray"]);
             setTimeout(()=>{ 			
		    	 this.arrayOfUserTips = (value["UserTipsTipArray"] as (Array<Tip>));
             },350);
             this.rotationArray[1] = "fini";
            		 
			console.log("tips are now " + this.arrayOfUserTips);
			 
		 }
		 else if(value["UserTipsArrayBetweenTwoDates"])
		 {
			 this.UpdatesToLoadingSubjectObservable.next("GotInformationNowClose");
			 this.arrayOfTipsBetweenTwoDates = [];
			 setTimeout(() =>{
			 this.arrayOfTipsBetweenTwoDates = (value["UserTipsArrayBetweenTwoDates"] as (Array<Tip>));
			
			 },350);
			
			 
			 
		 }
		 else if(value["UsersTipTotalsBetweenTwoDates"] || value["UsersTipTotalsBetweenTwoDatesForWebsite"]) {
			     console.log(value);
		         this.UpdatesToLoadingSubjectObservable.next("GotInformationNowClose");
				 var totalTipTotal:Array<Tip>;
				 if(value["UsersTipTotalsBetweenTwoDatesForWebsite"])
				 {
					 totalTipTotal = (value["UsersTipTotalsBetweenTwoDatesForWebsite"] as  Array<Tip>);
				 }
				 else{
			   	    totalTipTotal = (value["UsersTipTotalsBetweenTwoDates"] as  Array<Tip>);
				 }
				 console.log(totalTipTotal);
				 var currency_conversion_json = this.currencyConversionService.getCurrencyConversionObject;
				 var ltcUSDPrice = this.currencyConversionService.getLTCUSDPrice();
				 console.log(currency_conversion_json);
				 var shortCurrencyAbbrv = this.currentSearchCurrency.split("-")[1].trim().toLowerCase();
				 var totalAmount = 0.0;
				// for(var i = 0; i < totalTipArrayTotal.length; i++)
				 //{
					// var current_currency_json;
					// var tip_curr:Tip = totalTipArrayTotal[i];
					// for(var x =0; x < currency_conversion_json.length;x++)
					 //{
						 var currency_conversion_json_track ;//= currency_conversion_json[x];
						// if(currency_conversion_json_track["currency"] == tip_curr.short_currency)
						 //{
					//		current_currency_json = currency_conversion_json_track; 
						 //}
					// }
					 
					 //console.log(tip_curr);
					// console.log(current_currency_json["currency"] + " : " + current_currency_json["rate"] );
					 //console.log(((current_currency_json["rate"] as unknown) as number));
					 //var timesBy = ((current_currency_json["rate"] as unknown) as number);
					 if(this.currentSearchCurrency.split("-")[0].trim().toLowerCase() == "euro" /*&& tip_curr.tip_currency.toLowerCase() == "euro" */
					 )
					 {
						// timesBy = 1;
					 }						 
					 //console.log(timesBy);
					 var conversion_to =  0;//((tip_curr.tipAmount as unknown) as number) / (timesBy);
					 console.log(conversion_to);
					 totalAmount += conversion_to;
				 //}
				 console.log("total Amount is: " + totalAmount);
				 this.totalTipAmountBetweenTwoDates = totalAmount;
				 this.connectionToNumberCounterModal.next({"show":"open"});
		 }
		 else if(value["NextFive"])
		 {
			 this.arrayOfIncomingUserTips = [];
			 var tempTipnextFiveArray:Array<Tip> = (value["NextFive"] as (Array<Tip>));
			 if(tempTipnextFiveArray.length > 0)
			 {
			  for(var i  = 0; i < 5; i++)
			  {
				  if(tempTipnextFiveArray[i] != null)
				  {
				    this.arrayOfIncomingUserTips.push(tempTipnextFiveArray[i]);
				  }
			  }
			 }
			 this.rotationArray[0] = "fini";	
			 console.log("Next five tips are " + this.arrayOfIncomingUserTips);
		 }
		 else if (value["NextTip"])
		 {
			 var tip = (((value["NextTip"] as any) as Array<Tip>)[0]);
			 if(tip.tip_uuid != this.arrayOfIncomingUserTips[0].tip_uuid)
			 {
				 if(this.arrayOfIncomingUserTips.length == 5)
				 {
				   this.arrayOfIncomingUserTips.splice(4,1);
				   this.arrayOfIncomingUserTips.unshift(tip);
				 }
				 else {
					 this.arrayOfIncomingUserTips.unshift(tip);
				 }
			 }
		 }
	 });
	 this.tipInformationService.getTimeGroupTipSubjectObservable().subscribe((value) => {
		this.UpdatesToLoadingSubjectObservable.next("GotInformationNowClose");
		console.log(value);
		if(value["UsersTipTotalsBetweenTwoDates"] || value["UsersTipTotalsBetweenTwoDatesForWebsite"]){
			var timeGroupTip:TimeGroupTip;
			if(value["UsersTipTotalsBetweenTwoDates"]){
		    	timeGroupTip = (value["UsersTipTotalsBetweenTwoDates"] as TimeGroupTip);
			}
			else{
				timeGroupTip = (value["UsersTipTotalsBetweenTwoDatesForWebsite"] as TimeGroupTip);
			}
			var currencyConversionObject = this.currencyConversionService.getCurrencyConversionObject();
			var ltcUSDPrice = (this.inputedLiteCoinPrice != null && this.inputedLiteCoinPrice != undefined) ? this.inputedLiteCoinPrice : this.currencyConversionService.getLTCUSDPrice();
			console.log("ltc price is " + ltcUSDPrice);
			console.log(currencyConversionObject);
			var shortCurr = this.currentSearchCurrency.split("-")[1].trim().toLowerCase();
			var currencyConversionUSD:any = null;
			if(shortCurr.toLocaleLowerCase() != "usd"){
			    currencyConversionUSD = (currencyConversionObject[shortCurr])["rate"];
			}
			else{
				currencyConversionUSD = 1.0;
			}
			var amountInSpecifiedCurrency = (ltcUSDPrice * timeGroupTip.getActualTipAmount()) * currencyConversionUSD;
			this.totalTipAmountBetweenTwoDates = amountInSpecifiedCurrency;
			this.connectionToNumberCounterModal.next({"show":"open"});

		}
     });
	 this.tipInformationService.getTipInformationSubjectObservable().subscribe((value) =>{
	 	console.log(value);
	 	if(value["checkIfUserExcelFileExist"]  && value["checkIfUserExcelFileExist"] == "Excel file exist"){
			this.security_code_for_file = value["security_uuid"];
			if(value["download"] == "true"){
	 		 this.downLoadFinancialReport();
			  this.UpdatesToLoadingSubjectObservable.next("GotInformationNowClose");
			  this.connectionToSuccessModal.next({"show":"open"});
			  this.connectionToSuccessModal.next({"extraData":"File exist, Downloading report...."});
			}
	 		
		   
			this.lastExcelFileUploadDate = new Date(value["lastUploadDate"]);
	 	}else if(value["checkIfUserExcelFileExist"]  && value["checkIfUserExcelFileExist"] == "Excel file does not exist"){
			
	 		console.log("Excel files does not exist");
	 		this.UpdatesToLoadingSubjectObservable.next("GotInformationNowClose");
			if(value["onInit"] == "false"){
	 			this.connectionToSuccessModal.next({"show":"open"});
          	    this.connectionToSuccessModal.next({"extraData":"Excel file automatically deletes after 96 hours, excel file does not exist"});
			}
	 	}else{
		 this.UpdatesToLoadingSubjectObservable.next("GotInformationNowClose");
		 this.connectionToSuccessModal.next({"show":"open"});
		}
		 
		 
		 console.log(value);
	 });
	 this.tipInformationService.getUserTips(1,false);
	 this.tipInformationService.getUserTips(1,true);
	 this.tipInformationService.getCountOfUserTips();
  }

  ngOnInit() {
	 this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
  }
  
  continueRotation(index:number)
  {
	  console.log("continuation called");
	  if(this.rotationArray[index] != "fini")
	  {
		  if(this.rotationArray[index] == "default")
		  {
			   this.rotationArray[index] = "rotated";
		  }
		  else 
		  {
			  this.rotationArray[index] = "default";
		  }
	  }
	   
  }
  
  ngAfterContentChecked(){
  // console.log("content is checked");
  	this.cdr.detectChanges();
   
  }
  ngAfterViewInit()
  {
	console.log("AfterViewInit called");  
	this.cdr.detectChanges();
    for(var i = 0; i < this.rotationArray.length; i++)
    {
	   if(this.rotationArray[i] != "rotated")
	   {
	    this.rotationArray[i] = "default";		
		//this.rotationArray[i] = "rotated";
	   }
    }
     this.cdr.detectChanges();
	 this.tipInformationService.checkIfUserExcelFileExist(false,true);
  }
  
  setdefaultSettings(setting:string)
  {
	  this.defaultSettingTime = setting;
  }
  getPageOfUserTips(page:Number)
  {
	  this.pageOfUserTips = page;
	  this.tipInformationService.getUserTips(page,false);
	  this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
	  console.log("next page of user tips are " + this.pageOfUserTips);
  }
  test()
  {
	  console.log("this is ");
  }
  setSearchCurrency(currency:string)
  {
	  console.log(currency);
	  this.currentSearchCurrency = currency;
	  this.splitCurrency = currency.split("-")[1].trim();
  }
  downLoadMostRecentFile(){
	  this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
	  this.tipInformationService.retreiveTipllyFinancialReportExcelFile(this.security_code_for_file);
	  
  }
  submitTotalTipsAmountQuery(isReport:boolean)
  {
	  console.log("here at submitTotalTipsAmountQuery()");
	  var errors = "";
	  /*if(this.firstDateTipSearch > this.secondDateTipSearch)
	  {
		  errors += "<strong>first date cannot be greater than second date. </strong><br/>";
	  }*/
	  if(this.currentSearchCurrency.toLowerCase() == "please select currency")
	  {
		  errors += "<strong>please select a currency to display tip information</strong><br/>";
	  }
	  console.log(this.firstDateTipSearch);
	  let days = Math.floor((new Date(this.secondDateTipSearch).getTime() - new Date(this.firstDateTipSearch).getTime()) / 1000 / 60 / 60 / 24);
	  if(days <= 7){
        errors +=  "<strong>The days between the first date and second date must be greater than 7.</strong><br/>";
	  }
	  console.log("errors are: " + errors);
	  if(errors == "")
	  {
		  this.totalTipsErrorDiv.nativeElement.innerHTML = errors;
		  if(isReport == false)
		  {
			this.tipInformationService.getTotalTipsBetweenTwoDates(this.firstDateTipSearch,this.secondDateTipSearch);
			this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
		  }
		  else {
		      this.tipInformationService.createExcelJob(this.firstDateTipSearch,this.secondDateTipSearch,this.currentSearchCurrency.trim());
			  this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
		  }
	  }
	  else
	  {
		  this.totalTipsErrorDiv.nativeElement.innerHTML = errors;
	  }
	  
  }
  
  getPageAndAmountOfTipsBetweenTwoDates()
  {
	  this.queryErrors = "";
	  if(this.firstDateTipSearch == null)
	  {
		  this.queryErrors += "First Date is null" + "<br/>";
	  }
	  if(this.secondDateTipSearch == null)
	  {
		  this.queryErrors += "Second Date is null" + "<br/>";
	  }
	 /* if(this.firstDateTipSearch > this.secondDateTipSearch)
	  {
		  this.queryErrors += "First Date must not be greater then second Date";
		  console.log("First date is greater than second date");
		  console.log(this.firstDateTipSearch);
		  console.log(this.secondDateTipSearch);
	  } */
	  if(this.firstDateTipSearch != null && this.secondDateTipSearch != null && this.queryErrors == "")
	  {
		  this.tipInformationService.getAmountOfTipsBetweenTwoDates(this.firstDateTipSearch,this.secondDateTipSearch);
		  this.tipInformationService.getUserTipsBetweenTwoDates(this.firstDateTipSearch,this.secondDateTipSearch,1);
		  this.pageOfUserTipsBetweenTwoDates = 1;
		  this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
	  }
		  
  }
  backgroundGraphUrlHighlight()
  {
	  this.barbackgroundGraphUrl = this.barbackgroundGraphUrl == "./../../../assets/images/graphs/graph-green.png" ? "./../../../assets/images/graphs/graph-red.png":"./../../../assets/images/graphs/graph-green.png";
  }
  lineBackgroundChange()
  {
	  this.lineGraphUrl = this.lineGraphUrl == "./../../../assets/images/graphs/line-graph-red.png" ?  "./../../../assets/images/graphs/line-graph-purple.png" : "./../../../assets/images/graphs/line-graph-red.png";
	  this.lineGraphBackgroundStyle = this.lineGraphBackgroundStyle == "graphBackground" ? "graphHighlightedBackgroundColor" : "graphBackground";
  }
  getPageBetweenTwoDates(page:number)
  {
	  this.pageOfUserTipsBetweenTwoDates = page;
	  this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
	  this.tipInformationService.getUserTipsBetweenTwoDates(this.firstDateTipSearch,this.secondDateTipSearch,page);
  }
  getfirstDateTipSearch($event)
  {
	  console.log("date is ");
	  console.log($event);
  }
  showChart(chartToshow:string)
  {
	  if(chartToshow == "Bar")
	  {
		this.ShowGraphObservable.next("showChartBar");
      }
	  else if (chartToshow == "Line")
	  {
		  this.ShowGraphObservable.next("showChartLine")
	  }
  }
  checkIfExcelFileExist(){
  	this.tipInformationService.checkIfUserExcelFileExist(true,false);
  	this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
  }
  downLoadFinancialReport(){
	  //this.LoadingSubjectObservable.next({"Loading":"PlainLoading"});
	  this.tipInformationService.retreiveTipllyFinancialReportExcelFile(this.security_code_for_file);
  }
  
  openInputDialog()
  {
	  this.connectionToInputDialog.next({"InputDialog":"show"});
	  this.connectionToUpdatesToInputDialog.next("Website");
  }
  
  refreshList(choice:string)
  {
    switch(choice)
	{
		case "UserTips":
		       this.rotationArray[0] = "default";
			   this.tipInformationService.getUserTips(this.pageOfUserTips,true);
		       break;
		case "UserTipsRecent":
		       this.rotationArray[1] = "default";
			   this.tipInformationService.getUserTips(this.pageOfUserTipsBetweenTwoDates,false);
		       break;
	}		
  }
  getPriceOfLiteCoinInSpecificCurrency(currency:string){
	//console.log(currency);
	if((currency !== undefined && currency != null && currency != undefined) ){
	   if(currency.split("-")[1] != undefined && currency.split("-")[1].toLowerCase().trim() != "usd"){
	   //console.log("Here is getPriceOfLiteCoinInCurrency()");
	   var shortCurrency = currency.split("-")[1].toLowerCase();
	   //console.log(this.currencyConversionService.getCurrencyConversionObject()[shortCurrency.trim()]);
	   //console.log(this.currencyConversionService.getCurrencyConversionObject()[this.userInformationService.getTipUser().preferredCurrency.split("-")[1].toLowerCase().trim()]);
	   var liteCoinPriceInCurrency = (((((this.currencyConversionService.getCurrencyConversionObject()[currency.split("-")[1].toLowerCase().trim()]))["rate"] ) as any ) as number) * this.inputedLiteCoinPrice; //this.currencyConversionService.getLTCUSDPrice();
	  if(((liteCoinPriceInCurrency as any ) as number) % 1 != 0.0){
		liteCoinPriceInCurrency = Math.round((liteCoinPriceInCurrency + Number.EPSILON) * 100) / 100;
	 }
	 return liteCoinPriceInCurrency;
	
	}
}
if((currency !== undefined && currency != null && currency != undefined) ){
	if(currency.split("-")[1] != undefined && currency.split("-")[1].toLowerCase().trim() == "usd"){
		console.log(this.currencyConversionService.getLTCUSDPrice());
		var liteCoinPriceInCurrencyForUSD = (Number(this.currencyConversionService.getLTCUSDPrice() as any as number).toFixed(5) as any) as string;
		console.log("value is");
		console.log(liteCoinPriceInCurrencyForUSD);
			return liteCoinPriceInCurrencyForUSD;//(Number(this.currencyConversionService.getLTCUSDPrice() as any as number).toFixed(5) as any) as string;
		}
}
   }
  getPriceOfLiteCoinInCurrency(){
   if(this.userInformationService.getTipUser() != null && this.userInformationService.getTipUser().preferredCurrency.split("-")[1].toLowerCase().trim() != "usd"){
  	if(this.userInformationService.getTipUser().preferredCurrency.indexOf("-") != -1 && this.userInformationService.getTipUser().preferredCurrency.split("-")[1].toLowerCase().trim() != "usd"){
  	//console.log("Here is getPriceOfLiteCoinInCurrency()");
  	var shortCurrency = this.userInformationService.getTipUser().preferredCurrency.split("-")[1].toLowerCase();
  	//console.log(this.currencyConversionService.getCurrencyConversionObject()[shortCurrency.trim()]);
  	//console.log(this.currencyConversionService.getCurrencyConversionObject()[this.userInformationService.getTipUser().preferredCurrency.split("-")[1].toLowerCase().trim()]);
  	var liteCoinPriceInCurrency = (((((this.currencyConversionService.getCurrencyConversionObject()[this.userInformationService.getTipUser().preferredCurrency.split("-")[1].toLowerCase().trim()]))["rate"] ) as any ) as number) * this.currencyConversionService.getLTCUSDPrice();
     if(((liteCoinPriceInCurrency as any ) as number) % 1 != 0.0){
  	 liteCoinPriceInCurrency = Math.round((liteCoinPriceInCurrency + Number.EPSILON) * 100) / 100;
    }
    return liteCoinPriceInCurrency;
   }
   }else{
    console.log(this.currencyConversionService.getLTCUSDPrice());
    var liteCoinPriceInCurrencyForUSD = (Number(this.currencyConversionService.getLTCUSDPrice() as any as number).toFixed(5) as any) as string;
    console.log("value is");
    console.log(liteCoinPriceInCurrencyForUSD);
   	return liteCoinPriceInCurrencyForUSD;//(Number(this.currencyConversionService.getLTCUSDPrice() as any as number).toFixed(5) as any) as string;
   }
  }
  getRespectiveCurrency(){
  	//console.log("getRespectiveCurrency() called ");
  	//console.log(this.userInformationService.getTipUser().preferredCurrency);
  	return this.userInformationService.getTipUser().preferredCurrency;
  }
  resetInputedPriceOfLiteCoin(){
  	this.inputedLiteCoinPrice = this.getPriceOfLiteCoinInCurrency() as any as number;
    console.log(this.inputedLiteCoinPrice);
    if(((this.inputedLiteCoinPrice as any ) as number) % 1 != 0.0){
  	 //this.inputedLiteCoinPrice = Math.round((this.inputedLiteCoinPrice + Number.EPSILON) * 100) / 100;
    }
    console.log(this.inputedLiteCoinPrice);
  }

  openVideoModal()
  {
	  var video_url = this.videoService.getUrlOfVideo("Tiplly Tip Information Section Video");
	  this.connectionToVideoModal.next({"show":`${video_url}`});
  }
}
