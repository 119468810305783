import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpModule} from "@angular/http";
import{BrowserAnimationsModule}from "@angular/platform-browser/animations";
import { AppComponent } from './Components/app.component';
import { LandingComponent } from './Components/landing/landing.component';
import {Routes,RouterModule} from "@angular/router";
import {PathLocationStrategy,HashLocationStrategy, LocationStrategy} from "@angular/common";
import {UserInformationService} from "./services/user-information.service";

import { NgxSmartModalModule } from 'ngx-smart-modal';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, } from "angularx-social-login";
import { AuthService } from "angularx-social-login";
import {TipExecutionService} from "./services/tip-execution-service.service";
import {TipInformationService} from "./services/tip-information-service.service";
import 'css-loading';
import { PrimaryComponent } from './Components/primary/primary.component';
import { ProfileComponent } from './Components/profile/profile.component';
import { IdentityComponent } from './Components/identity/identity.component';
import {ReactiveFormsModule} from "@angular/forms";
import {FormsModule} from "@angular/forms";
import {SuiModule} from 'ng2-semantic-ui';
import { SuiSelectModule } from 'ng2-semantic-ui';
import { ShowErrorsComponent } from './Components/show-errors/show-errors.component';
import { TipInformationComponent } from './Components/tip-information/tip-information.component';
import { WebsiteComponent } from './Components/website/website.component';
import {CountdownModule} from "ngx-countdown";
import {WebsiteService} from "./services/website-service.service";
import { WebsiteFormComponent } from './Components/website-form/website-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TipComponent } from './Components/tip/tip.component';
import { YesNoComponent } from './Components/yes-no/yes-no.component';
import {NgxPopoverImageModule} from 'ngx-popover-image';
import { LoadingModalComponent } from './Components/loading-modal/loading-modal.component';
import { ReceiptComponent } from './Components/receipt/receipt.component';
import { CheckoutComponent } from './Components/checkout/checkout.component';
import {ChartsModule, ThemeService} from 'ng2-charts';
import {MyDatePickerModule} from "mydatepicker";
import {OwlDateTimeModule, OwlNativeDateTimeModule,DateTimeAdapter,OWL_DATE_TIME_FORMATS} from "ng-pick-datetime";
//import {OwlMomentDateTimeModule} from "ng-pick-datetime-moment";
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { GraphsShowingComponent } from './Components/graphs-showing/graphs-showing.component';
import {DatePipe} from "@angular/common";
import {TipGraphInformationService} from "./services/tip-graph-information.service";
import {CurrencyConversionService} from "./services/currency-conversion-service.service";
import { SupportComponent } from './Components/support/support.component';
import { ArticleComponent } from './Components/article/article.component';
import { TicketComponent } from './Components/ticket/ticket.component';
import { SupportMainComponent } from './Components/support-main/support-main.component';
import {TipHelpService} from "./services/tip-help-service.service";
import { SupportConversationComponent } from './Components/support-conversation/support-conversation.component';
import { HelpMessageDisplayComponent } from './Components/help-message-display/help-message-display.component';
import { TipplyInformationComponent } from './Components/tipply-information/tipply-information.component';
import { InputDialogComponent } from './Components/input-dialog/input-dialog.component'; 
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ArticleListComponent } from './Components/article-list/article-list.component';
import {ArticleService} from "./services/article-service.service";
import { PdfViewerComponent } from './Components/pdf-viewer/pdf-viewer.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import {SecurityService} from "./services/security.service";
import { HttpClientModule } from '@angular/common/http';
import { ExampleDialogComponent } from './Components/example-dialog/example-dialog.component';
import {TipRelatedSecurityService} from "./services/tip-related-security.service";
import {TipLinkGuard} from "./guards/tip-link-guard.guard";
import {SessionGuard} from "./guards/session-guard.guard";
import { ErrorShowingComponent } from './Components/error-showing/error-showing.component';
import {LoadingBarModule} from "@ngx-loading-bar/core";
import { NumberCounterComponent } from './Components/number-counter/number-counter.component';
import { SuccessModalComponent } from './Components/success-modal/success-modal.component';
import { WebsiteShowingComponent } from './Components/website-showing/website-showing.component';
import {VgCoreModule} from 'videogular2/compiled/core';
import {VgControlsModule} from 'videogular2/compiled/controls';
import {VgOverlayPlayModule} from 'videogular2/compiled/overlay-play';
import {VgBufferingModule} from 'videogular2/compiled/buffering';
import { VideoModalComponent } from './Components/video-modal/video-modal.component';
import { RatingModule } from 'ng-starrating';
import {RatingComponent} from "./Components/rating/rating.component";
import {VideoService} from "./services/video-service.service";
import { NgxUploaderModule } from 'ngx-uploader';
import { MiscellaneousModalComponent } from './Components/miscellaneous-modal/miscellaneous-modal.component';
import { InputModalComponent } from './Components/input-modal/input-modal.component';
import { WebsiteIntegrationTutorialComponent } from './Components/website-integration-tutorial/website-integration-tutorial.component';
import { WalletAddressComponent } from './Components/wallet-address/wallet-address.component';
import { ThirdPartyAuthenticatorComponent } from './Components/third-party-authenticator/third-party-authenticator.component';
import { PluginUserInformationComponent } from './Components/plugin-user-information/plugin-user-information.component';
import { QRCodeModule } from 'angularx-qrcode';

//import {SingleMediaPlayer} from './single-media-player';
const checkoutChildRoutes = [{path:'receipt', component:ReceiptComponent},
                             {path:':tipLink',component:TipComponent, canActivate:[TipLinkGuard]}
						     ];
const profileChildRoutes = [{path:'information',component:IdentityComponent, canActivate:[SessionGuard]},
							{path:'tipllyInformation', component:TipplyInformationComponent, canActivate:[SessionGuard]}];
const supportChildRoutes = [{path:'supportmain',component:SupportMainComponent, canActivate:[SessionGuard]},
							
							];	
const homeChildRoutes = [{path:'profile',component:ProfileComponent, children: profileChildRoutes, canActivate:[SessionGuard]},
						 {path:'tipinformation',component:TipInformationComponent, canActivate:[SessionGuard]},
						 {path:'websiteInformation',component:WebsiteComponent, canActivate:[SessionGuard]},
						 {path:'support',component:SupportComponent,children:supportChildRoutes, canActivate:[SessionGuard]},
						 {path:'websiteIntegrationTutorial',component:WebsiteIntegrationTutorialComponent,canActivate:[SessionGuard]}];					 
const route:Routes = [{path:'landing',component:LandingComponent, pathMatch:'full'},
                   	  {path:'', redirectTo:'landing', pathMatch:'full'},
					  {path:'error', component:ErrorShowingComponent},
					  {path:'tip', component:CheckoutComponent, pathMatch:'prefix', children:checkoutChildRoutes},
					  {path:'home',component:PrimaryComponent, pathMatch:'prefix',children:homeChildRoutes},
					  {path:'pdfView/:pdfId',component:PdfViewerComponent},
					  {path:'supportconversation',component:SupportConversationComponent,canActivate:[SessionGuard]},
					  {path:'pluginUserWalletAddresses', component:WalletAddressComponent,pathMatch:'full'},
					  {path:'viewAuthenticatorInformation',component:ThirdPartyAuthenticatorComponent,pathMatch:'full'},
					  {path:'pluginUserDetails',component:PluginUserInformationComponent,pathMatch:'full'}];

					  
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("796007062106-7uida8olq7lekc3d09igg3nao3lfuerr.apps.googleusercontent.com")
  }
]); 
let base_url = /*"http://localhost:8080"; */"https://tiplly.com/api";
export function provideConfig() {
  return config;
}
export const MY_MOMENT_FORMATS = {
    parseInput: 'LLLL',
    fullPickerInput: 'l LT', // CHANGE THIS TO A MOMENT.JS FORMAT
    datePickerInput: 'l', // CHANGE THIS TO A MOMENT.JS FORMAT
    timePickerInput:'H HH', // CHANGE THIS TO A MOMENT.JS FORMAT
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    PrimaryComponent,
    ProfileComponent,
    IdentityComponent,
    ShowErrorsComponent,
    TipInformationComponent,
    WebsiteComponent,
    WebsiteFormComponent,
    TipComponent,
    YesNoComponent,
    LoadingModalComponent,
    ReceiptComponent,
    CheckoutComponent,
    GraphsShowingComponent,
    SupportComponent,
    ArticleComponent,
    TicketComponent,
    SupportMainComponent,
    SupportConversationComponent,
    HelpMessageDisplayComponent,
    TipplyInformationComponent,
    InputDialogComponent,
    ArticleListComponent,
    PdfViewerComponent,
    ExampleDialogComponent,
    ErrorShowingComponent,
    NumberCounterComponent,
    SuccessModalComponent,
    WebsiteShowingComponent,
    VideoModalComponent,
	RatingComponent,
	MiscellaneousModalComponent,
	InputModalComponent,
	WebsiteIntegrationTutorialComponent,
	WalletAddressComponent,
	ThirdPartyAuthenticatorComponent,
	PluginUserInformationComponent
    
  ],
  imports: [
    RouterModule.forRoot(route),
    BrowserModule,
	NgxPaginationModule,
	BrowserAnimationsModule,
	HttpModule,
	NgxSmartModalModule.forRoot(),
	ReactiveFormsModule,
	SuiModule,
	SuiSelectModule,
	CountdownModule,
	FormsModule,
	NgxPopoverImageModule,
	ChartsModule,
	//OwlDateTimeModule,
	//OwlNativeDateTimeModule,
	MyDatePickerModule,
//	OwlMomentDateTimeModule,
	AngularDateTimePickerModule,
	//NgbModule,
	PdfJsViewerModule,
	AngularFontAwesomeModule,
	HttpClientModule,
	LoadingBarModule,
	VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
	RatingModule,
	NgxUploaderModule,
	QRCodeModule
	
	
	

  ],
 
  providers: [{provide:LocationStrategy,useClass:HashLocationStrategy},
	          {provide:"base_href", useValue:base_url},
			  UserInformationService,
			  TipInformationService,
			  TipExecutionService,
			  TipGraphInformationService,
			   {provide: AuthServiceConfig,useFactory: provideConfig},
			  AuthService,
			  WebsiteService,
			  {provide:OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
			  DatePipe,
			  CurrencyConversionService,
			  TipHelpService,
			  ArticleService,
			  SecurityService,
			  TipRelatedSecurityService,
			  VideoService,
			  TipLinkGuard,
			  ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
