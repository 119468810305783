import{trigger,transition,state,style,animate} from "@angular/animations";

export function rotate()
{
 return  trigger('rotatedAnim', [
            state('default', style({transform: 'rotate(0deg)' })),
            state('rotated', style({ transform: 'rotate(1800deg)' })),
            state('fini',style({display:'inline'})),
            transition('default => rotated', animate('3500ms')),
			transition('rotated => default', animate('3500ms'))
        ]);
}

export function rotation()
{
  return rotate();
}
