export class PendingWebsite {

  constructor(public website:string, public dateAdded:Date, public websiteCode:string, public minutesToCheck:number)
  {
	  
  }
  
    getTimeLeftBetweenVerification()
	{
		var firstDate: Date = new Date(this.dateAdded.getTime() + this.minutesToCheck * 60000);
		var secondDate = new Date();
	    var difference = (firstDate.getTime() - secondDate.getTime())/1000;
		return difference;
		
	}
}
