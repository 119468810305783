import { Component, OnInit,Input, ElementRef,ViewChild,AfterViewInit } from '@angular/core';
import {FormBuilder, FormGroup,Validators} from "@angular/forms";
import {Flags} from "./../../exportedValues/flags";
import {FormSuggestionHelpers} from "./../../exportedValues/form-suggestion-helpers";
import {Miscellaneous} from "./../../exportedValues/miscellaneous";
import {UserInformationService} from "./../../services/user-information.service";
import  {BankAccount} from "./../../Models/bank-account.model";
import {Card} from "./../../Models/card.model";
import {Subject} from "rxjs";
import {timer}  from 'rxjs';
import {loadStripe} from '@stripe/stripe-js';
@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css','../../../../node_modules/purecss/build/pure-min.css']
})
export class IdentityComponent implements OnInit,AfterViewInit {
  timerObservable:any;	
  @Input() formsa:FormGroup;
  pushForm:Subject<Array<FormGroup>>;
  margin:Number = 300;
  CurrentCountry:String = "";
  CurrentCurrency:String = "";
  flags:Flags;
  miscellaneous: Miscellaneous;  
  formHelpers:FormSuggestionHelpers;
  addressForm:FormGroup;
  individualData:FormGroup;
  bankData:FormGroup;
  cardData:FormGroup;
  currentFlag:String;
  paymentDisplay:String = "";
  @ViewChild("tosCheckBox",{static:false}) tosCheckBox:ElementRef;
  @ViewChild("agreementErrorSpan",{static:false}) agreementErrorSpan:ElementRef;
  @ViewChild("bankFormErrorDiv",{static:false}) bankFormErrorDivBank :ElementRef;
  @ViewChild("cardFormErrorDiv",{static:false}) cardFormErrorDiv:ElementRef;
  public connectionToExamplesModal:Subject<{[key:string]:string}>;
  public connectionToSuccessModal:Subject<{[key:string]:string}>;
  public connectionToLoadingModal:Subject<{[key:string]:string}>;
  public updatesToLoadingModal:Subject<string>;
  public connectionToMiscellaneousModal:Subject<{[key:string]:string}>;
  public connectionToErrorModal:Subject<{[key:string]:string}>;
  public connectionToYesNoModal:Subject<{[key:string]:string}>;
  public bankAccount:BankAccount;
  public files:any;
  public stripe:any;
  public card:any;
  test:Array<String> = ["dfsdf","ddsfsdf","dsfdsf"];

  
  constructor(private formBuilder:FormBuilder, public userInformationService:UserInformationService) {
	  this.flags = new Flags();
	  this.pushForm = new Subject<Array<FormGroup>>();
	  this.timerObservable = timer(1000,1000);
	  this.miscellaneous = new Miscellaneous();
	  this.formHelpers = new FormSuggestionHelpers();
	  this.addressForm = this.formBuilder.group({
		 Country:["",Validators.compose([Validators.required])],
		 StateorProvince: ["",Validators.compose([Validators.required])],
		 City:["",Validators.compose([Validators.required])],
		 PostalCode:["",Validators.compose([Validators.required])],
		 Street:["",Validators.compose([Validators.required])],
		 HouseNumber:["",Validators.compose([Validators.required])]  
	  });
	  this.individualData = this.formBuilder.group({
		 dateofBirth:["",Validators.compose([Validators.required])],
         identityNumber:["",Validators.compose([Validators.required])],
         gender:["",Validators.compose([Validators.required])],
         lastName:["",Validators.compose([Validators.required])],
         firstName:["",Validators.compose([Validators.required])],
         phoneNumber:["",Validators.compose([Validators.required])]		 
	  });
	  this.bankData = this.formBuilder.group({
		 fullName:[null,Validators.compose([Validators.required])],
         branchCode:[null,Validators.compose([Validators.required])],
		 bankName:[null,Validators.compose([Validators.required])],
         CountryCode:[null,Validators.compose([Validators.required])],
         CurrencyCode:[null,Validators.compose([Validators.required])],
         AccountType:["checking",Validators.compose([Validators.required])],
         AccountNumber:[null,Validators.compose([Validators.required])]		 
	  });
	  this.cardData = this.formBuilder.group({cardNumber:[null,Validators.compose([Validators.required])],
											  cardMonthExpirationDate:[null,Validators.compose([Validators.required])],
											  cardYearExpirationDate:[null,Validators.compose([Validators.required])],
											  cardCVC:[null,Validators.compose([Validators.required])]});
	  this.individualData.controls['phoneNumber'].valueChanges.subscribe((value) => {console.log(this.individualData.controls['phoneNumber'].value)})
	  this.bankData.controls["fullName"].valueChanges.subscribe((value)=>{console.log("value is " + value);});
	  this.timerObservable.subscribe(value => {
		  this.individualData.controls["dateofBirth"].setValue((document.getElementById("birthDate") as HTMLInputElement).value);
		  console.log( this.individualData.controls["dateofBirth"].value);
	  });
	  this.connectionToExamplesModal = new Subject<{[key:string]:string}>();
	  this.connectionToSuccessModal = new Subject<{[key:string]:string}>();
	  this.connectionToLoadingModal = new Subject<{[key:string]:string}>();
	  this.updatesToLoadingModal = new Subject<string>();
	  this.connectionToMiscellaneousModal = new Subject<{[key:string]:string}>();
	  this.connectionToErrorModal = new Subject<{[key:string]:string}>();
	  this.connectionToYesNoModal = new Subject<{[key:string]:string}>();
	  this.files = [];
  }
  
  submitForm()
  {
	  console.log("pushed");
	  var FormGroups:Array<FormGroup> = new Array<FormGroup>();
	  FormGroups.push(this.addressForm);
	  FormGroups.push(this.individualData);
	  FormGroups.push(this.bankData);
	  this.pushForm.next(FormGroups);
  }
  async loadStripeJs()
  {
	  this.stripe = await loadStripe("pk_live_51H9bOILM1YlTZauwoMyrXihMRVwL5oYzaKzlhgMnkrnVdN5sIAxLTYHlRizI9t2geYuBJvp4r56L6f7tjiSSUe8500LKLJFFqg");
	  
  }

  ngOnInit() {
	  this.loadStripeJs();
	  this.userInformationService.getUserInformationSubject().subscribe((value) => {
		  this.updatesToLoadingModal.next("GotInformationNowClose");
		 if(value["redirectLink"])
		 {
			 this.userInformationService.acceptStripeTOS();
			 var redirectUrl = value["redirectLink"];
			 this.updatesToLoadingModal.next("GotInformationNowClose");
			 this.connectionToMiscellaneousModal.next({"show":"redirectToStripe"});
			 setTimeout(function(){
			     window.location.href = redirectUrl;
			 },5000);
			 
		 }
		 else if(value["bankAccount"] || value["cardAccount"]){
		    this.connectionToSuccessModal.next({"show":"open"});
			this.connectionToSuccessModal.next({"extraData":"Now you must get a tip and wait 7-10 days"});
			this.userInformationService.getTipUser().hasBankAccountAttached = 2;
		 }
		 else if(value["uploadProof"])
		 {
			 this.connectionToSuccessModal.next({"show":"open"});
		 }
		 else if(value["err"])
		 {
			 this.connectionToErrorModal.next({"show":"retrieveError"});
			 console.log(value);
			 if(value["err"] == "failedToDelete")
			 {
				 this.connectionToErrorModal.next({"information":"You have a pending balance in stripe account cannot delete account"});
			 }
		 }
	  });
	  this.connectionToYesNoModal.subscribe((value) =>{
				console.log(value);
				if(value["confirmReplaceCountry"] == "yes"){
				   //this.connectionToYesNoModal.next({"YesNo":"areYouSure"});
				   this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
				   this.userInformationService.createUsersStripeAccount((this.addressForm.controls["Country"].value).toString().split("-")[1].trim());
				  
							   
				}
			
	});
  }
  onSubmitFiles(event: EventTarget)
  {
	    var errors = "";
	    var eventObj: MSInputMethodContext = <MSInputMethodContext> event;
        var target: HTMLInputElement = <HTMLInputElement> eventObj.target;
        var filei: any = target.files[0];
		var filesize = ((filei.size/1024)/1024).toFixed(4); 
		console.log(filei);
		
		if(filei !== undefined && ((filesize as unknown) as number) < 10){
			this.files = [];
			this.files.push(filei)
			
		}
		else
		{
			errors += "<li>File size must be 10 mb or less. Files can only be pdf, jpg, or png</li>";
		}
	   var which = this.userInformationService.getTipUserIsVerified() == -1 ? true :false;
	   this.submitFilesToServer(which);
       console.log(this.files);
  }
  submitFilesToServer(which:boolean)
  {
	  this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
	  this.userInformationService.submitFilesForVerification(this.files,which);
  }
  setBankData(value:String, which:String)
  {
	   console.log(value);
	  switch(which)
	  {
		case "CountryCode":   this.bankData.controls["CountryCode"].setValue(value);
				        	  break;
        case "CurrencyCode": this.bankData.controls["CurrencyCode"].setValue((value.split("-")[1]).trim());
						     break;
		case "AccountType" : this.bankData.controls["AccountType"].setValue(value);
		                     break;
       	
	  }
  }
  createBankModelAndSend()
  {
	  this.setBankData("checking","AccountType");
	  this.bankFormErrorDivBank.nativeElement.innerHTML = "";
	  this.bankAccount = new BankAccount(this.bankData.controls["fullName"].value,this.bankData.controls["AccountNumber"].value, this.bankData.controls["branchCode"].value, this.bankData.controls["CountryCode"].value,
	                                     this.bankData.controls["bankName"].value, this.bankData.controls["CurrencyCode"].value);
	  console.log(this.bankData);
	  if(this.bankData.valid)
	  {
	  
		  console.log(this.bankAccount);
		  this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
		  this.userInformationService.updateStripeUsersBankInformation(this.bankAccount);
	  }
	  else
	  {
		  this.bankFormErrorDivBank.nativeElement.innerHTML = "<div style='color:red;'>Please enter all values for form before submission</div>";
		  console.log("form is invalid");
	  }
  }
  createCardModelAndSend()
  {
	  var card:Card;
	  this.cardFormErrorDiv.nativeElement.innerHTML = "";
	  var userInformationService = this.userInformationService;
	  var currency_depo = this.bankData.controls["CurrencyCode"].value;
	  currency_depo = currency_depo.split("-")[1].trim();
	  this.stripe.createToken(this.card,{"currency": currency_depo}).then(function(result) {
        console.log(result);
		var access_result = (result as any);
		console.log(access_result);
		userInformationService.updateStripeUserDebitCardPaymentInformation(result.token.id);
      });
	 // if(this.cardData.valid)
	  ///{
		//  card = new Card(this.cardData.controls["cardNumber"].value,this.cardData.controls["cardMonthExpirationDate"].value,this.cardData.controls["cardYearExpirationDate"].value,this.cardData.controls["cardCVC"].value,);
	      //this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
		//  this.userInformationService.updateStripeUserDebitCardPaymentInformation(card);
	//  }else{
	  //  this.cardFormErrorDiv.nativeElement.innerHTML = "<div style='color:red;'>Please enter all values for form before submission</div>";
	 // }
	  
	  
  }
  setGender(value:String)
  {
	  console.log("setGeneder called" + value);
	  if(value == "Male")
	  {
		 document.getElementById("dropdownGender").style.marginRight = "92.5px"; 
	  }
	  else if(value == "Female")
	  {
		document.getElementById("dropdownGender").style.marginRight = "78.5px";  
	  }
	  else
	  {
		  document.getElementById("dropdownGender").style.marginRight = "45px";
	  }
	  
      
	  
	  this.individualData.controls["gender"].setValue(value);
  }
  setCountry(value:String,flag:String)
  {
	  console.log("value is" + flag);
	  this.currentFlag = flag;
	  this.addressForm.controls["Country"].setValue(value);
  }
  getUserBankStatus():number
  {
	  return this.userInformationService.getTipUser().hasBankAccountAttached;
  }
  submitToServer()
  {
	    
		this.agreementErrorSpan.nativeElement.innerHTML = "";
	    console.log(this.tosCheckBox);
		console.log(this.tosCheckBox.nativeElement);
		console.log(this.addressForm.controls["Country"].value.toString());
	   if(this.tosCheckBox.nativeElement.checked == true && (this.addressForm.controls["Country"].value).toString() != ""){	  
			//console.log(value);	
			if((this.userInformationService.getTipUser().country != null) && (this.userInformationService.getTipUser().country.toLowerCase() == "" || ((this.addressForm.controls["Country"].value).toString().split("-")[1].trim().toLowerCase() != this.userInformationService.getTipUser().country.trim().toLowerCase())) ){
				
				this.connectionToYesNoModal.next({"YesNo":"confirmReplaceCountry"});
			}
			else
			{
				 this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
				 this.userInformationService.createUsersStripeAccount((this.addressForm.controls["Country"].value).toString().split("-")[1].trim());
			}
	   }
	   else
	   {
		   this.agreementErrorSpan.nativeElement.innerHTML = "Please select a country and agrree to stripe's and tiplly's tos before continuing. ";
	   }
  }
  bringUpExamplesModal(key:string)
  {
	  this.connectionToExamplesModal.next({"example":`${key}`});
  }
  loadCard()
  {
	   
	  var elements = this.stripe.elements();
	  var style = {
				  base: {
					color: '#32325d',
					fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
					fontSmoothing: 'antialiased',
					maxWidth:'100px',
					width:'100px',
					fontSize: '16px',
					'::placeholder': {
					  color: '#aab7c4'
					}
					
				  },
				  invalid: {
					color: '#fa755a',
					iconColor: '#fa755a'
				  }
	  };
	  this.card = elements.create('card', {style: style});
  }
  ngAfterViewInit()
  {
	  var elements = this.stripe.elements();
	  var style = {
				  base: {
					color: '#32325d',
					fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
					fontSmoothing: 'antialiased',
					maxWidth:'100px',
					width:'100px',
					fontSize: '16px',
					'::placeholder': {
					  color: '#aab7c4'
					}
					
				  },
				  invalid: {
					color: '#fa755a',
					iconColor: '#fa755a'
				  }
	  };
	  this.card = elements.create('card', {style: style});
	  this.card.mount('#card-element');
  }
}
