import { Component, OnInit,Input } from '@angular/core';
import {Subject} from "rxjs"
import {NgxSmartModalService} from "ngx-smart-modal"; 
@Component({
  selector: 'Example-Dialog',
  templateUrl: './example-dialog.component.html',
  styleUrls: ['./example-dialog.component.css']
})
export class ExampleDialogComponent implements OnInit {

  @Input() public connectionDialog:Subject<{[key:string]:string}>;
  public showHelper:string;
  constructor(private ngxSmartModalService:NgxSmartModalService) {
  }

  ngOnChanges(changes)
  {
	if(changes["connectionDialog"])
	{
		this.connectionDialog.subscribe((value) =>{
			this.showHelper = value["example"];
			this.ngxSmartModalService.getModal("Example").open();
		});
	}		
  }
  
  ngOnInit() {
  }

}
