import { Component, OnInit } from '@angular/core';
import { MiscellaneousService } from 'src/app/services/miscellaneous-service.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-wallet-address',
  templateUrl: './wallet-address.component.html',
  styleUrls: ['./wallet-address.component.css']
})
export class WalletAddressComponent implements OnInit {
  wallets: Array<string>;
  private loading:string = "true";
  constructor(private miscellaneousService:MiscellaneousService, private route:ActivatedRoute) {
    this.wallets = new Array<string>();
   
  }

  ngOnInit() {
    this.miscellaneousService.getResultsSubject().subscribe((value) => {
      console.log(value);
      if(value["purpose"] == "wallets" && value["result"] == "successful" ){
           console.log(value);
           this.loading = "false";
           var wallets_json = (value["body"])["wallletList"];
           console.log(wallets_json);
           for(var i = 0; i < wallets_json.length; i++){
            this.wallets.push(wallets_json[i]);
           }

      }
      else if(value["purpose"] == "wallets" && (value["results"] == "error" || value["results"] == "failure")){
        this.loading = "error";
      }
    });
    this.route.queryParams.subscribe((value) =>{
      if(value["username"]){
        this.miscellaneousService.getAllWalletAddresses(value["username"]);
      }
      
    });
  }

}
