import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import {timeout} from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class TipllyPluginService {
  private timeout_:any = 300000;
  private connectionToTipllyPluginService:Subject<{[key:string]:any}>;
  private code_:any = null;
  private email_code:any = null;
  private user_information: any;
  constructor(private httpClient:HttpClient, @Inject("base_href")private base_url:string) {
    this.connectionToTipllyPluginService = new Subject<{[key:string]:any}>();
  }

  getConnectionToTipllyPluginService(){
    return this.connectionToTipllyPluginService;
  }
  getPast50TipsForPluginUser(username:string){
    var url_ =`${this.base_url}/UserPlugin/getPast50Tips/ForWebAppUser?username=${username}`;
    var headers_ = new HttpHeaders().append("emailCode",this.email_code).append("code",this.code_);
    this.httpClient.get(url_,{observe:"response",headers:headers_}).pipe(timeout(this.timeout_)).subscribe((success) =>{
      console.log(success);
      var response_body = success["body"] as any;
      this.connectionToTipllyPluginService.next({"past50Tips":response_body});
   },(err) =>{
       console.log(err);
      if(err.hasOwnProperty("status")){
       this.connectionToTipllyPluginService.next({"past50Tips":"server_error"});
      }else{
        this.connectionToTipllyPluginService.next({"past50Tips":"timedOut"});
      }
  
   });
  }
  submitEmailCode(username:string,emailCode:string){
    var url_ = `${this.base_url}/UserPlugin/getAmountInWalletAndWalletAddress/ForWebAppUser?username=${username}&isSpender=true`;
    console.log(url_);
    var headers_ = new HttpHeaders().append("emailCode",emailCode).append("code",this.code_);
    this.httpClient.get(url_,{observe:"response",headers:headers_}).pipe(timeout(this.timeout_)).subscribe((success) =>{
      console.log(success);
       var response_body = success["body"] as any;
       this.connectionToTipllyPluginService.next({"submitEmailCode":response_body});
       this.email_code = emailCode;
       this.user_information = response_body;
    },(err) =>{
       if(err.hasOwnProperty("status")){
        console.log(err);
        this.connectionToTipllyPluginService.next({"submitEmailCode":"server_error"});
       }else{
         this.connectionToTipllyPluginService.next({"submitEmailCode":"timedOut"});
       }
   
    });
  }
  sendEmailToUserForPlugin( username:string){
    var url = `${this.base_url}/user/SignInOrSignUpForWebAppMuser?username=${username}`;
    console.log(url);
    this.httpClient.post(url,null,{observe:"response"}).pipe(timeout(this.timeout_)).subscribe((success) =>{
       this.connectionToTipllyPluginService.next({"sendEmailToUserForPlugin":"success"});
       this.code_ = success.headers.get("code");
    }, (err) =>{
      console.log(err);
      if(err.hasOwnProperty("status")){
        this.connectionToTipllyPluginService.next({"sendEmailToUserForPlugin":"failed"});
      
      }else{
        this.connectionToTipllyPluginService.next({"sendEmailToUserForPlugin":"timeout"});
      }
    });
  }


}
