import { Component, OnInit, Input,EventEmitter, Output } from "@angular/core";
import {Article} from "./../../Models/article.model";
@Component({
  selector: 'article-select',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class ArticleComponent implements OnInit {

  @Input("article") article:Article;
  @Output("clickedArticle") clickedArticle:EventEmitter<String>;
  constructor() {
    this.clickedArticle = new EventEmitter<String>();
  }

  ngOnInit() {
  }
  
  clickArticle()
  {
	  console.log(this.article.articleID);
	  this.clickedArticle.emit(this.article.articleID);
  }
  
 

  ngOnChanges(changes)
  {
      if(changes["article"])
	  {
		  
	  }		  
  }

}
