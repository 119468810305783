import { Injectable, Inject } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserInformationService} from "./user-information.service";
import {TimeGroupTip} from "./../Models/time-group-tip";
import {Subject} from "rxjs";
import { SecurityService } from './security.service';
@Injectable()
export class TipGraphInformationService {
 
  /*type Time = {
    hours: number;
    minutes: number;
  }; */
  emitGraphInformation:Subject<Array<TimeGroupTip>>;
  errorGraphInformationConnection:Subject<{[key:string]:string}>;
  private base_url:string = ""
  constructor(private http:HttpClient, private userInformationService:UserInformationService, @Inject("base_href")private base_href:string, private securityService:SecurityService) 
  {
    this.emitGraphInformation = new Subject<Array<TimeGroupTip>>();
	this.errorGraphInformationConnection = new Subject<{[key:string]:string}>();
	this.base_url = `${base_href}/tip`;
  }
  getGraphInformationObservable()
  {
	  return this.emitGraphInformation;
  }
  getGraphInformationErrorObservable()
  {
	  return this.errorGraphInformationConnection;
  }
  getTipsInformationTotalForMonths(date:Date, secondDate:Date)
  {
	  var username = this.userInformationService.getTipUsername();
	  var tipInformationTotalForMonthUrl:string = `${this.base_url}/getTipsInformationTotalForMonths?username=${username}&firstMonthDate=${date}&secondMonthDate=${secondDate}&timezone=${secondDate}`;
      console.log(tipInformationTotalForMonthUrl);
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(tipInformationTotalForMonthUrl,{headers:headerOptions}).subscribe((success) =>{
		  console.log(success);
	      var array_of_time_group_tips: Array<TimeGroupTip> = new Array<TimeGroupTip>();	 
		  var jsonInformation = ((success as unknown) as any);
		  for(var x = 0; x < jsonInformation.length; x++)
		  {
			  var selectJson = jsonInformation[x];
			  var firstDate = new Date(selectJson["date"]);
			  var secondDate = new Date(selectJson["to_date"]);
			 // var tipSummarizeList = selectJson["tipSummarizeList"];
			 // console.log(tipSummarizeList);
			 // for(var w =0; w < tipSummarizeList.length; w++)
			 // {
			//	  var tempSummarize = tipSummarizeList[w];
				  var tempGroupTip:TimeGroupTip = new TimeGroupTip(firstDate, secondDate, selectJson["tip_amount"] );
			      array_of_time_group_tips.push(tempGroupTip);
			 // }
			  
		  }
		  this.emitGraphInformation.next(array_of_time_group_tips);
		  
	  },(err) =>{
		  this.errorGraphInformationConnection.next({"Graph":"Failed to retrieve information"});
	  });
  }
  getTipsInformationForDay(date:Date)
  {
	  var username = this.userInformationService.getTipUsername();
	  var tipInformationFortheDayUrl:string = `${this.base_url}/getTipsInformationForDay?username=${username}&timezone=${date}`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(tipInformationFortheDayUrl,{headers:headerOptions}).subscribe((success) =>{
		  console.log(success);
		  var array_of_time_group_tips : Array<TimeGroupTip> = new Array<TimeGroupTip>();
	      var jsonInformation = ((success as unknown) as any);
	      for(var x =0; x < jsonInformation.length; x++)
		  {
			  var selectJson = jsonInformation[x];
			  var firstDate = new Date(selectJson["date"]);
			  var secondDate = new Date(selectJson["to_date"]);
			//  var tipSummarizeList = selectJson["tipSummarizeList"];
			 // console.log(tipSummarizeList);
			  //for(var w =0; w < tipSummarizeList.length; w++)
			  {
				 // var tempSummarize = tipSummarizeList[w];
				  var tempTimeGroupTip:TimeGroupTip = new TimeGroupTip(firstDate,secondDate,(selectJson["tip_amount"] as number));
			      array_of_time_group_tips.push(tempTimeGroupTip);
			  }
			  this.emitGraphInformation.next(array_of_time_group_tips);
		  }
	  },(err)=>{
		  console.log(err);
		  this.errorGraphInformationConnection.next({"Graph":"Failed to retrieve information"});
	  });
  }
  getTipsInformationForMonth(date:Date)
  {
	  var username = this.userInformationService.getTipUsername();
	  var year = date.getFullYear();
	  var month = date.getMonth();
	  var tipInformationForTheMonthUrl:string = `${this.base_url}/getTipsInformationForMonth?username=${username}&year=${year}&month=${month}&timezone=${date}`;
   	  var headerOptions = this.securityService.generateAndReturnHeaders();
	 this.http.get(tipInformationForTheMonthUrl,{headers:headerOptions}).subscribe((success) =>{
		 var array_of_time_group_tips : Array<TimeGroupTip> = new Array<TimeGroupTip>();
		 var jsonInformation = ((success as unknown) as any);
		 console.log(jsonInformation);
		 for(var x = 0; x < jsonInformation.length; x++)
		 {
			 var selectJson = jsonInformation[x];
		     var firstDate = new Date(selectJson["date"]);
			 var secondDate = new Date(selectJson["to_date"]);
			 //var tipSummarizeList = selectJson["tipSummarizeList"];
			 //console.log(tipSummarizeList);
			 //for(var w = 0; w < tipSummarizeList.length; w++)
			 //{
				// var tempSummarize = tipSummarizeList[w];
				 var tempTimeGroupTip:TimeGroupTip = new TimeGroupTip(firstDate,secondDate,(selectJson["tip_amount"] as number));
	   		     console.log(tempTimeGroupTip);
				 array_of_time_group_tips.push(tempTimeGroupTip);
			 //}
		 }
		 this.emitGraphInformation.next(array_of_time_group_tips);
		 
	  },(err) =>{
		  console.log(err);
		  this.errorGraphInformationConnection.next({"Graph":"Failed to retrieve information"});
	  });
  }
  getTipInformationBetweenTwoDates(firstDate:Date,secondDate:Date)
  {
	  var username = this.userInformationService.getTipUsername();
	  var tipInformationBetweenTwoDatesUrl = `${this.base_url}/getTipInformationBetweenTwoDays?username=${username}&Dateone=${firstDate}&Datetwo=${secondDate}`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(tipInformationBetweenTwoDatesUrl,{headers:headerOptions}).subscribe((success) => {
		  console.log(success);
		  var array_of_time_group_tips:Array<TimeGroupTip> = new Array<TimeGroupTip>();
		  var jsonInformation = ((success as unknown) as any);
		  for(var x = 0; x < jsonInformation.length; x++)
		  {
			  var selectJson = jsonInformation[x];
			  var firstDate = new Date(selectJson["date"]);
			  var secondDate = new Date(selectJson["to_date"]);
			//  var tipSummarizeList = selectJson["tipSummarizeList"];
			  //console.log(jsonInformation[x])
			  //console.log(tipSummarizeList);
			 // for(var w =0; w < tipSummarizeList.length; w++)
			  {
				//  var tempSummarize = tipSummarizeList[w];
				  var tempTimeGroupTip:TimeGroupTip = new TimeGroupTip(firstDate,secondDate,(selectJson["tip_amount"] as number));
				  console.log(tempTimeGroupTip);
				  array_of_time_group_tips.push(tempTimeGroupTip);
				  
			  }
		  }
		  this.emitGraphInformation.next(array_of_time_group_tips);
	  },
	  (err) =>{
		  console.log(err);
		  this.errorGraphInformationConnection.next({"Graph":"Failed to retrieve information"});
	  },() => {
	      console.log("done");
	  });
  }
}
