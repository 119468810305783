import { Component, OnInit, Input } from '@angular/core';
import {NgxSmartModalService} from  "ngx-smart-modal";
import{Subject} from "rxjs";
@Component({
  selector: 'miscellaneous-modal',
  templateUrl: './miscellaneous-modal.component.html',
  styleUrls: ['./miscellaneous-modal.component.css']
})
export class MiscellaneousModalComponent implements OnInit {
  @Input()miscellaneousModalSubject:Subject<{[key:string]:string}>;
  toShow:string ="";
  message:string = "";
  constructor(private ngxSmartModalService:NgxSmartModalService) 
  { 
    
  }
  ngOnChanges(changes) {
     if(changes["miscellaneousModalSubject"])
	 {
		 this.miscellaneousModalSubject.subscribe((value) =>{
			if(value["show"])
			{
				this.toShow = value["show"];
				this.ngxSmartModalService.get("miscModal").open();
			}
             if(this.toShow == "redirectToStripe")
			 {
				 this.message = "Redirecting to stripe in 5 seconds to start or resume KYC verification";
			 }
			 if(this.toShow == "successfulReturn")
			 {
				 this.message = "Successfully returned from stripe KYC verification";
			 }
		 });
	 }
  }
  ngOnInit() {
  }

}
