export class HelpMessage {
	
	public the_Message:string;
	public username:string;
	public message_date:Date;
	constructor(message:string, username:string, message_date:Date)
	{
		this.the_Message = message;
		this.username = username;
		this.message_date = message_date;
		
	}
}
