import { Component, OnInit,AfterViewInit,AfterContentChecked,AfterContentInit } from '@angular/core';
import {TipHelpService} from "./../../services/tip-help-service.service";
import {VideoService} from "./../../services/video-service.service";
import {Subject} from "rxjs";
import {ViewChild, ElementRef} from "@angular/core";
import {HelpQuery} from "./../../Models/help-query.model";
import {HelpTicket} from "./../../Models/help-ticket.model";
import {Router,ActivatedRoute} from "@angular/router";
import {HeadingContext} from "./../../exportedValues/heading-context.model";
import {rotation} from "./../../../animations/rotateAnimation.animation";
import {Miscellaneous} from "./../../exportedValues/miscellaneous";
@Component({
  selector: 'app-support-main',
  templateUrl: './support-main.component.html',
  styleUrls: ['./support-main.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css'],
  animations:[rotation()]
})
export class SupportMainComponent implements OnInit,AfterViewInit , AfterContentChecked /*,  AfterContentInit*/ {
   
  buttonColorArray:Array<String> = ["ui","red","button"];
  supportCategory:string = "Monetization";
  supportCategoryArray:Array<String>;
  array_of_help_tickets:Array<HelpTicket>;
  pagesOfHelps:number;
  currentPageOfHelps:number = 1;
  numberOfHelpTickets:number; 
  arrayOfHelpTicketsPagesCount:Array<number>;
  numberOfArticles:number;
  headingContext:HeadingContext;
  errors:string;
  connectionToLoadingModal:Subject<{[key:string]:string}>;
  updatesToLoadingModal:Subject<string>;
  connectionToErrorModal:Subject<{[key:string]:string}>;
  connectionToSuccessModal:Subject<{[key:string]:string}>;
  connectionToVideoModal:Subject<{[key:string]:string}>;
  rotateAnimation:string="";
  misc:Miscellaneous;
 // timer_r:timer;
  @ViewChild("textAreaInfo",{static:false}) textAreaInfo:ElementRef;
  constructor(private tipHelpService:TipHelpService, private router:Router, private videoService:VideoService, private route:ActivatedRoute) 
  { 
     this.supportCategoryArray = new Array<String>();
	 this.connectionToLoadingModal = new Subject<{[key:string]:string}>();
	 this.connectionToErrorModal = new Subject<{[key:string]:string}>();
	 this.updatesToLoadingModal = new Subject<string>();
	 this.connectionToSuccessModal = new Subject<{[key:string]:string}>();
	 this.connectionToVideoModal = new Subject<{[key:string]:string}>();
	 this.headingContext = new HeadingContext();
	 this.array_of_help_tickets = new Array<HelpTicket>();
	 this.misc = new Miscellaneous();
	 this.tipHelpService.getHelpCategoriesObservable().subscribe((value) =>{
		this.supportCategoryArray = value.map(x => x);
        		
	 });
	 //this.tipHelpService.getCategoriesOfHelp();
     this.tipHelpService.getCountOfHelpTicketsObservable().subscribe((value) =>{
		 this.numberOfHelpTickets = value["numberOfHelpTickets"];
		 var count_of_pages = (this.numberOfHelpTickets / 5);
		 console.log("number of pages are : " +count_of_pages)
		 //count_of_pages = this.numberOfHelpTickets % 5 != 0 ? count_of_pages + 1 : count_of_pages;
		 this.arrayOfHelpTicketsPagesCount = new Array<number>();
		 for(var i = 0; i < count_of_pages; i++)
		 {
			 this.arrayOfHelpTicketsPagesCount.push(1);
		 }
		 console.log("Length of array is " + this.arrayOfHelpTicketsPagesCount.length)
	 });
     //this.tipHelpService.getCountOfUserTickets();
	 this.tipHelpService.getArrayOfHelpRequestTicketsObservable().subscribe((value) => {
		 console.log(value);
		 this.rotateAnimation = "fini";
		 this.updatesToLoadingModal.next("GotInformationNowClose");
		 var array_of_help_tickets:Array<HelpTicket> = (value as  Array<HelpTicket>);
		 this.array_of_help_tickets = (value as  Array<HelpTicket>);
		 console.log(array_of_help_tickets);
	 });
	 //this.tipHelpService.getUserTickets(1);
	 this.tipHelpService.getHelpMiscellaneousRelatedObservable().subscribe((value) =>{
		 this.updatesToLoadingModal.next("GotInformationNowClose");
		 if(value["submitHelpRequest"] == "successful")
		 {
			 this.connectionToSuccessModal.next({"show":"open"});
			 this.currentPageOfHelps = 1;
			 this.tipHelpService.getUserTickets(1);
		 }
	 });
	 this.tipHelpService.getErrorRelatedObservable().subscribe((value) =>{
		 this.updatesToLoadingModal.next("GotInformationNowClose");
		 this.connectionToErrorModal.next({"show":"retrieveError"});
		 
	 });
  }

  ngOnInit() {
  }
  ngAfterContentChecked(){
    // console.log("content checked");
	
  }/*
  ngAfterContentInit(){
    //console.log("contnent initialized");
  } */
  ngAfterViewInit()
  {
	
  }
  
  continueRotation()
  {
	console.log("continue Rotation called");  
	if(this.rotateAnimation != "fini")
	{
		this.rotateAnimation = this.rotateAnimation == "default" ? "rotated" : "default";
	}	
  }
  submitTicket()
  {
	  this.errors = "";
	  console.log("df");
	  if(this.supportCategory == "")
	  {
		  this.errors += "Select a category. " + "<br/>";
	  }
	  console.log("d");
	  console.log(this.textAreaInfo.nativeElement.value.toString());
	  if(this.textAreaInfo.nativeElement.value.toString().trim() == "")
	  {
		  this.errors += "Please enter information for Support. " + "<br/>";
	  }
	  if(this.textAreaInfo.nativeElement.value.toString().length > 5000)
	  {
		  this.errors += "Please make sure length is less than 5000 characters. " + "<br/>";
	  }
	  if(this.errors == "")
	  {
		  var helpQuestion = this.textAreaInfo.nativeElement.value.toString().trim();
		  var helpQuery:HelpQuery = new HelpQuery(helpQuestion,this.supportCategory);
		  this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
		  this.tipHelpService.submitHelpRequest(helpQuery);
	  }
	  setTimeout(() =>{
		  this.pagesOfHelps = 1;
		  this.tipHelpService.getUserTickets(1);
	  },4000);
	 
  }
  navigateToHelpConversation(indexOfHelp:number)
  {
	  console.log("navigated");
	  var helpTicketUUID:string = this.array_of_help_tickets[indexOfHelp].helpTicketUUID;
	  this.tipHelpService.setTipHelpTicket(this.array_of_help_tickets[indexOfHelp]);
	  this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
	  this.router.navigate(["/","supportconversation"]);
  }
  changePage(page:number)
  {
	  this.currentPageOfHelps = page;
	  this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
	   setTimeout(() =>{
		  this.tipHelpService.getUserTickets(page);
	  },200);
      console.log("page changed");
  }
  refreshPage()
  {
	  this.array_of_help_tickets = [];
	  this.rotateAnimation = "default";
	   setTimeout(() =>{
		  this.tipHelpService.getUserTickets(this.currentPageOfHelps);
		  this.tipHelpService.getCountOfUserTickets();
	  },200);
  }
  setArticleCount(count:number)
  {
	  this.numberOfArticles = count;
  }
  setHelpCategory(value:string)
  {
	  this.supportCategory = value;
  }
  changeButtonColor()
  {
	  this.buttonColorArray.some((value) => {  return (value == "red"); }) == true ? this.buttonColorArray[1] = "green" :  this.buttonColorArray[1] = "red";
  }
  showVideo()
  {
	  var video_url = this.videoService.getUrlOfVideo("Tiplly Support");
	  console.log(video_url);
	  this.connectionToVideoModal.next({"show":`${video_url}`});
  }
}
