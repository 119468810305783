import { Component, OnInit, AfterViewInit,ViewChild, ElementRef } from '@angular/core';
import {Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {Location} from "@angular/common";
import {Inject} from "@angular/core";
import {TipHelpService} from "./../../services/tip-help-service.service";
import {UserInformationService} from "./../../services/user-information.service";
import {HelpMessage} from "./../../Models/help-message.model";
import {HelpTicket} from "./../../Models/help-ticket.model"; 
import {Subject} from "rxjs";
import {timer} from "rxjs";
@Component({
  selector: 'app-support-conversation',
  templateUrl: './support-conversation.component.html',
  styleUrls: ['./support-conversation.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class SupportConversationComponent implements OnInit, AfterViewInit {
  
  current_array_of_messages:Array<HelpMessage>; 
  submitButtonArray:string[] = ["ui","inverted","green","button"];
  closeButtonArray:string[] =  ["ui","inverted","red","button"];
  page_of_conversation:number = 1;
  count_of_help_tickets_messages:number;
  array_of_help_ticket_messages_pagination:Array<number> = new Array<number>();  
  currentHelpTicket:HelpTicket;
  errors_of_submit_text_area:string;
  connectionToLoadingModalSubject:Subject<{[key:string]:string}>;
  connectionToErrorsModalSubject:Subject<{[key:string]:string}>;
  connectionToSuccessSubject:Subject<{[key:string]:string}>;
  updatesConnectionToLoadingModalSubject:Subject<string>;
  connectionToYesNoModal:Subject<{[key:string]:string}>;
  connectionToStarRating:Subject<{[key:string]:string}>;
  @ViewChild("responseTextArea",{static:false}) responseTextArea:ElementRef;
  constructor(@Inject(DOCUMENT)private documente:Document, private tipHelpService:TipHelpService, private location_loc:Location,private router:Router, private userInformationService:UserInformationService) {   
    this.current_array_of_messages = new Array<HelpMessage>();
	this.connectionToLoadingModalSubject = new Subject<{[key:string]:string}>();
	this.connectionToErrorsModalSubject = new Subject<{[key:string]:string}>();
	this.updatesConnectionToLoadingModalSubject = new Subject<string>();
	this.connectionToYesNoModal = new Subject<{[key:string]:string}>();
	this.connectionToSuccessSubject = new Subject<{[key:string]:string}>();
	this.connectionToStarRating = new Subject<{[key:string]:string}>();
	
  }

  ngOnInit() {
	  this.documente.body.scrollTop = 0;
	  window.scrollTo(0, 0);
	  this.tipHelpService.getCountOfMessagesInHelpTicketObservable().subscribe((value) => {
		  this.updatesConnectionToLoadingModalSubject.next("GotInformationNowClose");
		  console.log(value);
		  if(value["messages_count"] >= 0)
		  {
			  console.log("count of messages are " + value["messages_count"]);
	          this.count_of_help_tickets_messages = value["messages_count"];
			  var pagination_count = (this.count_of_help_tickets_messages / 10);
			  console.log(pagination_count);
              this.array_of_help_ticket_messages_pagination	= [];
              for(var i = 0; i < pagination_count; i++)
			  {
				  this.array_of_help_ticket_messages_pagination.push(1);
			  }
             
            			  
		  }
		  
	  });
	  this.tipHelpService.getAmountOfMessagesForHelpTicket();
	  this.tipHelpService.getArrayOfHelpMessagesObservable().subscribe((value) => {
		   this.updatesConnectionToLoadingModalSubject.next("GotInformationNowClose");
		  console.log(value);
		  var array_of_messages:Array<HelpMessage> = (value as Array<HelpMessage>);
		  this.current_array_of_messages = [];
		  for(var i = 0; i < array_of_messages.length; i++)
		  {
			  this.current_array_of_messages.push(array_of_messages[i]);
		  }
		  window.scrollTo(0,0);
		  
	  });
	  this.tipHelpService.getHelpMiscellaneousRelatedObservable().subscribe((value) => {
		  if(value["submitHelpMessage"])
		  {
			  if(this.current_array_of_messages.length >= 10)
			  {
				   this.tipHelpService.getAmountOfMessagesForHelpTicket();
				   this.updatesConnectionToLoadingModalSubject.next("GotInformationNowClose");
			       this.page_of_conversation = (this.page_of_conversation + 1);
				   this.getPageOfResponses(this.page_of_conversation );
				   
				   
			  }
			  else
			  {
			    this.updatesConnectionToLoadingModalSubject.next("GotInformationNowClose");
			    this.getPageOfResponses(this.page_of_conversation);
			  }
			  this.connectionToSuccessSubject.next({"show":"open"});
			  
		  }
		  else if (value["closeHelpSession"])
		  {
			  this.updatesConnectionToLoadingModalSubject.next("GotInformationNowClose");
			  if(value["closeHelpSession"] == "successful")
			  {
				  this.connectionToSuccessSubject.next({"show":"open"});
				  timer(2500).subscribe(()=>{
					this.router.navigate(["/","home","support","supportmain"]);  
				  });
			  }
			 
		  }
	  });
	 
	  this.tipHelpService.getMessagesForHelpTicket(this.page_of_conversation);
	  this.currentHelpTicket = this.tipHelpService.getCurrentHelpTicket();
	  this.tipHelpService.getErrorRelatedObservable().subscribe((value) =>{
		 this.updatesConnectionToLoadingModalSubject.next("GotInformationNowClose");
	     this.connectionToErrorsModalSubject.next({"show":"retrieveError"});
		
		 
	 });
	 this.connectionToYesNoModal.subscribe((value) =>{
		if(value["closeHelpSession"] == "yes")
		{
			this.connectionToStarRating.subscribe((value) =>{
				if(value["results"]){
					var rating = ((value["results"] as any) as number);
					this.connectionToLoadingModalSubject.next({"Loading":"PlainLoading"});
					this.tipHelpService.closeHelpSession(this.currentHelpTicket.helpTicketUUID,rating);
				}
			});
			this.connectionToStarRating.next({"show":"open"});
			
			
		}			
	 });
	 
  }

  goBack()
  {
	  this.location_loc.back();
  }
  refresh()
  {
	  this.connectionToLoadingModalSubject.next({"Loading":"PlainLoading"});
	  this.tipHelpService.getMessagesForHelpTicket(this.page_of_conversation);
	  this.tipHelpService.getAmountOfMessagesForHelpTicket();
  }
  getPageOfResponses(page:number)
  {
	  this.page_of_conversation = page;
	  this.tipHelpService.getMessagesForHelpTicket(this.page_of_conversation);
	  this.connectionToLoadingModalSubject.next({"Loading":"PlainLoading"});
  }
  isUserResponse(username:string)
  {
	  return (this.userInformationService.getTipUsername() == username ? true:false);
  }
  
  submitResponse(response:string)
  {
	  this.errors_of_submit_text_area = "";
	  if(response.length < 10)
	  {
		  console.log("error found");
		  this.errors_of_submit_text_area += "<p>" + "Response must be of length 10 characters or greater." + "</p>";
	  }
	  if(this.errors_of_submit_text_area == "")
	  {
		  this.connectionToLoadingModalSubject.next({"Loading":"PlainLoading"});
		  this.tipHelpService.replyToHelpSession(response);
	  }
	  console.log("reponse is : " +  response);
  }
  closeHelpSession()
  {
	  this.connectionToYesNoModal.next({"YesNo":"closeHelpSession"});
  }
  changeButtonColor(whichButton:string)
  {
	  switch(whichButton)
	  {
		  case "submit":
					this.submitButtonArray[2] == "grey" ? this.submitButtonArray[2] = "green" : this.submitButtonArray[2] = "grey";
					break;
		   case "close":
		            this.closeButtonArray[2] == "red" ? this.closeButtonArray[2] = "grey" : this.closeButtonArray[2] = "red";
		         break;
      }
  }
  
  ngAfterViewInit(){
	   this.documente.body.scrollTop = 0;
	   this.connectionToLoadingModalSubject.next({"Loading":"PlainLoading"});
  }

}
