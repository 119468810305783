import { Component, OnInit,AfterViewInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {Subject} from "rxjs";
import {VideoService} from "./../../services/video-service.service";
import {UserInformationService} from "./../../services/user-information.service";
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css','../../../../node_modules/purecss/build/pure-min.css']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  public selected: Array<String>; 
  public currentItem:String;
  public pastItem:String = "";  
  public connectionToVideoModal:Subject<{[key:string]:string}>;
  constructor(private router:Router, private route:ActivatedRoute, private videoService:VideoService, private userInformationService:UserInformationService) {
	this.selected = new Array<String>(); 
    this.currentItem = "profile";	
    this.selected.push("pure-menu-item");
    this.selected.push("pure-menu-selected");
	this.connectionToVideoModal = new Subject<{[key:string]:string}>();
  }
  change()
  {
	  this.currentItem = this.currentItem == "profile" ? "Tiplly" : "profile";
	 /* if(this.currentItem == "profile" && this.pastItem == "Tiplly")
	  {
		  this.router.navigate(["./","information"],{relativeTo:this.route});
		  this.pastItem = "profile";
	  }
	  else { */
		  this.router.navigate(["./","tipllyInformation"],{relativeTo:this.route});
		  this.pastItem = "Tiplly";
	  //}
  }
  openVideo(which:string)
  {
	  var video_url;
 	  video_url = which == "Introduction" ? this.videoService.getUrlOfVideo("Tiplly Introduction") : this.videoService.getUrlOfVideo("Tiplly Profile");
	  console.log(video_url);
	  this.connectionToVideoModal.next({"show":`${video_url}`});
  }
  ngAfterViewInit()
  {
	  console.log("after view init called");
	   if(this.userInformationService.shouldShowWelcomeModal())
	  {
		  console.log("opening video");
		  this.openVideo("Introduction");
	  }
  }
  ngOnInit() {
	  this.currentItem = "Tiplly";
	  this.pastItem = "Tiplly";
	  this.router.navigate(["./","tipllyinformation"],{relativeTo:this.route});
	  console.log("here in ngonInit profile.comoponent");
	 
  }

}
