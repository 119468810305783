import { Injectable } from '@angular/core';
import {Http} from "@angular/http";
import {Miscellaneous} from "./../exportedValues/miscellaneous";
@Injectable({
  providedIn: 'root'
})
export class CurrencyConversionService {

   private currency_object:any;
   private misc:Miscellaneous;
   private ltcUSDPrice:any;

  constructor() 
  {
    this.misc = new Miscellaneous();
	
  }
  getCurrencyConversionObject(){
	  return this.currency_object;
  }
  getLTCUSDPrice()
  {
	  return this.ltcUSDPrice;
  }
  setLTCUSDPrcie(price:number){
	  this.ltcUSDPrice = price;
  }
  setCurrencyObject(currencyObj:any){
	  this.currency_object = currencyObj;
    
  }
  
}
