import { Injectable,Inject } from '@angular/core';
import {Http} from "@angular/http";
import {Subject} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class TipRelatedSecurityService {
  private securityObservable:Subject<{[key:string]:boolean}>;
  private baseUrl_:string;
constructor(private http:Http, @Inject("base_href") base_href:string) 
  { 
    this.securityObservable = new Subject<{[key:string]:boolean}>();
	this.baseUrl_ = `${base_href}/security`;
  }
  getSecurityObservable()
  {
	  return this.securityObservable;
  }
  checkIfTipLinkIsValid(tipLink:string)
  {
	var url = `${this.baseUrl_}/checkIfTipLinkIsValid?tipLink=${tipLink}`; 
    this.http.get(url).subscribe((success) =>{
		this.securityObservable.next({"tipLink":true});
	},(err) =>{
		if(err.status == 417)
		{
		 this.securityObservable.next({"tipLink":false});
		}
		else
		{
		 this.securityObservable.next({"tipLink":null});	
		}
	});	
	return this.securityObservable;
  }
}
