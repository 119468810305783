import { Component, OnInit,Input } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {NgxSmartModalService} from "ngx-smart-modal";
import {Subject} from "rxjs";
@Component({
  selector: 'error-showing',
  templateUrl: './error-showing.component.html',
  styleUrls: ['./error-showing.component.css']
})
export class ErrorShowingComponent implements OnInit {

  @Input() public modalConnector:Subject<{[key:string]:string}>;
  @Input() public errorContinuation:Subject<{[key:string]:string}>;
  public errorToShow:Array<string>;
  public errorReveal:string;
  public moreInfo:string = "";
  constructor(private ngxSmartModalService:NgxSmartModalService, private route:ActivatedRoute, private router:Router) 
  { 
     this.modalConnector = new Subject<{[key:string]:string}>();
	 this.errorContinuation = new Subject<{[key:string]:string}>();
	 this.errorToShow = new Array<string>();
  }
  ngOnChanges(changes)
  {
	  if(changes["modalConnector"])
	  {
		  this.modalConnector.subscribe((value) => {
			    console.log(value);
				
				 if(value["show"])
				 {
				     this.errorToShow.push(value["show"]);
					 this.moreInfo = "";
				 }
				 if(value["reset"])
				 {
					 this.errorToShow = [];
					 
				 }
				 if(value["information"])
				 {
					 this.moreInfo = value["information"];
				 }
				 console.log(this.ngxSmartModalService.getOpenedModals().length);
				// if(this.ngxSmartModalService.getOpenedModals().length)
				// {
				  this.ngxSmartModalService.get("errorModal").open();
                 //}	
                console.log(this.errorToShow);				 
		  });
	  }
	  
	if(changes["errorContinuation"])
	{
	  this.errorContinuation.subscribe((value) =>{
		  if(value["update"]){
		   this.errorToShow.push(value["update"]);
		  }
		  else if (value["clear"]){
		    this.errorToShow = [];
		  }
		  else if (value["info"])
		  {
			  this.moreInfo = value["info"];
		  }
		  
	  });
	} 
  }
  ngOnInit() {
	  this.route.queryParams.subscribe((value) => {
		  if(value["reveal"])
		  {
			  this.errorReveal = value["reveal"];
		  } 
	  });
	 
  }

}
