import { Component, OnInit,Input,ViewChild, ElementRef } from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {Subject} from "rxjs";
import{NgxSmartModalService} from "ngx-smart-modal";
@Component({
  selector: 'video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css']
})
export class VideoModalComponent implements OnInit {
 
  @Input() public videoSubjectConnection:Subject<{[key:string]:string}>;
  @ViewChild("sourceTag",{static:false}) sourceVideo:ElementRef;
  @ViewChild("media",{static:false}) media:ElementRef;
  public videoUrl:string = "";
  public modalClass:Array<string> = ["modalClass"];
  constructor(private ngxSmartModalService:NgxSmartModalService)
  { 
    this.videoSubjectConnection = new Subject<{[key:string]:string}>();
  }
  ngOnChanges(changes)
  {
	  if(changes["videoSubjectConnection"])
	  {
		  this.videoSubjectConnection.subscribe((value) =>{
			 if(value["show"])
			 {
				 this.videoUrl = value["show"];
				 console.log(this.sourceVideo);
				 console.log(this.sourceVideo.nativeElement);
				 this.sourceVideo.nativeElement.src = this.videoUrl;
				 
				 console.log(value); 
			 }
			  
			 this.ngxSmartModalService.get("videoModal").open();
			 this.media.nativeElement.load();
			 if(!value["show"].includes("Introduction")){
			   this.media.nativeElement.play();
			 }
		  });
	  }
  }
  ngOnInit() {
	  
  }

}
