import { Component, OnInit, Input } from '@angular/core';
import {Subject}from "rxjs";
import {NgxSmartModalService} from "ngx-smart-modal";
import {timer} from "rxjs";
@Component({
  selector: 'yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class YesNoComponent implements OnInit {
   
  @Input() subjectObservable:Subject<{[key:string]:string}>; 
  private deleteWebsiteConfirmation:string ="Are you sure you want to remove this website?";
  private choice:string = "";
  public confirmation:string;
  public disperse:string = "";
  constructor(private ngxsmartModalService:NgxSmartModalService) {
  }
  ngOnChanges(changes)
  {
	  if(changes["subjectObservable"])
	  {
		  console.log("new value");
		  this.subjectObservable.subscribe((value:{[key:string]:string}) => {
			  this.disperse = "No"; 
			  console.log(value);
			  if(value["YesNo"])
			  {
				this.disperse = "No";   
				switch(value["YesNo"])
				{
					case "deleteWebsite":  
						this.confirmation = this.deleteWebsiteConfirmation;
						this.ngxsmartModalService.getModal("YesorNo").open();
						this.choice = "removeWebsite";
						this.disperse = "YES";
						break;
					case "confirmRelpy":
                          this.choice = "Are you sure you want to submit the reply?";
						  this.ngxsmartModalService.getModal("YesorNo").open();
						  this.disperse = "YES";
						 break;	
				   case "confirmReplaceCountry":
				          this.choice = "confirmReplaceCountry";
                          this.confirmation = "Stripe has a different country on file from the one you selected. Continuing will void your stripe verification if verified, it will also void your bank account connection and make you start over (deletion will only work if the balance of your account is 0. (so if you have tips from the past three days it wont work.)) Do you still want to move forward?" ;
						  this.ngxsmartModalService.getModal("YesorNo").open();
						  this.disperse = "YES";
						 break;
                   case "areYouSure":
				          this.choice = "areYouSure";
                          this.confirmation = "Are you sure about this, there are no reversals!" ;
						  this.ngxsmartModalService.getModal("YesorNo2").open();
						  timer(3000).subscribe(() =>{
							 this.disperse = "YES"; 
						  });
						 break;							 
                   case "closeHelpSession":  
						this.confirmation = "Are you sure you want to close this help session?";
						this.ngxsmartModalService.getModal("YesorNo").open();
						this.choice = "closeHelpSession";
						this.disperse = "YES";
						break;						 
				}
			  }
			  
		  });
	  }
	  
  }
  buttonHover(whichButton:string)
  {
	  
  }
  ngOnInit() {
  }
  yesButtonClicked()
  {
	  if(this.disperse == "YES"){
      this.ngxsmartModalService.getModal("YesorNo").close();		  
	  console.log("yes button clicked");
	  if(this.choice == "removeWebsite")
	  {
		  this.subjectObservable.next({"removeWebsite":"yes"});
	  }		  
	  else if (this.choice == "confirmReply")
	  {
		  this.subjectObservable.next({"confirmRelpy":"yes"});
		  
	  }
	  else if (this.choice == "closeHelpSession")
	  {
		  this.disperse = "NO";
		  this.subjectObservable.next({"closeHelpSession":"yes"});
		  this.disperse = "NO";
	  }
	  else if(this.choice == "confirmReplaceCountry")
	  {
		  this.disperse = "NO";
		  this.subjectObservable.next({"confirmReplaceCountry":"yes"});
		  this.disperse = "NO";
	  }
	  else if(this.choice == "areYouSure")
	  {
		  this.disperse = "NO";
		  this.subjectObservable.next({"areYouSure":"yes"});
		  this.disperse = "NO";
	  }
	  
	 
	  }
  }
  noButtonClicked()
  {
	  console.log("no button clicked");
	  this.ngxsmartModalService.getModal("YesorNo").close();
	  if(this.choice == "removeWebsite")
	  {
		  this.subjectObservable.next({"removeWebsite":"no"});
	  }		  
	  else if (this.choice == "cnfirmReply")
	  {
		  this.subjectObservable.next({"confirmRelpy":"no"});
	  }
	  else if (this.choice == "closeHelpSession")
	  {
		  this.subjectObservable.next({"closeHelpSession":"no"});
	  }
	  else if(this.choice == "confirmReplaceCountry")
	  {
		  this.subjectObservable.next({"confirmReplaceCountry":"no"});
	  }
	   else if(this.choice == "areYouSure")
	  {
		  this.subjectObservable.next({"areYouSure":"no"});
	  }
	  
  }
  

}
