export class FormSuggestionHelpers {
public tipllyProfileHelpersArray:Array<String> = ["This is your tiplly username, this is your gmail address","This is your tiplly code. ","Lite coin wallet address to forward your tips to: ",
                                                  "This is your tiplly unique code that is meant to be placed within the description section of video or whereever the tutorial video above has stated.",
                                                  "Set your preffered currency it will be used to show your tips in that currency. Litecoin prices fluctuate"];
	public addressPlaceHolderArray:Array<String> = ["Country","State or Province","City","Postal Code","Street Name","House Number"];
	
	public addressjsonObject:Object = {
		"StateorProvince":"Enter the state or province you live in.",
		"City":"Enter the city you live in.",
		"PostalCode":"Enter the postal code or zip code of your address",
		"HouseNumber":"Enter the number of your house excluding the street name",
		"StreetName":"Name of the street you live on excluding the house number"
		
	};
	
	public individualPlaceHolderArray:Array<String> = ["Date of Birth","Identity Number","Gender","Last Name","First Name","Phone Number"];
	
	public individualData:Object = {
		"dateofBirth":"This will be used to verify who you, and report your earnings to your local government as required",
	    "identityNumber":"Enter your Social Security Number, National identification number,  Social Insurance numbers or  ID card, this will be used to report all earninigs to your Country's government",
	    "gender":"Your gender will be used to verify who you say you are.",
		"lastName":"Enter your last name, this will be used to verify who you say you are.",
		"firstName":"Enter your first name, this will be used to verify who you say you are.",
		"phonenumber":"Enter your phone number, this will be used to contact you"
		
		
	};
	
	
	
	public gender:Array<String> = [
		"Male","Female", "unsepecified" 
	];
	
	public bankPlaceHolder:Array<String> = ["Full Name","Branch Code", "Account Number"];
	public bankJsonObject:Object = {
		"FullName":"Enter your full name.",
		"Currency":"Select the currency that your bank primarily holds.",
		"Country":"Select the country your bank resides in.",
		"AccountType":"Select the Account in which you would like to receive any tips you receive. Tips are deposited into your account every week.",
		"BranchCode" : "Enter your banks branch code click for example",
		"AccountNumber":"Enter the Account number of your bank account, click for example",
		"BankName":"Enter the full name of your bank"
		
	};
}
