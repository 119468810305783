import {trigger,state,transition,style,animate} from "@angular/animations";

export function pop()
{
	return trigger("pop",[
	   state('popout',style({position:'relative',width:'*', height:'*',transform: 'scale(1.1)'})),
	   state('popin',style({position:'relative'})),
	   transition("popin => popout",[animate('200ms ease-in-out',style({transform: 'scale(1.1)'}))]),
	   transition("popout => popin",[animate('200ms ease-in-out'),style({transform:'scale(1.0)'})])
	   
	]);
}

export function popper()
{
	return pop();
}