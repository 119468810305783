import { Component, OnInit,Input } from '@angular/core';
import {HelpMessage} from "./../../Models/help-message.model";
import {HelpTicket} from "./../../Models/help-ticket.model";
@Component({
  selector: 'help-message-display',
  templateUrl: './help-message-display.component.html',
  styleUrls: ['./help-message-display.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class HelpMessageDisplayComponent implements OnInit {

  @Input() helpMessage:HelpMessage;
  @Input() helpTicket:HelpTicket;
  @Input() firstQuestion:boolean;
  @Input() isUsersResponse:boolean;
  borderColor:string;
  constructor() {
	  }

  ngOnInit() {
	this.borderColor = this.firstQuestion == true ? "red" : "#dedede";
  }

}
