import { Component, OnInit,Input } from '@angular/core';
import {timer} from "rxjs";
import {NgxSmartModalService} from "ngx-smart-modal";
import {Subject} from "rxjs";
@Component({
  selector: 'number-counter',
  templateUrl: './number-counter.component.html',
  styleUrls: ['./number-counter.component.css']
})
export class NumberCounterComponent implements OnInit {

  countTimer = timer(1,0.0000001);
  num = 0;
  @Input()numbes:number;
  @Input()currency:string;
  @Input()shortCurrency:string;
  @Input()liteCoinPriceInSpecifiedCurrency:number; 
  @Input()modalConnector:Subject<{[key:string]:string}>;
  constructor(private ngxSmartModalService:NgxSmartModalService)
  {
	 // this.modalConnector = new Subject<{[key:string]:string}>();
  }
  ngOnChanges(changes)
  {
	  if(changes["modalConnector"])
	  {
		  this.modalConnector.subscribe((value) =>{
			 if(value["show"] == "open")
			 {
				 console.log("we have received number counter open request");
				 this.num = 0.00;
				 this.ngxSmartModalService.get("numberCounterModal").open();
			 }				 
		  });
	  }
  }
  ngOnInit()
  {
	  
    this.countTimer.subscribe(() => {
     if(this.num <= this.numbes)
     {
      if( ( this.num) > (this.numbes * 0.999999999999999999 ))
      {
	   if(this.num < this.numbes)
	   {		   
        this.num += .01;
	   }
      }
      else {
        this.num +=  (this.numbes * 0.01 );
       
      }
     }
    });
 }
}
