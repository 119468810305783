import { Component, OnInit, ViewChild,ElementRef,Inject } from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {ActivatedRoute,Router} from "@angular/router";
import {TipHelpers} from "./../../exportedValues/tip-helpers";
import {NgxSmartModalService} from "ngx-smart-modal";
import {TipExecutionService} from "./../../services/tip-execution-service.service";
import {FormGroup} from "@angular/forms";
import {FormControl} from "@angular/forms";
import {TipErrors} from "./../../exportedValues/tip-errors";
import {Subject} from "rxjs";
import {ChargeTip} from "./../../Models/charge-tip.model";
import {loadStripe} from '@stripe/stripe-js';
import {CurrencyConversionService} from "./../../services/currency-conversion-service.service";
@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class TipComponent implements OnInit {

  tipLink:string;
  tipUUID:string;
  tipHelper:TipHelpers;
  showHelper :string = "";
  ExpirationMonth:string = "";
  ExpirationYear:string = "";
  tipCurrency:string = "";
  tipCurrencyImage:string = "";
  tipErrorsArray:string[];
  cardErrorsArray:string[];
  tipErrors:TipErrors;
  LoadingObservable:Subject<{[key:string]:string}>;
  UpdatesToTextObservable:Subject<string>;
  ExamplesConnectionObservable:Subject<{[key:string]:string}>;
  ConnectionToErrorObservable:Subject<{[key:string]:string}>;
  chargeTip:ChargeTip;
  stripe:any;
  card:any;
  //@ViewChild("cardErrors") cardErrorsDiv:ElementRef;
  constructor(private router:Router, private route:ActivatedRoute, private ngxSmartModalService:NgxSmartModalService,private currencyConversionService:CurrencyConversionService, private tipService:TipExecutionService,@Inject(DOCUMENT) private documente:Document) {
	  this.tipHelper = new TipHelpers();
	  this.tipErrors = new TipErrors();
	  this.LoadingObservable = new Subject<{[key:string]:string}>();
	  this.ExamplesConnectionObservable = new Subject<{[key:string]:string}>()
	  this.UpdatesToTextObservable = new Subject<string>();
	  this.ConnectionToErrorObservable = new Subject<{[key:string]:string}>();
	  this.tipErrorsArray = new Array();
	  for(var i = 1; i <= 7; i++)
	  {
		  this.tipErrorsArray.push("");
	  }
	  console.log("got to the tip component");
          this.route.params.subscribe((params)=>{
			  console.log("params are");
			  console.log(params['tipLink']);
			  console.log(params);
			  this.tipLink = params['tipLink'];
	});
	this.initialize_tipService();
	
	//this.currencyConversionService.setUp();
	
  }
  ngOnInit()
  {
	  this.loadStripeJs();
	  console.log("initializing currency");
	  //this.currencyConversionService.setUp();
  }
  async loadStripeJs()
  {
	  this.stripe = await loadStripe("pk_live_51H9bOILM1YlTZauwoMyrXihMRVwL5oYzaKzlhgMnkrnVdN5sIAxLTYHlRizI9t2geYuBJvp4r56L6f7tjiSSUe8500LKLJFFqg");
	  this.loadElements();
	  
  }
  initialize_tipService()
  {
	  console.log("initialized called");
	  this.tipService.gettipExecutionServiceSubjectObservable().next(null);
	  this.tipService.gettipExecutionServiceSubjectObservable().next(null);
  }

  submitTip(tip_form:any,card_form:any)
  {
	  console.log(tip_form);
	  console.log(card_form);
	  console.log("Tip amount is: ");
	  console.log((tip_form as FormGroup).controls["TipAmount"].value);
	  this.checkErrorstip_form(tip_form as FormGroup); 
	  //this.checkErrorscard_form(card_form as FormGroup);
	  if(this.checkIfAnyErrors() == false)
	  {
		(this.documente.getElementById("tipButton") as any).disabled  = true; //("disabled", "disabled");;   
		this.LoadingObservable.next({"Loading":"Attempting to Tip"});  
	    this.tipService.gettipExecutionServiceSubjectObservable().subscribe((value:{[key:string]:string}) =>{
			if(value["tip_uuid"]){
				if(this.tipUUID != value["tip_uuid"]){
				this.tipUUID = value["tip_uuid"];
				console.log(`the tip id is ${this.tipUUID}`);
				var tip_website = localStorage.getItem("websiteStorage") === null ? null :  localStorage.getItem("websiteStorage");
				var tipDate = localStorage.getItem("tiptimeStamp") === null ? null: localStorage.getItem("tiptimeStamp");
				this.chargeTip = new ChargeTip(this.tipUUID,(tip_form as FormGroup).controls["TipAmount"].value,this.tipCurrency.split("-")[0].trim(),(tip_form as FormGroup).controls["TipNote"].value,tip_website);
			    console.log(this.chargeTip);
				this.tipService.getTipToken(this.chargeTip,this.tipLink);
				}
			}
			if(value["tip_success"])
			{
				this.UpdatesToTextObservable.next("GotInformationNowClose");
				this.tipService.sendRecentTip(this.chargeTip);
				this.router.navigate(["tip/receipt"]);  
				
			}
			if(value["tip_token"])
			{
			 var updatesToTextObservable = this.UpdatesToTextObservable;	//remove later
			 var cardToCharge = this.card;	
			 var clientSecret = ((value["tip_token"] as unknown) as string);
			 console.log(clientSecret);
			 console.log(this.documente);
			 var documentRef = this.documente;
			 var tipServiceAny:any = this.tipService;
			 var chargeTipCnne:any = this.chargeTip;
			 var tipLinkccne:any = this.tipLink;
			 var connectionUpdates:any = this.UpdatesToTextObservable;
			 console.log(connectionUpdates);
			 this.stripe.confirmCardPayment(clientSecret, {
				payment_method: {
				  card: cardToCharge
				}
			  }).then(function(result) {
				if (result.error) {
			      connectionUpdates.next("GotInformationNowClose");
				  // Show error to your customer (e.g., insufficient funds)
				  console.log(result.error);
				  (documentRef.getElementById("tipButton") as any).disabled = false;
				  console.log(result.error.message);
				  console.log(result.error.type);
				  console.log(documentRef);
				  var err_message = result.error.message;
				  
				  if(result.error.type == "invalid_request_error")
				  {
					  err_message = "Content creator needs to finish tiplly-stripe registration or stripe authentication has not finished, needs more information or has failed";
				  }
				  documentRef.getElementById("card-errors").innerHTML = err_message;
				} else {
				  // The payment has been processed!
				  if (result.paymentIntent.status === 'succeeded') {
					  console.log("payment succeeded");
					  console.log(result.paymentIntent);
					  tipServiceAny.chargeTip(chargeTipCnne,tipLinkccne,result.paymentIntent.id);
					// Show a success message to your customer
					// There's a risk of the customer closing the window before callback
					// execution. Set up a webhook or plugin to listen for the
					// payment_intent.succeeded event that handles any business critical
					// post-payment actions.
				  }
				  (documentRef.getElementById("tipButton") as any).disabled = false;
				}
			  });
			}
			if(value["tip_error"])
			{ 
		            this.UpdatesToTextObservable.next("GotInformationNowClose");
					this.ConnectionToErrorObservable.next({"show":"retrieveError"});
			}
		});
		
		this.getTipUUID();
		this.UpdatesToTextObservable.next("Initializing secure line");
	//	var chargeTip:ChargeTip = new ChargeTip(this.tipUUID,(tip_form as FormGroup).controls["TipAmount"].value,this.tipCurrency,(tip_form as FormGroup).controls["TipNote"].value);
       
		//activities with ayden_js	//
        this.UpdatesToTextObservable.next("Initializing Card Charge");
		//end
		
         		 
		console.log("passed all checks");
		 
	  }
	  
  }
  getTipUUID()
  {
	  this.tipService.getTipUUID();
  }
  checkIfAnyErrors():boolean{
	  for(var i = 0; i < this.tipErrorsArray.length; i++ )
	  {
		  if(this.tipErrorsArray[i] != "")
		  {
			  if(i != 2)
			  {
			   return true;
			  }
		  }
	  }
	  return false;
  }
  emptyTipErrors()
  {
	  for(var i = 0; i < this.tipErrorsArray.length; i++)
	  {
		  this.tipErrorsArray[i] = "";
	  }
  }
  checkErrorstip_form(tipFormGroup:FormGroup)
  {
	this.emptyTipErrors(); 
    var tipAmountUSDArray = null;//this.currencyConversionService.getCurrencyConversion("USD");
    console.log(tipAmountUSDArray);	
	var tipAmountUSDJson;
	for(var x = 0; x < tipAmountUSDArray.length; x++)
	{
		if(tipAmountUSDArray[x].currency.trim() == this.tipCurrency.split("-")[1].trim())
		{
			tipAmountUSDJson = tipAmountUSDArray[x];
		}
	}
    var tipAmountConverted =   (tipFormGroup as FormGroup).controls["TipAmount"].value / tipAmountUSDJson["rate"];
	console.log(tipAmountConverted);
	if(tipAmountConverted < 0.50)
	{
		this.tipErrorsArray[1] = this.tipErrors.tipFormErrors[0];
	}
    if(this.tipCurrency == "")
	{
		this.tipErrorsArray[0] = this.tipErrors.tipFormErrors[1] ;
	}
    
	
  }
  checkErrorscard_form(cardFormGroup:FormGroup)
  {
	if(cardFormGroup.controls["cardNumber"].value == "" || cardFormGroup.controls["cardNumber"].value == null)
	{
		this.tipErrorsArray[3] = this.tipErrors.cardFormErrors[0];
	}		
	if(cardFormGroup.controls["cvv"].value == "" )
	{
	  this.tipErrorsArray[6] = this.tipErrors.cardFormErrors[1];
	}	
	if(this.ExpirationMonth == "")
	{
		this.tipErrorsArray[4] = this.tipErrors.cardFormErrors[2];
	}
	if(this.ExpirationYear == "")
	{
		this.tipErrorsArray[5] = this.tipErrors.cardFormErrors[3];
	}
  }
  setHelperandShowModal(which:string)
  {
	  this.showHelper = which;
	  
	  this.ExamplesConnectionObservable.next({"example":this.showHelper});
	  
  }
  setExpirationMonth(month:string)
  {
	  this.ExpirationMonth = month;
  }
  setExpirationYear(year:string)
  {
	  this.ExpirationYear = year;
	  console.log(year);
  }
  setTipCurrency(currency:string,currencyImage:string)
  {
	  this.tipCurrency = currency;
	  this.tipCurrencyImage = currencyImage;
  }
   
  loadElements()
  {
	   var elements = this.stripe.elements();
	  var style = {
				  base: {
					color: '#32325d',
					fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
					fontSmoothing: 'antialiased',
					maxWidth:'100px',
					width:'100px',
					fontSize: '16px',
					'::placeholder': {
					  color: '#aab7c4'
					}
					
				  },
				  invalid: {
					color: '#fa755a',
					iconColor: '#fa755a'
				  }
	  };
	  this.card = elements.create('card', {style: style});
	  this.card.mount('#card-element');
  }

  ngAfterViewInit()
  {
	 
  }

}
