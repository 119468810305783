import { Injectable,Inject } from '@angular/core';
import {Http} from "@angular/http";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {UserInformationService} from "./user-information.service";
import {SecurityService} from "./security.service";
import {HelpQuery} from "./../Models/help-query.model";
import {HelpTicket} from "./../Models/help-ticket.model";
import {HelpMessage} from "./../Models/help-message.model";
import {ReplyMessage} from "./../Models/reply-message.model";
@Injectable()
export class TipHelpService {

  countOfHelpTicketsObservable:Subject<{[key:string]:number}>;
  arrayOfHelpCategoriesObservable:Subject<Array<string>>;
  helpMiscellaneousRelatedObservable:Subject<{[key:string]:string}>;
  errorRelatedObservable:Subject<{[key:string]:string}>;
  numberOfHelpMessagesObservable:Subject<{[key:string]:number}>;
  arrayOfHelpRequestTickets:Subject<Array<HelpTicket>>;
  arrayOfHelpMessages:Subject<Array<HelpMessage>>;
  currentTipHelpTicket:HelpTicket;
  private baseUrl_:string;
  constructor(private http:HttpClient, private userInformationService:UserInformationService, private securityService:SecurityService, @Inject("base_href")private base_href:string) 
  {
    this.countOfHelpTicketsObservable = new Subject<{[key:string]:number}>();
    this.arrayOfHelpCategoriesObservable = new Subject<Array<string>>();
	this.arrayOfHelpRequestTickets = new Subject<Array<HelpTicket>>();
	this.numberOfHelpMessagesObservable = new Subject<{[key:string]:number}>();
	this.arrayOfHelpMessages = new Subject<Array<HelpMessage>>();
	this.helpMiscellaneousRelatedObservable = new Subject<{[key:string]:string}>();
	this.errorRelatedObservable = new Subject<{[key:string]:string}>();
	this.baseUrl_ = `${base_href}/UserHelp`;
  }
  setTipHelpTicket(inTipHelpTicket:HelpTicket)
  {
	  this.currentTipHelpTicket = inTipHelpTicket;
  }
  getCurrentHelpTicket():HelpTicket
  {
	  return this.currentTipHelpTicket;
  }
  getErrorRelatedObservable()
  {
	  return this.errorRelatedObservable;
  }
  getArrayOfHelpMessagesObservable()
  {
	  return this.arrayOfHelpMessages;
  }
  getCountOfMessagesInHelpTicketObservable()
  {
	  return this.numberOfHelpMessagesObservable;
  }
  getCountOfHelpTicketsObservable()
  {
	  return this.countOfHelpTicketsObservable;
  }
  getHelpCategoriesObservable()
  {
	  return this.arrayOfHelpCategoriesObservable;
  }
  getArrayOfHelpRequestTicketsObservable()
  {
	  return this.arrayOfHelpRequestTickets;
  }
  getHelpMiscellaneousRelatedObservable()
  {
	  return this.helpMiscellaneousRelatedObservable;
  }
  replyToHelpSession(message:string)
  {
	 var username = this.userInformationService.getTipUsername(); 
	 var replyMessage:ReplyMessage = new ReplyMessage(message,this.userInformationService.getTipUsername()); 
	 var replyMessageUrl = `${this.baseUrl_}/replyMessage?helpUUID=${this.currentTipHelpTicket.helpTicketUUID}&username=${username}`;
	 var headerOptions = this.securityService.generateAndReturnPostHeaders();
	 this.http.post(replyMessageUrl,replyMessage,{headers:headerOptions}).subscribe((success) => {
		console.log(success); 
		this.helpMiscellaneousRelatedObservable.next({"submitHelpMessage":"successful"});
	 },(err) => {
		 console.log(err);
		 this.errorRelatedObservable.next({"submitHelpMessage":"failure"});
	 });
  }
  getCountOfUserTickets()
  {
	  var username = this.userInformationService.getTipUsername();
	  var countOfUserTipsUrl = `${this.baseUrl_}/getAmountOfUserHelpSessions?username=${username}`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(countOfUserTipsUrl,{headers:headerOptions}).subscribe((success) =>{
	  console.log(success);
	  var number_of_help_tickets = ((success  as unknown) as number);
	  this.countOfHelpTicketsObservable.next({"numberOfHelpTickets":number_of_help_tickets});
	  });
	  
  }
  getAmountOfMessagesForHelpTicket()
  {
	  var username = this.userInformationService.getTipUsername();
	  var getAmountOfMessagesInHelpTicketUrl = `${this.baseUrl_}/getAmountOfMessagesForHelpSession?help_uuid=${this.currentTipHelpTicket.helpTicketUUID}`;
	  console.log(getAmountOfMessagesInHelpTicketUrl);
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(getAmountOfMessagesInHelpTicketUrl,{headers:headerOptions}).subscribe((success) => {
		 console.log(success);
         var number_of_messages_in_help_ticket:number = ((success as unknown) as number);
		 this.numberOfHelpMessagesObservable.next({"messages_count":number_of_messages_in_help_ticket});
		 
	  });
	  
  }
  getMessagesForHelpTicket(page:number)
  {
	  var username = this.userInformationService.getTipUsername();
	  var getMessagesForHelpTicketUrl = `${this.baseUrl_}/getListOfMessagesForHelpSubmission?username=${username}&helpUUID=${this.currentTipHelpTicket.helpTicketUUID}&page=${page}`;
	  console.log(getMessagesForHelpTicketUrl);
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  var list_of_messages:Array<HelpMessage> = new Array<HelpMessage>();
	  this.http.get(getMessagesForHelpTicketUrl,{headers:headerOptions}).subscribe((success) =>{
		 console.log(success);
		 var json_object_all = ((success as unknown) as any);
		 for(var i = 0; i < json_object_all.length; i++)
		 {
			 var json_object = json_object_all[i];
			 var help_message:HelpMessage = new HelpMessage(json_object["message"],json_object["messageOwner"],new Date(json_object["timestamp"]));
			 list_of_messages.push(help_message);
		 }
		 console.log(list_of_messages);
		 this.arrayOfHelpMessages.next(list_of_messages);
	  },(err) =>{
		  this.errorRelatedObservable.next({"retriveListOfMessages":"failure"});
	  });
  }
  getUserTickets(page:number)
  {
	  var username = this.userInformationService.getTipUsername();
	  var getUserTicketsUrl = `${this.baseUrl_}/getListOfHelpSubmissions?page=${page}&username=${username}`;
	  var array_of_help_request_tickets:Array<HelpTicket> = new Array<HelpTicket>();
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(getUserTicketsUrl,{headers:headerOptions}).subscribe((success) => {
		  console.log(success);
		  var json_object = ((success as unknown) as any);
		  console.log(json_object);
		  for(var i = 0; i < json_object.length; i++)
		  {
			  var jsonObject = json_object[i];
			  var helpTicket:HelpTicket = new HelpTicket(jsonObject["help_question"],jsonObject["category"], new Date(jsonObject["added_when"]),jsonObject["open"],jsonObject["solved"]);
		      helpTicket.setHelpTicketUUID(jsonObject["help_uuid"]);
			  array_of_help_request_tickets.push(helpTicket);  
		  }
		  this.arrayOfHelpRequestTickets.next(array_of_help_request_tickets);
		  
	  },(err) =>{
		  this.errorRelatedObservable.next({"retriveUserTickets":"failure"});
	  })
  }
  submitHelpRequest(helpQuery:HelpQuery)
  {
	  var username = this.userInformationService.getTipUsername();
	  var submitHelpRequestUrl = `${this.baseUrl_}/submitHelpQuery?username=${username}`;
	  var headerOptions = this.securityService.generateAndReturnPostHeaders();
	  this.http.post(submitHelpRequestUrl,helpQuery,{headers:headerOptions,observe:'response'}).subscribe((success) => {
		  console.log(success);
		  var jsonObject = success;
		  if(success.status == 208)
		  {
			  this.errorRelatedObservable.next({"submitHelpRequest":"Already has one open"});
		  }else{
			  this.helpMiscellaneousRelatedObservable.next({"submitHelpRequest":"successful"});
		  }
	  },(err) => {
		  
		 
		    this.errorRelatedObservable.next({"submitHelpRequest":"failure"});
		  
	  });
	  
  }
  getCategoriesOfHelp()
  {
	  var url = `${this.baseUrl_}/getAllQuestionCategories`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(url,{headers:headerOptions}).subscribe((success) => {
		  console.log(success);
		  var jsonInformation = ((success as unknown) as any);
		  var arrayOfHelps: Array<string> = new Array<string>(); 
		  for(var x = 0; x < jsonInformation.length; x++)
		  {
			  arrayOfHelps.push(jsonInformation[x]);
		  }
		  arrayOfHelps = arrayOfHelps.reverse();
		  console.log(arrayOfHelps); 
		  this.arrayOfHelpCategoriesObservable.next(arrayOfHelps);
	  });
  }
  
  closeHelpSession(uuid:string, rating:number)
  {
	  var url  = `${this.baseUrl_}/closeHelpSession?helpSessionUUID=${uuid}&rating=${rating}`;
	  this.http.post(url,null).subscribe((success) =>{
		  this.helpMiscellaneousRelatedObservable.next({"closeHelpSession":"successful"});
	  },(err) =>{
		    this.errorRelatedObservable.next({"clseHelpSession":"failure"});
	  });
  }
}
