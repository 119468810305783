import { Injectable, Inject } from '@angular/core';
import {Http,ResponseContentType} from "@angular/http";
import {SecurityService} from "./security.service";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {map} from 'rxjs/operators';
import {Article} from "./../Models/article.model";
@Injectable()
export class ArticleService {
   
  
  
  private articleBytesSubject:Subject<any>;
  private articleListSubject:Subject<Array<Article>>;
  private articleInformationSubject:Subject<{[key:string]:string}>; 
  private articleErrorSubject:Subject<string>;
  baseUrl_:string;
  userHelpBaseUrl_:string;
  constructor(private http:HttpClient, private securityService:SecurityService, @Inject("base_href")private base_href:string ) 
  {
	  this.articleBytesSubject = new Subject<any>();
	  this.articleInformationSubject = new Subject<{[key:string]:string}>();
	  this.articleListSubject = new Subject<Array<Article>>();
	  this.articleErrorSubject = new Subject<string>();
	  this.baseUrl_ = `${base_href}/getFileInformation`;
	  this.userHelpBaseUrl_ = `${base_href}/UserHelp`;
  }
  
  getArticleInformationSubject()
  {
	  return this.articleInformationSubject;
  }
  
  getArticleListSubject()
  {
	  return this.articleListSubject;
  }
  getArticleBytesSubject()
  {
	  return this.articleBytesSubject;
  }
  getCountOfArticles()
  {
	  var url = `${this.baseUrl_}/getCountOfArticles`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(url,{headers:headerOptions}).subscribe((success) => {
		 var count_of_articles = ((((success as unknown) as any) as string)); 
		 this.articleInformationSubject.next({"count_of_articles":count_of_articles});
	  },(err) =>{
		 console.log(err); 
	  });
  }
  getArticle(articleUUID:string)
  {
	  var url = `${this.baseUrl_}/getPDFFileInBytes?fileUUID=${articleUUID}`;
	  console.log("here before pdf request");
	    var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(url, { headers:headerOptions, responseType: 'blob'}).pipe(
                map((result: any) => {
					console.log("here during pdf request");
					console.log(result);
					//this.articleBytesSubject.next(result);
                     return new Blob([result], { type: "application/pdf" });
                })).subscribe((res) =>{
					this.articleBytesSubject.next(res);
					console.log(res);
				});
	console.log("here after pdf request");
            
  }
  
  getArticlesBySearchQuery(query:string)
  {
	var url = `${this.userHelpBaseUrl_}/getArticlesByName?articleQueryName=${query}`;  
	var headerOptions = this.securityService.generateAndReturnHeaders();
	this.http.get(url, {headers:headerOptions}).subscribe((success) =>{
		var json_list = ((success as unknown as any));
		 var listOfArticles:Array<Article> = new Array<Article>();
		 for(var i = 0; i < json_list.length; i++)
		 {
			 var json_at = json_list[i];
			 var article:Article = new Article(json_at["articleUniqueTitle"],json_at["addedWhen"],json_at["articlePictureLink"], json_at["article_uuid"]);
			 listOfArticles.push(article);
		 }
		 this.articleListSubject.next(listOfArticles);
	},(err) =>{
		
	});
  }
  
  getListOfArticles(page:number)
  {
	  var url = `${this.baseUrl_}/getArticles?page=${page}`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(url,{headers:headerOptions}).subscribe((success) =>{
		 var json_list = ((success as unknown) as any);
		 var listOfArticles:Array<Article> = new Array<Article>();
		 for(var i = 0; i < json_list.length; i++)
		 {
			 var json_at = json_list[i];
			 var article:Article = new Article(json_at["articleUniqueTitle"],json_at["addedWhen"],json_at["articlePictureLink"], json_at["article_uuid"]);
			 listOfArticles.push(article);
		 }
		 this.articleListSubject.next(listOfArticles);
	  },(err) =>{
		  console.log(err)
	  });
  }	  
  
 
  
  
}
