import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
declare var ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title:String = 'Tipper';
  
  constructor(private router:Router, private route:ActivatedRoute)
  {
	  
  }
  ngOnInit()
  {
	this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    }); 
	//this.router.navigate(['/landing']);  
	//console.log(this.route.component);
  }
  
}
