import { Component,OnInit,Input,ViewChild } from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";
import {Subject} from "rxjs";
import { StarRatingComponent } from 'ng-starrating';
@Component({
  selector: 'star-rating-modal',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {

  @Input() private connectionToModal:Subject<{[key:string]:string}>;
  @ViewChild("starRating",{static:false}) starRatingComponent:StarRatingComponent;
  constructor(private ngxSmartModalService:NgxSmartModalService)
  { 
    this.connectionToModal = new Subject<{[key:string]:string}>();
  }
  ngOnChanges(changes)
  {
	  if(changes["connectionToModal"])
	  {
		  this.connectionToModal.subscribe((value) =>{
			  if(value["show"] == "open"){
			   this.ngxSmartModalService.get("ratingModal").open();
			  }
		  });
	  }
  }
  rateAndSubmit($event:{oldValue:number, newValue:number, starRating:StarRatingComponent})
  {
	  console.log($event);
	 // console.log(this.starRatingComponent._value);
	  //console.log($event.newValue);
	 // this.ngxSmartModalService.get("ratingModal").close();
	  var rateAsString = (($event.newValue as unknown) as string);
	  this.connectionToModal.next({"results":rateAsString});
	  this.ngxSmartModalService.get("ratingModal").close();
  }
  ngOnInit() {
	 // this.starRatingComponent.onValueChange.subscribe((value) =>{
		//console.log(value);  
	  //})
  }

}
