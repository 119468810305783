import { Injectable,Inject } from '@angular/core';
import {Response} from "@angular/http";
import {HttpClient} from "@angular/common/http";
import {Tip} from "./../Models/tip.model";
import{Subject} from "rxjs";
import {UserInformationService} from "./user-information.service";
import {SecurityService} from "./security.service";
import {ExcelJob} from "./../Models/excel-job.model";
import {TimeGroupTip} from "./../Models/time-group-tip";
import {DatePipe} from "@angular/common";
import { saveAs } from 'file-saver';
@Injectable()
export class TipInformationService {

  private tipsSubjectObservable: Subject <{[key:string]:Array<Tip>}>;
  private countOfUserTipsSubject:Subject<{[key:string]:number}>;
  private tipInformationSubject:Subject<{[key:string]:string}>;
  private tipErrorSubjectObservable:Subject<{[key:string]:string}>;
  private timeGroupTipSubjectObservable:Subject<{[key:string]:TimeGroupTip}>;
  baseUrl_:string;
  private basehrefF_:string = "";
  constructor(private userInformationService:UserInformationService,private securityService:SecurityService,private datePipe:DatePipe, private http:HttpClient, @Inject("base_href")base_href:string) 
  {
    this.countOfUserTipsSubject =  new Subject<{[key:string]:number}>();
	this.tipsSubjectObservable = new Subject<{[key:string]:Array<Tip>}>();
	this.tipInformationSubject = new Subject<{[key:string]:string}>();
	this.tipErrorSubjectObservable = new Subject<{[key:string]:string}>();
	this.timeGroupTipSubjectObservable = new Subject<{[key:string]:TimeGroupTip}>();
	this.baseUrl_ = `${base_href}/tip`;
	this.basehrefF_ = `${base_href}/getFileInformation`;
	
  }
  getCountOfUserTipsSubjectObservable()
  {
	  return this.countOfUserTipsSubject;
  }
  getTimeGroupTipSubjectObservable():Subject<{[key:string]:TimeGroupTip}>{
	   return this.timeGroupTipSubjectObservable;

  }  
  getTipInformationSubjectObservable():Subject<{[key:string]:string}>
  {
	  return this.tipInformationSubject;
  }
  getUserTipsSubjectObservable()
  {
	  return this.tipsSubjectObservable;
  }
  getTipErrorSubjectObservable()
  {
	  return this.tipErrorSubjectObservable;
  }
  getCountOfUserTips()
  {
	  var tipUsername = this.userInformationService.getTipUsername();
	  var getCountOfUserTipsUrl = `${this.baseUrl_}/getCountOfTips/${tipUsername}`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  console.log("the url of getting count of tips is " + getCountOfUserTipsUrl);
	  this.http.get(getCountOfUserTipsUrl,{ headers:headerOptions }).subscribe((success)=>{
		  console.log(success);
		  var count:number = (success as unknown) as number;//["_body"];
		  console.log("user tips are: " + count);
		  this.countOfUserTipsSubject.next({"countOfUserTipsOverall":count});
	  },(err) =>{
		  console.log(err);
		 this.tipErrorSubjectObservable.next({"countOfUserTipsOverall":"Cannot Retrieve Tips"})
	  });
  }

  getNextTipAfterTip(tipDate:Date, tipUUID:string, tipId:number)
  {
	  var tipUsername:string = this.userInformationService.getTipUsername();
	  var randNum = Math.random();
      var tipUrl = `${this.baseUrl_}/getNextTip?username=${tipUsername}&tiptimestamp=${tipDate}&rand=${randNum}&tipUUID=${tipUUID}`;
	  //console.log(`${tipUrl}`);
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(tipUrl,{headers:headerOptions}).subscribe((success) =>{
		  var tipJson = ((success as unknown) as any);
		  console.log(tipJson);
		  if(tipJson != null)
		  {
			  var array_of_tip:Array<Tip> = new Array<Tip>();
			  array_of_tip.push(new Tip(tipJson["tip_uuid"],new Date (tipJson["added_Date"]), tipJson["amount"],  tipJson["note"]));
			  this.tipsSubjectObservable.next({"NextTip":array_of_tip});
		  }
	  },(err) =>{
		//  console.log(err);
	  });
  }
  getUserTips(page:Number, isNextFive:boolean):void
  {
	  var tipUsername:string = this.userInformationService.getTipUsername();
	  var tipUrl:string = `${this.baseUrl_}/getUserTips/${tipUsername}?page=${page}`;
	  var tipArrayList:Array<Tip> = new Array<Tip>();
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(tipUrl,{ headers:headerOptions }).subscribe((success)=>{
		  console.log(success);
         var tipListJson = ((success as unknown) as any);//success["_body"];
		 
		 //console.log(success["_body"]);
	     for(var x =0; x < tipListJson.length; x++)
		 {
			 var tipJson = tipListJson[x];
			 var temp_tip:Tip = new Tip(tipJson["tip_uuid"],new Date (tipJson["added_Date"]), tipJson["amount"],  tipJson["note"]);
		     tipArrayList.push(temp_tip);
		 }
		 console.log("tip list is " + tipArrayList);
		 if(isNextFive == false)
		 {
		  this.tipsSubjectObservable.next({"UserTipsTipArray":tipArrayList});
	     }
		 else{
	      this.tipsSubjectObservable.next({"NextFive":tipArrayList});	 
		 }
	  },
	  (err) =>{
		  console.log(err);
		  if(isNextFive == false)
		  {
		    this.tipErrorSubjectObservable.next({"UserTipsError":"Cannot retrieve user tips"});
		  }
		  else
		  {
		    this.tipErrorSubjectObservable.next({"UserTipsErrorNextFive":"Cannot retrieve user tips"});
		  }
	  },
      () =>{
		  console.log("completed");
	  }	  
	  );

	 
  }
  getNextTip(timeStamp:Date):void
  {
	  
  }  
  getAmountOfTipsBetweenTwoDates(firstDate:Date, secondDate:Date)
  {
	  var tipUsername:string = this.userInformationService.getTipUsername();
	  var tipUrl:string =`${this.baseUrl_}/getTipsCountBetweenTwoDates/${tipUsername}?date1=${firstDate}&date2=${secondDate}`;
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(tipUrl,{ headers:headerOptions }).subscribe((success) =>{
		  console.log("Amount of tips between two dates are: " + success);//["_body"]);
		  var number_of_tips:number = success as unknown as number ;//["_body"];
		  this.countOfUserTipsSubject.next({"countOfTipsBetweenTwoDates": number_of_tips});
	  },(err) => {
		  
	  });
  }
  getUserTipsBetweenTwoDates(firstDate:Date, secondDate:Date, page:Number):void
  {
     var tipUsername:string = this.userInformationService.getTipUsername();
	 var tipUrl:string = `${this.baseUrl_}/getTipsBetweenTwoDates/${tipUsername}?firstDate=${firstDate}&secondDate=${secondDate}&page=${page}`;
     var tipArrayList:Array<Tip> = new Array<Tip>();
     var headerOptions = this.securityService.generateAndReturnHeaders();	 
	 this.http.get(tipUrl,{ headers:headerOptions }).subscribe((success) =>{
		var tipsListJson = /*JSON.parse(*/((success as unknown) as any);//); 
		for(var x = 0; x < tipsListJson.length; x++)
		{
			var temp_tip_json = tipsListJson[x];
			var temp_tip:Tip;
			temp_tip = new Tip(temp_tip_json["tip_uuid"],new Date (temp_tip_json["added_Date"]), temp_tip_json["amount"],  temp_tip_json["note"]);
			tipArrayList.push(temp_tip);
			console.log(tipArrayList);
		}
        this.tipsSubjectObservable.next({"UserTipsArrayBetweenTwoDates":tipArrayList});		
	 },(err) =>{
		 this.tipErrorSubjectObservable.next({"UserTipsBetweenTwoDates":"Cannot Retrieve tips between two dates"});
	 });
     	 
  }
 
  getTotalTipsBetweenTwoDates(firstDate:Date, secondDate:Date)
  {
	  var tipUsername:string = this.userInformationService.getTipUsername();
      var tipUrl = `${this.baseUrl_}/getTotalTipsCurrencyBetweenTwoDates?firstDate=${firstDate}&secondDate=${secondDate}&username=${tipUsername}`;
	  var tipTotalArrayList:Array<Tip> = new Array<Tip>();
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.get(tipUrl,{ headers:headerOptions }).subscribe((success) =>{
		  console.log(success);
		  var tipsTotalFromDateRange = success;//success["tipSummarizeList"];//JSON.parse(success["_body"])["tipSummarizeList"]; 
		  console.log(tipsTotalFromDateRange);
		  var timeGroupTip = new TimeGroupTip(tipsTotalFromDateRange["date"],tipsTotalFromDateRange["to_date"],tipsTotalFromDateRange["tip_amount"]);
		  var timeGroupTipArray = new Array<TimeGroupTip>();
		 /* for(var x = 0; x < tipTotalsListJson.length; x++)
		  {
			  var temp_tip_json = tipTotalsListJson[x];
			  var temp_tip:Tip;
			  temp_tip = new Tip(temp_tip_json["tip_uuid"],new Date (temp_tip_json["added_Date"]), temp_tip_json["amount"],  temp_tip_json["note"]);
			  tipTotalArrayList.push(temp_tip);
		  }*/
		  //console.log(tipTotalArrayList);
		  this.timeGroupTipSubjectObservable.next({"UsersTipTotalsBetweenTwoDates":timeGroupTip});
		  //this.tipsSubjectObservable.next({"UsersTipTotalsBetweenTwoDates":tipTotalArrayList});
	  },(err) =>{
		  console.log(err);
		  this.tipErrorSubjectObservable.next({"getTotalTipsBetweeenTwoDates":"Cannot retrieve tips between two dates"});
	  });
	  
  }
  createExcelJob(firstDate:Date,secondDate:Date, currency:string)
  {
	  var excel_url = `${this.baseUrl_}/createExcelReport`;
	  var tipUsername:string = this.userInformationService.getTipUsername();
	  var excelJob:ExcelJob = new ExcelJob(this.datePipe.transform(firstDate,"EEE MMM dd yyyy HH:mm:ss 'GMT'Z").toString(),(this.datePipe.transform(secondDate,"EEE MMM dd yyyy HH:mm:ss 'GMT'Z").toString()),tipUsername,currency);
	  var headerOptions = this.securityService.generateAndReturnHeaders();
	  this.http.post(excel_url,excelJob,{ headers:headerOptions, observe: 'response' }).subscribe((success) =>{
		  console.log(success);
		  this.tipInformationSubject.next({"excelJob":"success"});
	  },(err) =>{
		  console.log(err);
		  if(err.status == 409){
			  this.tipErrorSubjectObservable.next({"CreateExcelJob":"Must wait at least 24 hours from your past generate excel report request."});
		  }
		  else{
		    this.tipErrorSubjectObservable.next({"CreateExcelJob":"Could not create excel job"});
		  }
	  });
  }
  getTipTotalForWebsite(website:string,firstDate:Date,secondDate:Date)
  {
	var username = this.userInformationService.getTipUsername();
    var headerOptions = this.securityService.generateAndReturnHeaders();
	var url = `${this.baseUrl_}/getTotalTipsForWebsite?username=${username}&website=${website}&firstDate=${firstDate}&secondDate=${secondDate}`;
    this.http.get(url).subscribe((success) =>{
		console.log(success);
		var tipTotal = (( success as unknown )as any);
		//var arrayOfTips = new Array<Tip>();
		var timeGroupTip:TimeGroupTip = new TimeGroupTip(tipTotal["date"],tipTotal["to_date"],tipTotal["tip_amount"]);
		
		//arrayOfTips.push(new Tip(tipTotal["tip_uuid"],tipTotal["added_date"],tipTotal["amount"],null));
		
	this.timeGroupTipSubjectObservable.next({"UsersTipTotalsBetweenTwoDatesForWebsite":timeGroupTip});
	},(err) =>{
		console.log(err);
		this.tipErrorSubjectObservable.next({"getTipTotalForWebsite":"error"});
	});	
  }
  checkIfUserExcelFileExist( downLoad:any, isOnInit:boolean){
	downLoad = downLoad + "";
  	var username = this.userInformationService.getTipUsername();
	var security_uuid = "";
  	var headerOptions = this.securityService.generateAndReturnHeaders();
  	var url = `${this.baseUrl_}/checkIfExcelFileExist?username=${username}`;
  	this.http.get(url,{ headers:headerOptions}).subscribe((success) =>{
  		  console.log(success);
  		  var results = (((success) as unknown) as any );
  		  console.log(results);
  		  if(results["result"] == true ){
			var lastUploadDate:any = undefined;
			if(results["fileDate"]){
				lastUploadDate = new Date(results["fileDate"]).toString();
				security_uuid = results["security_uuid"];
			}
            this.tipInformationSubject.next({"checkIfUserExcelFileExist":"Excel file exist",
									     	"onInit":`${isOnInit}`,
											 "download":downLoad,
											 "lastUploadDate":lastUploadDate,
											 "security_uuid":security_uuid});
  		  }else{
            this.tipInformationSubject.next({"checkIfUserExcelFileExist":"Excel file does not exist","onInit":`${isOnInit}`});
  		  }
      
  	}, (err) =>{
     this.tipErrorSubjectObservable.next({"checkIfUserExcelFileExist":"Failed to execute"});
  	});
  }
  
  retreiveTipllyFinancialReportExcelFile( security_code:string){
	var username = this.userInformationService.getTipUsername();
	var url = `${this.basehrefF_}/getUserGeneratedFile?username=${username}&FileType=FinancialReport&security_code=${security_code}`;
	var headerOptions = this.securityService.generateAndReturnHeaders();
	this.http.get(url,{headers:headerOptions,responseType:"blob",observe:"response"}).subscribe((success) =>{
       const blob = success.body as Blob;
	   var fileName = success.headers.get('Content-Disposition');
	   if(fileName != null){
	    fileName = fileName.split('filename=')[1] || 'excel-report.xlsx';
	   }
	   saveAs(blob, fileName);
	},(err) =>{
		this.tipInformationSubject.next({"retreiveTipllyFinancialReportExcelFile":"Excel file does not exist"});

	})
	
	
}
}
