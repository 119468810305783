export class HelpTicket {
    public helpTicketUUID:string;	
	
	constructor(public helpQuestion:string, public helpCategory:string, public added_when:Date, public isOpen:boolean, public solved:boolean)
	{
		
	}
	
	setHelpTicketUUID(inHelpTicketUUID:string)
	{
		this.helpTicketUUID = inHelpTicketUUID;
	}
	
}
