import { Component, OnInit,EventEmitter,Output,AfterViewInit,AfterContentChecked } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms"
import {NgxSmartModalService} from "ngx-smart-modal";
import {WebsiteService} from "./../../services/website-service.service";
import {VideoService} from "./../../services/video-service.service";
import {Subject,BehaviorSubject} from 'rxjs';
import {Website} from "./../../Models/website.model";
import {PendingWebsite} from "./../../Models/pending-website.model";
import {timer}  from 'rxjs';
import {HeadingContext} from "./../../exportedValues/heading-context.model";
import {Miscellaneous} from "./../../exportedValues/miscellaneous";
import {rotation} from "./../../../animations/rotateAnimation.animation";
import {urlStanderizer} from "./../../exportedValues/verify-url-functions";
@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css'],
  animations: [rotation()]
})


export class WebsiteComponent implements OnInit, AfterViewInit, AfterContentChecked {

  websiteForm:FormGroup;
  buttonHover:Array<String> = ['ui','negative','basic','button'];
  buttonList:Array<Array<String>>; //Finish up button List
  timerObservable:any;
  openForm:Subject<String>;
  ownedwebsiteList:Array<Website>;
  pendingwebsiteList:Array<PendingWebsite>;
  failedAuthenticationWebsiteList:Array<Website>;
  ownedwebsitepaginationArray:Array<Number>;
  pendingwebsitepaginationArray:Array<Number>;
  failedAuthenticationWebsiteArray:Array<Number>;
  numberofWebsites:Number;
  pageOfOwnedWebsites:Number = 1;
  pageOfPendingWebsites:Number = 1;
  numberofPendingWebsites:Number;
  pageOfFailedAuthenticationWebsite:Number = 1;
  numberOfFailedWebsites:Number;
  websiteToremove:string = "";
  YesNo:Subject<{[key:string]:string}>;
  headingContext:HeadingContext;
  rotationArray:string[] = ["","",""];
  miscellaneous:Miscellaneous;
  connectionToLoadingModal:Subject<{[key:string]:string}>;
  connectionToInputDialog:Subject<{[key:string]:string}>;
  connectionToWebsiteShowing:Subject<{[key:string]:string}>;
  websiteUpdatesToLoadingObservable:Subject<string>;
  errorModalConnector:Subject<{[key:string]:string}>;
  connectionToSuccesssModal:Subject<{[key:string]:string}>;
  connectionToVideoModal:Subject<{[key:string]:string}>;
  constructor(private FB:FormBuilder, private ngxSmartModalService:NgxSmartModalService, private websiteService:WebsiteService, private videoService:VideoService) { 
   this.connectionToLoadingModal = new Subject<{[key:string]:string}>();
   this.websiteUpdatesToLoadingObservable = new Subject<string>();
   this.errorModalConnector = new Subject<{[key:string]:string}>();
   this.connectionToInputDialog = new Subject<{[key:string]:string}>();
   this.connectionToWebsiteShowing = new Subject<{[key:string]:string}>();
   this.connectionToSuccesssModal   = new Subject<{[key:string]:string}>();
   this.connectionToVideoModal = new Subject<{[key:string]:string}>();
   
  }
  getPage(e)
  {
	  this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
	  console.log(e);
	  var page = +e;
	  console.log("page is " + this.pageOfOwnedWebsites);
	  this.websiteService.getUserWebsites(page);
	  this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
  }
  getUserPendingWebsites(page)
  {
	  console.log(page);
	  this.websiteService.getUserPendingVerificationWebsites(page);
	  
  }
  openWebsiteModal()
  {
	  this.openForm.next("open");
  }  
  deleteWebsite(websitename:string)
  {
	  console.log("emitted");
	//  if(this.websiteToremove != websitename)
	  //{
		 
		  this.YesNo.subscribe((value:{[key:string]:string}) => {
			 console.log("result of Yes No is ");
			 console.log(value); 
			 if(value["removeWebsite"])
			 {
				 if(value["removeWebsite"] == "yes")
				 {
					if(this.websiteToremove != websitename)
					{						
					 this.websiteToremove = websitename;
					 this.websiteService.deleteUserWebsite(this.websiteToremove);
					 this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
					 console.log("received approval to remove website");
					}
					 
				 }
			 }
			 
		  });
		  this.YesNo.next({"YesNo":"deleteWebsite"});
	 // }
	  
  }
  submitWebsite(website:string, timeVerify:number)
  {
	  console.log(website);
	  this.websiteService.addNewWebsite(website,timeVerify);
	 
	  this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
	  
  }
  ngAfterContentChecked(){
  // console.log("content is checked");
  /*  for(var i = 0; i < this.rotationArray.length; i++)
    {
	   if(this.rotationArray[i] != "rotated")
	   {
	    this.rotationArray[i] = "default";		
		//this.rotationArray[i] = "rotated";
	   }
    }*/
   
  }
  ngAfterViewInit(){
  }
  ngOnInit()
  {
	this.ownedwebsiteList = new Array<Website>();
	this.pendingwebsiteList = new Array<PendingWebsite>();
	this.ownedwebsitepaginationArray = new Array<Number>();
	this.pendingwebsitepaginationArray = new Array<Number>();
	this.failedAuthenticationWebsiteArray =new Array<Number>();
	this.YesNo = new Subject<{[key:string]:string}>();
	this.timerObservable =  timer(5000);
	this.headingContext = new HeadingContext();
	this.miscellaneous = new Miscellaneous();
	this.websiteService.getSubjectwebsiteObservable().subscribe((values :any) => {
		values = values as Array<Website>;
		this.ownedwebsiteList = [];
		this.buttonList = [];
	    this.rotationArray[0] = "fini";
		for(var i = 0; i < values.length; i++)
		{
			console.log(values[i]);
			this.ownedwebsiteList.push((values[i]));
			this.buttonList.push(this.buttonHover);
		}
	});
	this.websiteService.getPendingWebsiteObservable().subscribe((values:any) => {
		values = values as Array<PendingWebsite>;
		console.log(values);
		this.pendingwebsiteList = [];
		this.rotationArray[1] = "fini";
		for(var i = 0; i < values.length; i++)
		{
			console.log("supernatural beyond");
			this.pendingwebsiteList.push(values[i]);
		}
	});
	this.websiteService.getWebsiteCountObservable().subscribe((value:number) =>{
		console.log(value);
		this.ownedwebsitepaginationArray = [];
		this.numberofWebsites = value;
	    var pagesValue:number = value / 5 ;
		if(pagesValue <= 1)
		{
	    	this.ownedwebsitepaginationArray.push(1);
	    }
		else{		
			for(var i= 0; i < pagesValue; i++){
			this.ownedwebsitepaginationArray.push(i);
			}
			console.log("here i am");
			console.log(this.ownedwebsitepaginationArray);
		}
	});
	
	this.websiteService.getUserCountOfOwnedWebsites();
	this.websiteService.getUserPendingVerificationWebsites(1);
    this.websiteService.getUserWebsites(1);
	this.websiteService.getPendingWebsiteCount().subscribe((value:number) =>{
	   this.numberofPendingWebsites = value;
	   this.pendingwebsitepaginationArray = [];
	   var pagesValue:number = value / 5;
	   console.log("value is " + value);
	   console.log("value of the evaluated is: " + pagesValue);
	   if(pagesValue <= 1)
	   {
		   this.pendingwebsitepaginationArray.push(1);
	   }
	   else
	   {
		  for(var i  = 0; i < pagesValue; i++)
		  {
			  this.pendingwebsitepaginationArray.push(i);
		  }
		  console.log("pendingwebsitepaginationArray:");
		  console.log(this.pendingwebsitepaginationArray);
	   }
	   
	});
	
	this.websiteService.getUserCountOfPendingWebsites();
	this.websiteService.getFailedWebsiteCountObservable().subscribe((value:number) => {
		this.numberOfFailedWebsites = value;
		console.log("number of failed websites are");
		console.log(this.numberOfFailedWebsites);
		var pagesValue = value / 5;
		if(pagesValue <= 1)
		{
			this.failedAuthenticationWebsiteArray.push(1);
		}
		else
		{
		  for(var i = 0; i < pagesValue; i++)
		  {
			  this.failedAuthenticationWebsiteArray.push(i);
		  }			  
		  console.log("failedAuthenticationWebsiteArray");
		  console.log(this.failedAuthenticationWebsiteArray);
		}
	});
	this.websiteService.getUserCountOfFailedWebsites();
	this.websiteService.getFailedWebsitesObservable().subscribe((values:any) => {
		values = values as Array<Website>;
		this.failedAuthenticationWebsiteList = [];
		this.rotationArray[2] = "fini";
		for(var i = 0; i < values.length; i++)
		{
			this.failedAuthenticationWebsiteList.push(values[i]);
		}
		console.log("failed authentication websites are");
		console.log(this.failedAuthenticationWebsiteList);
		
	});
	this.websiteService.getUserFailedWebsites(1);
	
	console.log("Here at ownedwebsiteList");
	console.log(this.ownedwebsiteList);
    this.openForm = new Subject<String>();
    this.websiteForm = this.FB.group({
		websiteUrl:[""]
	});
	this.openForm.subscribe((value:string) => {
		console.log(value);
		switch(value)
		{
			case "open":
			            break;
			default:
			         console.log(value);
			         var jsonObject = JSON.parse(value);
					 
			         this.submitWebsite(jsonObject["website"],jsonObject["timeVerify"]);
		}
	});
	this.websiteService.getWebsiteErrorObservable().subscribe((value) =>{
		console.log(value);
		this.websiteUpdatesToLoadingObservable.next("GotInformationNowClose");
	if(value["AddedWebsite"] == "Conflict")
	{
		this.errorModalConnector.next({"show":"claimedError"});
	}
	else if (value["AddedWebsite"] == "Overloaded")
	{
		this.connectionToWebsiteShowing.next({"send":"overloaded"});
	}
	else if(value["checkWebsite"] == "Error")
	{
		this.connectionToWebsiteShowing.next({"send":"unclaimed"});
	}
	else if (value["DeleteWebsiteError"] == "Error")
	{
		this.connectionToWebsiteShowing.next({"send":"unclaimed"});
	}
	else {
		this.errorModalConnector.next({"show":"retrieveError"});
	}
	});
	this.websiteService.getWebsiteSuccessSubject().subscribe((value) =>{
		this.websiteUpdatesToLoadingObservable.next("GotInformationNowClose");
		if(value["addedWebsite"] == "success")
		{
			this.refreshResults("PendingWebsites");
		}
		else if (value["checkWebsite"] == "success")
		{
			this.connectionToWebsiteShowing.next({"send":"claimed"});
		}
		else if (value["deleteWebsite"] == "success")
		{
			console.log("succesfully deleted website");
			this.refreshResults("OwnedWebsites");
			this.connectionToSuccesssModal.next({"show":"open"});
		}
	});
   
  }
  continueRotation(index:number)
  {
	  
	  if(this.rotationArray[index] != "fini")
	  {
		  if(this.rotationArray[index] == "default")
		  {
			   this.rotationArray[index] = "rotated";
		  }
		  else 
		  {
			  this.rotationArray[index] = "default";
		  }
	  }
	   
  }
  
  refreshResults(table:string)
  {
	  switch(table)
	  {
		  case "OwnedWebsites":
		        this.rotationArray[0] = "default";
				this.ownedwebsiteList = [];
				this.websiteService.getUserWebsites(this.pageOfOwnedWebsites);
				this.websiteService.getUserCountOfOwnedWebsites();
		        break;
		  case "PendingWebsites":
		        this.rotationArray[1] = "default";
				this.pendingwebsiteList = [];
				this.websiteService.getUserPendingVerificationWebsites(this.pageOfPendingWebsites);
		        break;
		  case "FailedWebsites":
		        this.rotationArray[2] = "default";
				this.failedAuthenticationWebsiteList = [];
				this.websiteService.getUserFailedWebsites(1);
				this.websiteService.getUserCountOfFailedWebsites();
                break;		  
	  }
  }
  submitWebsiteForExtraActs(website:string,action:string)
  {
	  website = website.trim();
	  if(website != "")
	  {
		  website = urlStanderizer(website);
		  
		  if(action == "search")
		  {
			   this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
			   this.websiteService.checkIfUserOwnsWebsite(website);
		  }
		  else if(action == "delete")
		  {
			  this.deleteWebsite(website);
		  }
	  }
  }
  hoverButton(index:number)
  {
	   console.log(this.buttonList[index]);
	  if(this.buttonList[index].length == 4)
	  {
		   this.buttonList[index] = ['ui','red','button'];
	  }
	  else{
		   this.buttonList[index] = ['ui','negative','basic','button'];
	  }
	 
	  console.log("button changed");
  }
  
  bringUpVideoModal()
  {
	  var video_url = this.videoService.getUrlOfVideo("Tiplly Website");
	  console.log(video_url);
	  this.connectionToVideoModal.next({"show":`${video_url}`});
  }
 
}
