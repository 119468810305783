export class Flags{
           public aydenflagArray:Array<Object> = [  {"name":"United States-us",            "flag":"flag-icon flag-icon-us"},
													{"name":"Australia-au",                "flag":"flag-icon flag-icon-au"},
													{"name":"Austria-at",                  "flag":"flag-icon flag-icon-at"},
													{"name":"Belgium-be",                  "flag":"flag-icon flag-icon-be"},
													{"name":"Canada-ca",                   "flag":"flag-icon flag-icon-ca"},
													{"name":"Czech Republic-cz",           "flag":"flag-icon flag-icon-cz"},
													{"name":"Denmark-dk",                  "flag":"flag-icon flag-icon-dk"},
													{"name":"Estonia-ee",                  "flag":"flag-icon flag-icon-ee"},
													{"name":"Finland-fi",				   "flag":"flag-icon flag-icon-fi"},
													{"name":"France-fr",                   "flag":"flag-icon flag-icon-fr"},
													{"name":"Germany-de",                  "flag":"flag-icon flag-icon-de"},
													{"name":"Greece-gr",				   "flag":"flag-icon flag-icon-gr"},
													{"name":"Hong Kong-hk",				   "flag":"flag-icon flag-icon-hk"},
													{"name":"Ireland-ie",                  "flag":"flag-icon flag-icon-ie"},
													{"name":"Italy-it",                    "flag":"flag-icon flag-icon-it"},
													{"name":"Japan-jp",					   "flag":"flag-icon flag-icon-jp"},
													{"name":"Latvia-lv",                   "flag":"flag-icon flag-icon-lv"},
													{"name":"Lithuania-lt",                "flag":"flag-icon flag-icon-lt"},
													{"name":"Luxemborg-lu",                "flag":"flag-icon flag-icon-lu"},
													{"name":"Malta-mt",                    "flag":"flag-icon flag-icon-mt"},
													{"name":"Netherlands-nl",              "flag":"flag-icon flag-icon-nl"},
													{"name":"New Zealand-nz",              "flag":"flag-icon flag-icon-nz"},
													{"name":"Norway-no",                   "flag":"flag-icon flag-icon-no"},
													{"name":"Poland-pl",                   "flag":"flag-icon flag-icon-pl"},
													{"name":"Portugal-pt",                 "flag":"flag-icon flag-icon-pt"},
													{"name":"Romania-ro",                  "flag":"flag-icon flag-icon-ro"},
													{"name":"Singapore-sg",                "flag":"flag-icon flag-icon-sg"},
													{"name":"Slovakia-sk",                 "flag":"flag-icon flag-icon-sk"},
													{"name":"Spain-es",                    "flag":"flag-icon flag-icon-es"},
													{"name":"Sweden-se",                   "flag":"flag-icon flag-icon-se"},
													{"name":"Switzerland-ch",              "flag":"flag-icon flag-icon-ch"},
													{"name":"United Kingdom-gb",           "flag":"flag-icon flag-icon-gb-nir"},
													{"name":"England United Kingdom-gb",   "flag":"flag-icon flag-icon-gb"},
													{"name":"Sottland United Kingdom-gb",  "flag":"flag-icon flag-icon-gb-sct"},
													{"name":"Wales United Kingdom-gb",     "flag":"flag-icon flag-icon-gb-wls"}];

}