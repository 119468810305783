export class TipplyContentpara {
	
	public paraContentArray:Array<String> = ["Content creators are the powerhouse of the Internet. They create all content and spread their creative ideas on the web.",
	                                         "All content on the Internet is created by a content creator. Content that is usually free.",
											 "They provide entertainment for next to nothing. Compensation can be very low for content creators.",
											 "However there seems to be a financial demonetization storm at hand. Hosts with politically correct movements have demonetized several creators",
											 "These storms have left content creators in shambles. They have lead to content creators worrying about their next payment checks, and removal from partner programs.",
											 "This can ultimately leave content viewers without content to view or leave content viewers viewing content in poor quality",
											 "At times content providers do attempt to provide content creators with revenue but at the cost of hindering content creators creativity.This ultimately leads to loss of revenue because of loss of subscribers.",
											 "This leads to content that is poor or content that is untrue to the content creator because they are trying to fit the status quo. They have to compy with coporate heads of websites in order to remain monetized(comply with censorship)",
											 "This leaves content creators trying to gather revenue through subscription based methods that can back fire due to people canceling their subscription,charge backs can occur due to forgetfulness of a monthly subscription, and those subscribe platforms may impose further restrictions.",
											 "These methods can backfire due to unfair policies or rules such as discrimination to opposing views of the site which can lead to bans",
											 "However, there is a solution with Tiplly",
											 "Tiplly allows content creators to receive monetization from viewers through tips small or large",
											 "Tiplly allows content viewers to promote content they want by tipping.",
											 "Content viewers can tip as low as 50 cents",
											 "Tiplly puts the power of monetization into content creators",
											 "Tiplly can be used by anyone around the world",
											 "Sign up for tiplly by simply logging in with your google account.",
											 "Download the tiplly FireFox extension for more usability and functionality.",
											 "Download the chrome extension for more usability and functionality."];
}
