import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute,Event, NavigationEnd } from "@angular/router";
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class SupportComponent implements OnInit {

  constructor(private router:Router,private route:ActivatedRoute ) {
    
  }

  ngOnInit() {
	 this.router.events.subscribe((event:Event) => {
		 if(event instanceof NavigationEnd)
		 {
			console.log(event.url);
            if(event.url == "/home/support")
			{
				this.router.navigate(["./","supportmain"],{relativeTo:this.route});
			}				
		 }
	 }); 
     this.router.navigate(["./","supportmain"],{relativeTo:this.route});
  }

}
