import {trigger,transition,useAnimation} from "@angular/animations";
import {fadeOutUp, fadeInUp,flipInX,flipOutX, lightSpeedIn, lightSpeedOut, flipInY,flipOutY,bounceInRight,bounceOutRight,zoomIn,zoomOut} from "ng-animate";
	export function flippingInX()
	{
	return trigger("flipInX",[transition("void => *", useAnimation(flipInX)),
	                          transition("* => void", useAnimation(flipOutX))]);
	}
	export function lightSpeedInOutTransition()
	{
		return trigger("lightSpeed",[transition("void => *", useAnimation(lightSpeedIn)),
									   transition("* => void", useAnimation(lightSpeedOut))]);
	}
	export function flippingInOnXaxis()
	{
	  return flippingInX();
	}
	export function lightSpeedInOut()
	{
		return lightSpeedInOutTransition();
	}