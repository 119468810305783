export class Miscellaneous {
	
	bankCountryCodes:Object = [{"Country":"United States","Abbreviation":"US"},
							   {"Country":"Australia","Abbreviation":"AU"},
							   {"Country":"Austria","Abbreviation":"AF"},
							   {"Country":"Belgium","Abbreviation":"BE"},
							   {"Country":"Canada", "Abbreviation":"CA"},
							   {"Country":"Czech Republic","Abbreviation":"CZ"},
							   {"Country":"Denmark-dk", "Abbreviation":"DK"},
							   {"Country":"Republic of cyprus","Abbreviation":"DZ"},
							   {"Country":"Estonia","Abbreviation":"EE"},
							   {"Country":"Finland","Abbreviation":"FL"},
							   {"Country":"France","Abbreviation":"FR"},
							   {"Country":"Germany","Abbreviation":"DE"},
							   {"Country":"Greece","Abbreviation":"GR"},
							   {"Country":"Hong Kong","Abbreviation":"HK"},
							   {"Country":"Ireland","Abbreviation":"IE"},
							   {"Country":"Latvia","Abbreviation":"LV"},
							   {"Country":"Liechtenstein","Abbreviation":"LI"},
							   {"Country":"Lithuania","Abbreviation":"LT"},
							   {"Country":"Luxemborg","Abbreviation":"LU"},
							   {"Country":"Malta","Abbreviation":"MT"},
							   {"Country":"Netherlands","Abbreviation":"NL"},
							   {"Country":"New Zealand","Abbreviation":"NZ"},
							   {"Country":"Poland", "Abbreviation":"PO"},
							   {"Country":"Poland","Abbreviation":"PL"},
							   {"Country":"Portugal","Abbreviation":"PT"},
							   {"Country":"Romania","Abbreviation":"RO"},
							   {"Country":"Singapore","Abbreviation":"SG"},
							   {"Country":"Slovakia","Abbreviation":"SK"},
		                       {"Country":"Spain","Abbreviation":"ES"},
							   {"Country":"Sweden","Abbreviation":"SE"},
							   {"Country":"Switzerland","Abbreviation":"CH"},
	                           {"Country":"Northern Ireland","Abbreviation":"UK"},
							   {"Country":"England","Abbreviation":"UK"},
	                           {"Country":"Scottland","Abbreviation":"UK"},
							   {"Country":"Wales","Abbreviation":"UK"}];
							   
							   
	CurrencyCode:Object=["United States Dollar - USD","Euro - EUR","Czech Koruna - CZK","Singapore Dollar -  SGD","Swedish Krona - SEK", "New Zealand Dollar -  NZD","Romanian Leu - RON","Yen - JPY","Danish krone - KR","Hong Kong Dollar - HKD","Australian Dollar - AUD","Polish Zloty - PLN","Swiss Frank - CHF","Pound - GBP", "Canadian Dollar - CAD"]
	bankAccountType:Array<String> = ["checking","savings"];
	refreshPrompt:string = "Click to Refresh";
	syncPrompt :string ="Click to revert to normal"
	searchPrompt:string = "Check to see if website is in list of websites.";
	deletePrompt:string = "Click to delete website.";
	uploadDocumentPrompt:string = "Please upload proof of identity document. examples are passport photo, government identity card, proof of business and more. Contact support for more information. ";
    uploadAddressDocumentPrompt:string = "Please upload proof of address document. Such documents include utility bills."; 
	videoPrompt:string = "Click to play video tutorial that covers this section.";
}
