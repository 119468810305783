import { Component, OnInit, ViewChild,ElementRef, EventEmitter, AfterViewInit } from '@angular/core';
import {Subject} from "rxjs";
import {UserInformationService} from "./../../services/user-information.service";
import {FormGroup, FormBuilder} from "@angular/forms";
import {FormSuggestionHelpers} from "./../../exportedValues/form-suggestion-helpers";
import {CurrencyConversionService} from "./../../services/currency-conversion-service.service";
import {Miscellaneous} from "./../../exportedValues/miscellaneous";
@Component({
  selector: 'app-tipply-information',
  templateUrl: './tipply-information.component.html',
  styleUrls: ['./tipply-information.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css','./../../../../css/semantic-ui-css-master/semantic.min.css','../../../../node_modules/purecss/build/pure-min.css']
})
export class TipplyInformationComponent implements  AfterViewInit {

  @ViewChild("usernameField",{static:false}) usernameField:ElementRef;
  @ViewChild("tipplyLink",{static:false}) userLink:ElementRef;
  @ViewChild("outGoingWalletAddress",{static:false}) outWalletAddressField:ElementRef;
  @ViewChild("tipllyCode",{static:false}) tipllyCodeLink:ElementRef;
  @ViewChild("preferredCurrencyE",{static:false}) preferredCurrencyE:ElementRef;
  eventHandler:EventEmitter<{[key:string]:string}>;
  tipLink:string = "";
  outWalletAddress:string = "";
  tipllyCodeForLink:string ="";
  formSuggestionHelper:FormSuggestionHelpers;
  hasSubmitted:boolean = false;
  connectionToLoadingModal:Subject<{[key:string]:string}>;
  updatesToLoadingModal:Subject<string>;
  connectionToSuccessModal:Subject<{[key:string]:string}>;
  tipllyCodeForLinkString:string = "";
  miscellaneous:Miscellaneous;
  preferredCurrency:string;
  constructor(formBuilder:FormBuilder, private userInformationService:UserInformationService, private currencyConversionService:CurrencyConversionService) { 
     this.formSuggestionHelper = new FormSuggestionHelpers();
	 this.eventHandler = new EventEmitter<{[key:string]:string}>();
	 this.connectionToLoadingModal = new Subject<{[key:string]:string}>();
	 this.updatesToLoadingModal = new Subject<string>();
	 this.connectionToSuccessModal = new Subject<{[key:string]:string}>();
	 this.miscellaneous = new Miscellaneous();
  }
  openUpdateModal(){
	  console.log("sending event");
	  this.eventHandler.emit({"modal":"open"});
	  this.eventHandler.subscribe((value) =>{
		  console.log(value);
		 if(value["modal"] == "open"){
			 this.hasSubmitted = false;
		 } 
		 if(value["finished"]){
			 
			 console.log("sending info");
			 if(this.hasSubmitted == false){
			  this.userInformationService.submitLiteCoinAddress(value["finished"]);
			  this.hasSubmitted = true;
			  this.connectionToLoadingModal.next({"Loading":"PlainLoading"});
			 }
		 } 
	  });
  }
  ngAfterViewInit() {
	  this.usernameField.nativeElement.innerHTML = this.userInformationService.getTipUsername();
	  this.tipLink = "https://tiplly.com/#/tip/" + this.userInformationService.getTipUserLink();
	  this.tipllyCodeForLinkString = this.userInformationService.getTipllyCodeForLink();
	  this.outWalletAddress = this.userInformationService.getTipUserWalletAddress();
	  console.log("out wallet address is: " + this.outWalletAddress);
	  this.outWalletAddressField.nativeElement.value = this.outWalletAddress;
	  this.tipllyCodeLink.nativeElement.value = this.userInformationService.getTipllyCodeForLink();
	  /*this.preferredCurrencyE.nativeElement.innerHTML */ this.preferredCurrency= this.userInformationService.getTipUser().preferredCurrency;
	  
	  console.log(this.userInformationService.getTipUser());
	  this.userInformationService.getUserInformationSubject().subscribe((value) =>{

		 if(value["updateLiteCoinAddress"] == "successful"){
			 
			this.updatesToLoadingModal.next("GotInformationNowClose");
            this.connectionToSuccessModal.next({"show":"open"});
            this.outWalletAddress = this.userInformationService.getTipUserWalletAddress();
			this.outWalletAddressField.nativeElement.value = this.outWalletAddress;
			
		 }
		 else if(value["updatePreferredCurrency"]){
        this.updatesToLoadingModal.next("GotInformationNowClose");
         this.connectionToSuccessModal.next({"show":"open"});
		 } 
		 else if (value["err"]){
			 this.updatesToLoadingModal.next("GotInformationNowClose");
		 }
	  });
	  
	  //this.userLink.nativeElement.value = this.tipLink;
	  console.log(this.usernameField);
	 
  }
  setPreferredCurrency(value:string){
  	this.preferredCurrency = value;
  }
  updatePreferredCurrency(){
  	if(this.preferredCurrency != null || this.preferredCurrency != ""){
     this.connectionToLoadingModal.next({"Loading":"PkainLoading"});
     this.userInformationService.setUsersPreferredCurrency(this.preferredCurrency);
   }
  }

}
