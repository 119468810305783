import { Injectable } from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import {RequestOptions} from "@angular/http";

@Injectable()
export class SecurityService {

  private securityCode:string;
  private username_:string;
  constructor() {

  }
  setUserNameAndCode(username:string,code:string)
  {
	  this.securityCode = code;
	  this.username_ = username;
	  this.generateAndReturnHeaders();
	  console.log("headers are ");
	  var headers = new HttpHeaders().set("username",`${this.username_}`).set("code",`${this.securityCode}`);
	 console.log(headers);
	  console.log("egeusername is " + this.username_ + "code is " + this.securityCode);
	  
  }
  generateAndReturnPostHeaders():HttpHeaders
  {
	  var headers = new HttpHeaders().set("username",`${this.username_}`)
									 .set("code",`${this.securityCode}`)
									 .set("Content-Type","application/json;charset=UTF-8")
									 .set("Access-Control-Allow-Headers","*")
	                                 .set("Access-Control-Expose-Headers","*")
									 .set("Cache-Control","no-cache, no-store, must-revalidate, post-check=0, pre-check=0")
									 .set("Expires","0")
									 .set("Pragma","no-cache")
									 .set("Accept","*/*");
	 
	  return headers; 
  }
  generateAndReturnHeaders():HttpHeaders
  {
	  console.log("headers are ");
	  var headers = new HttpHeaders().set("username",`${this.username_}`)
	                                 .set("code",`${this.securityCode}`)
									 //.set("Access-Control-Allow-Headers","*")
	                                 .set("Cache-Control","no-cache, no-store, must-revalidate, post-check=0, pre-check=0")
	                                 .set("Expires","0")
									 .set("Pragma","no-cache");
	                                 //.set("Access-Control-Expose-Headers","*");
	 // var options = new RequestOptions({headers:headers});
	 
	 console.log(headers);
	  return headers;
  }
  
}
