export class Website {
	public time:Date;
	constructor(private website:string, private verified:Number, private websitecode:string)
	{
		
	}
	setDate(date:Date):void
	{
		this.time = date;
	}
	getTimeLeftBetweenBan()
	{
		var firstDate:Date = new Date();
		var secondDate:Date = new Date(this.time.getTime() + 24 * 60 * 60 * 1000);
		if(firstDate > secondDate)
		{
			return 0;
		}
		else{
			var difference = (secondDate.getTime() - firstDate.getTime())/1000;
		    return difference;
		}
	}
	
	getMinuteOfDate():any
	{
		return this.time.getMinutes();
	}
	addZero(number, length){
    var str = "" + number
    while (str.length < length) {
        str = '0'+str
    }
    return str
   }
    readableTimeZone(offset) {
		return ((offset<0? '+':'-') + this.addZero((Math.abs(offset/60)), 2) + this.addZero(Math.abs(offset%60), 2));
	}	
}
