import { Component, OnInit,Input, ViewChild, ElementRef } from '@angular/core';
import {Subject} from "rxjs";
import {NgxSmartModalService} from "ngx-smart-modal";
@Component({
  selector: 'input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class InputDialogComponent implements OnInit {

  @Input("InputDialogConnection") connectionDialog:Subject<{[key:string]:string}>; 
  @Input("UpdatesConnection") updatesConnection:Subject<string>; 
  @ViewChild("inputText",{static:false}) inputElement:ElementRef;
  inputPrompt:string = "";
  show:string;
  constructor(private ngxSmartModalService:NgxSmartModalService) {
     this.connectionDialog = new Subject<{[key:string]:string}>();
	 this.updatesConnection = new Subject<string>();
  }

  ngOnChanges(changes)
  {
	  if(changes["connectionDialog"]) 
	  {
		  console.log(changes["connectionDialog"]);
		  console.log(this.connectionDialog);
		  console.log("we have received change");
		  
		  this.connectionDialog.subscribe((value) =>{
			console.log(value);  
            if(value["InputDialog"] == "show"){			
			  this.ngxSmartModalService.open("InputDialog",false);
			}
		  });
		  this.updatesConnection.subscribe((value) =>{
			 this.show = ((value as unknown) as string);
			 if(value == "Email")
			 {
			   this.inputPrompt = "Please enter your Email: "; 
			   
			 }
			 else if (value == "Website")
			 {
				 this.inputPrompt = "Please enter your website to find total revenue for: "; 
			 }
		  });
	  }
	  else if (changes["updatesConnection"])
	  {
		  this.updatesConnection.subscribe((value) =>{
			 this.inputPrompt = (value as string); 
		  });
	  }
  }
  ngOnInit() {
  }
  
  submitAndClose()
  {
	  var value = this.inputElement.nativeElement.value;
	  console.log(value);
	  this.ngxSmartModalService.get("InputDialog").close();
	  this.connectionDialog.next({"returnValue":value});
  }

}
