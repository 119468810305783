import { Component, OnInit,Input } from '@angular/core';
import{Subject} from "rxjs";
import {NgxSmartModalService} from "ngx-smart-modal";
@Component({
  selector: 'website-showing',
  templateUrl: './website-showing.component.html',
  styleUrls: ['./website-showing.component.css']
})
export class WebsiteShowingComponent implements OnInit {

  public status_:string;
  @Input() public subjectObservableConnection:Subject<{[key:string]:string}>;
  constructor(private ngxSmartModalService:NgxSmartModalService) 
  { 
    this.subjectObservableConnection = new Subject<{[key:string]:string}>();
  }
  ngOnChanges(changes)
  {
	  if(changes["subjectObservableConnection"])
	  {
		  this.subjectObservableConnection.subscribe((value) =>{
			 this.status_ = value["send"];
			 this.ngxSmartModalService.get("websiteShowingResult").open();
				 
		  });
	  }
  }
  ngOnInit() {
  }

}
