import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from "@angular/router"; 
import { map } from 'rxjs/operators';
import {TipRelatedSecurityService} from "./../services/tip-related-security.service";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch'
@Injectable()
export class TipLinkGuard implements CanActivate {
  constructor(private tipRelatedSecurityService:TipRelatedSecurityService, private router:Router){
	  
  }  
	
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     var tipLink = next.params.tipLink;
     console.log(tipLink);	
     console.log("here before return");	 
	 return this.tipRelatedSecurityService.checkIfTipLinkIsValid(tipLink).map(e => {
            if (e["tipLink"] == true) {
				console.log(e);
                return true;
				
            }
			else 
			{
				this.router.navigate(["/",'error'],{queryParams:{"reveal":"InvalidLink"}});
			}
        }).catch(() => {
            //this.router.navigate(['/','receipt']);
			console.log("tip link is invalid");
			this.router.navigate(["/",'errpr'],{queryParams:{"reveal":"InvalidLink"}});
            return Observable.of(false);
        });
	 
	 
   
  }
}
