import { Component, OnInit, Input, ViewChild,ElementRef } from '@angular/core';
import {TimeGroupTip}   from "./../../Models/time-group-tip";
import {BarGraphModel}  from "./../../Models/bar-graph-model";
import {LineGraphModel} from "./../../Models/line-graph-model";
import {NgxSmartModalService} from "ngx-smart-modal";
import {TipGraphInformationService} from "./../../services/tip-graph-information.service";
import {Subject} from "rxjs";
import {DatePipe} from "@angular/common";
import {Miscellaneous} from "./../../exportedValues/miscellaneous";
import {CurrencyConversionService} from "./../../services/currency-conversion-service.service";
import {Renderer2} from "@angular/core";
import { BaseChartDirective } from 'ng2-charts';
import {timer } from "rxjs";
@Component({
  selector: 'graphs-showing',
  templateUrl: './graphs-showing.component.html',
  styleUrls: ['./graphs-showing.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css']
})
export class GraphsShowingComponent implements OnInit {

  @ViewChild("firstDateBetween",{static:false}) firstDateBetween:ElementRef;
  @ViewChild("secondDateBetween",{static:false}) secondDateBetween:ElementRef;
  @ViewChild("dayDate",{static:false}) dayDate:ElementRef;
  @ViewChild("yearInput",{static:false}) yearInput:ElementRef;
  @ViewChild("firstMonth",{static:false}) firstMonth:ElementRef;
  @ViewChild("secondMonth",{static:false}) secondMonth:ElementRef;
  @ViewChild("errors",{static:false}) errorDiv:ElementRef;
  @ViewChild("monthDate",{static:false}) monthInput:ElementRef;
  @ViewChild(BaseChartDirective,{static:false}) chart: BaseChartDirective;
  
  public barGraphModelInput:BarGraphModel;
  public lineGraphModelInput:LineGraphModel; 
  public gotInformation:boolean = false;
  public selection:string;  
  public firstDate:Date;
  public secondDate:Date;
  @Input() public showGraphObservable:Subject<string>;
  public currencyInformation:Miscellaneous;
  public chartToShow:string = "";
  public errorsOfChartToShow:string = "";
  public currentCurrency:string = "";
  public showLoading:boolean = false;
  public showUpdateRefreshDone:boolean = false;
  public showErrorOfResult:boolean = false;
  public customClass:Array<string> = ["'large-modal'"];
  public priceOfLiteCoin:number;
  public inputedLiteCoinPrice:number;
  constructor(private ngxSmartModalService:NgxSmartModalService,private tipGraphInformationService:TipGraphInformationService,private currencyConversionService:CurrencyConversionService, private datePipe:DatePipe,private renderer:Renderer2) 
  {
     this.setUp();
	 this.currencyInformation = new Miscellaneous();
	 
  }
  changeCurrency()
  {
  	if(this.inputedLiteCoinPrice == null || this.inputedLiteCoinPrice == 0){
  		this.inputedLiteCoinPrice = this.getPriceOfLiteCoinInCurrency();
  	}
  	console.log("inoutedLiteCoinPrice is " + this.inputedLiteCoinPrice);
	  var cur_compare:any = null;
	  var tempCurrency = this.currentCurrency.split("-")[1].trim();
	  this.showLoading = true;
        this.showUpdateRefreshDone = true;
	   var currencyTime = timer(100);
	   
		   currencyTime.subscribe(() => {
			// console.log(this.currentCurrency.split("-")); 
			 var tempCurrency = this.currentCurrency.split("-")[1].trim();   
		   var currency_object = this.currencyConversionService.getCurrencyConversionObject();//this.currencyConversionService.getCurrencyConversion(tempCurrency);
		   console.log(currency_object)
       cur_compare = currency_object;
       console.log(cur_compare);
			 //this.lineGraphModelInput.setConversionObject(cur_compare);
			 this.lineGraphModelInput.setCurrentCurrency(tempCurrency);
			 this.lineGraphModelInput.setLiteCoinUSDPice(this.inputedLiteCoinPrice);
			 this.barGraphModelInput.setCurrentCurrency(tempCurrency);
			 this.barGraphModelInput.setLiteCoinPriceUSD(this.inputedLiteCoinPrice);
			// this.barGraphModelInput.setConversionObject(cur_compare);

			 this.lineGraphModelInput.setLiteCoinUSDPice(this.inputedLiteCoinPrice);
			 this.barGraphModelInput.updateBarChart();
			 this.lineGraphModelInput.updateLineChartData();
			 this.showUpdateRefreshDone = false;
			 this.showLoading = false;
                
		});
		  	  
  }
  getPriceOfLiteCoinInCurrency(){
  	var tmp_currency_object = this.currencyConversionService.getCurrencyConversionObject() as any; 
  	if(this.currentCurrency.split("-")[1].toLowerCase().trim() != "usd"){
  	//console.log("Here is getPriceOfLiteCoinInCurrency()");
  	var shortCurrency = this.currentCurrency.split("-")[1].toLowerCase();

  	//console.log(this.currencyConversionService.getCurrencyConversionObject()[shortCurrency.trim()]);
  	//console.log(this.currencyConversionService.getCurrencyConversionObject()[this.userInformationService.getTipUser().preferredCurrency.split("-")[1].toLowerCase().trim()]);
  	this.priceOfLiteCoin = (tmp_currency_object[this.currentCurrency.split("-")[1].trim().toLowerCase()])["rate"] * this.currencyConversionService.getLTCUSDPrice(); 
  	return (((((this.currencyConversionService.getCurrencyConversionObject()[this.currentCurrency.split("-")[1].toLowerCase().trim()]))["rate"] ) as any ) as number) * this.currencyConversionService.getLTCUSDPrice();
   }else{
   	this.priceOfLiteCoin = this.currencyConversionService.getLTCUSDPrice(); 
   	return this.currencyConversionService.getLTCUSDPrice();
   }
  }
  setUp()
  {
	  this.tipGraphInformationService.getGraphInformationObservable().subscribe((value) =>{
		  var array_of_time_group:Array<TimeGroupTip> = (value as Array<TimeGroupTip>);
		  this.lineGraphModelInput = new LineGraphModel(array_of_time_group,this.datePipe);
		    this.barGraphModelInput = new BarGraphModel(array_of_time_group,this.datePipe);
		  
		  console.log("received the tips in compoenet sss");
		
		  this.gotInformation = true;
		  if(this.currentCurrency == "")
		  {
			  this.currentCurrency = "United States Dollar - USD";
		  }
		  var cur_compare:any = null;
		  var tempCurrency = this.currentCurrency.split("-")[1].trim();
		  var currencyTime = timer(100);
		   currencyTime.subscribe(() => {
			// console.log(this.currentCurrency.split("-")); 
			 var tempCurrency = this.currentCurrency.split("-")[1].trim();   
		     var currency_object = this.currencyConversionService.getCurrencyConversionObject();//this.currencyConversionService.getCurrencyConversion(tempCurrency);
		      console.log(currency_object)
             cur_compare = currency_object;
             console.log(cur_compare);
			 this.lineGraphModelInput.setLiteCoinUSDPice(this.currencyConversionService.getLTCUSDPrice())
			 this.lineGraphModelInput.setConversionObject(cur_compare);
			 this.lineGraphModelInput.setCurrentCurrency(tempCurrency);
			 this.lineGraphModelInput.updateLineChartData();
             this.barGraphModelInput.setLiteCoinPriceUSD(this.currencyConversionService.getLTCUSDPrice());
			 this.barGraphModelInput.setCurrentCurrency(tempCurrency);
			 this.barGraphModelInput.setConversionObject(cur_compare);
			 this.barGraphModelInput.updateBarChart();
			 this.showLoading = false;
                
		});
		  
		  
		  
		  
		  
		  
 		 
		  //console.log(this.lineGraphModelInput);
		  //console.log(this.bargraphModelInput);
		 
		  
		 
  		 
		// console.log(this.barGraphModelInput.barChartDataSets);
	  });
	  this.tipGraphInformationService.getGraphInformationErrorObservable().subscribe((value) =>{
		  this.showErrorOfResult = true;
	  });
  }
  checkValidityOfDates()
  {
	  var errors:string = "";
	  this.errorsOfChartToShow = errors;
	  switch(this.selection)
	  {
		  case 'BetweenMonths':
		       console.log("Between Months called");
			   console.log(this.firstMonth.nativeElement.value.toString());
		       if(this.firstMonth.nativeElement.value.toString() == "")
			   {
				   errors += " <p [ngStyle] = '{'margin-left': '45px'}'> Select the first month </p>";
				   console.log("error received");
			   }
			   if(this.secondMonth.nativeElement.value.toString()  == "")
			   {
				   errors += "<p style = 'margin-left:45px;'> select the second Month  </p>";
			   }
		        if((this.firstMonth.nativeElement.value.toString().split("-")[1] as number) > (this.secondMonth.nativeElement.value.toString().split("-")[1] as number))
				{
					if((this.firstMonth.nativeElement.value.toString().split("-")[0] as number) == (this.secondMonth.nativeElement.value.toString().split("-")[0] as number))
					{
					  errors += "First Month is Greater than second Month <p></p>"
					}
					
				}
				if((this.firstMonth.nativeElement.value.toString().split("-")[0] as number) > (this.secondMonth.nativeElement.value.toString().split("-")[0] as number) )
				{
						errors += "First Month Year is greater than second Months Year <p></p>";
				}
				break;
		 case "BetweenTwoDates":
		        if(this.firstDateBetween.nativeElement.value == "")
				{
					errors += "First Date has not been selected." + "<p></p>";
					
				}
				if(this.secondDateBetween.nativeElement.value == "")
				{
						errors += "Second Date has not been selected." + "<p></p>";
				}
				if(new Date(this.firstDateBetween.nativeElement.value) > new Date(this.secondDateBetween.nativeElement.value) )
				{
					errors += "First date is greater than second date " + "<p></p>";
				}
				if(this.firstDateBetween.nativeElement.value == this.secondDateBetween.nativeElement.value)
				{
					errors += "The two dates cannot be the same " + "<p></p>";
					
				}
			   break;
		 case "Month":
		    if(this.monthInput.nativeElement.value.toString() == "")
			{
				errors += "Please select a month. " + "<p></p>";
			}
			if((this.monthInput.nativeElement.value.toString().split("-")[0] as number) < 2019)
            {
				errors += "Please select a date greater than or equal to 2019 " + "<p></p>";
			}				
		       break;
		 case "Year":
		       if((this.yearInput.nativeElement.value.toString() ==  ""))
			   {
				   errors += "Please enter a value for the year. " + "<p></p>";
			   }
		       if((this.yearInput.nativeElement.value.toString() as number) < 2019)
			   {
				   errors += "Year must be 2019 or greater. " + "<p></p>";
			   }
			   break;
		 case "Day":
		         if((this.dayDate.nativeElement.value.toString() == ""))
				 {
					 errors += "Please select a day you would like to view." + "<p></p>";
				 }
		         break;
		
			  
	  }
	  this.errorsOfChartToShow = errors;
  }  
  submitRequest()
  {
  	this.inputedLiteCoinPrice = this.getPriceOfLiteCoinInCurrency();
	  this.showErrorOfResult = false;
	  this.checkValidityOfDates();
	  
	  if(this.errorsOfChartToShow == "")
	  {
		  this.showLoading = true;
		  
		  switch(this.selection)
		  {
			  case "Month":
			       var dateOne:Date = new Date();
				   console.log((this.monthInput.nativeElement.value.toString() as number));
				   dateOne.setMonth((this.monthInput.nativeElement.value.toString().split("-")[1] as number));
				   dateOne.setFullYear((this.monthInput.nativeElement.value.toString().split("-")[0] as number));
				   console.log(dateOne);
				   dateOne.setDate(1);
				   dateOne.setHours(0);
				   dateOne.setMinutes(0);
				   dateOne.setSeconds(0);
				   this.tipGraphInformationService.getTipsInformationForMonth(dateOne);
                   break;
              case "Day":
                    var dayDateEx:Date = new Date();
                    console.log(this.dayDate.nativeElement.value.toString());
                    dayDateEx = new Date(this.dayDate.nativeElement.value.toString());
					dayDateEx.setMonth((this.dayDate.nativeElement.value.toString().split("-")[1] as number) - 1);
					dayDateEx.setFullYear(this.dayDate.nativeElement.value.toString().split("-")[0] as number);
					dayDateEx.setDate(this.dayDate.nativeElement.value.toString().split("-")[2] as number);
					dayDateEx.setHours(0);
					dayDateEx.setMinutes(0);
					dayDateEx.setSeconds(0);
					console.log(dayDateEx);
					this.tipGraphInformationService.getTipsInformationForDay(dayDateEx);
					break;
			 case "BetweenTwoDates":
			        var dateOne:Date = new Date(this.firstDateBetween.nativeElement.value.toString());
					dateOne.setHours(0);
					dateOne.setMinutes(0);
					dateOne.setSeconds(0);
					var dateTwo:Date = new Date(this.secondDateBetween.nativeElement.value.toString());
					dateTwo.setHours(0);
					dateTwo.setMinutes(0);
					dateTwo.setSeconds(0);
				    this.tipGraphInformationService.getTipInformationBetweenTwoDates(dateOne,dateTwo);
					break; 	
              case "BetweenMonths":
                    var dateFirstMonth:Date = new Date();
					dateFirstMonth.setMonth((this.firstMonth.nativeElement.value.toString().split("-")[1] as number) - 1);
					dateFirstMonth.setFullYear(this.firstMonth.nativeElement.value.toString().split("-")[0] as number);
					dateFirstMonth.setHours(0);
					dateFirstMonth.setMinutes(0);
					dateFirstMonth.setSeconds(0);
					var dateSecondMonth:Date = new Date();
					dateSecondMonth.setMonth((this.secondMonth.nativeElement.value.toString().split("-")[1] as number)  - 1);
					dateSecondMonth.setFullYear(this.secondMonth.nativeElement.value.toString().split("-")[0] as number );
					//var dateSecondMOnth:Date = new Date();
					console.log(dateFirstMonth);
					console.log(dateSecondMonth);
					this.tipGraphInformationService.getTipsInformationTotalForMonths(dateFirstMonth,dateSecondMonth);
					break;
              case "Year":
                    var dateFirstMonthOfYear = new Date();
                    dateFirstMonthOfYear.setMonth(0);
					dateFirstMonthOfYear.setDate(1);
					dateFirstMonthOfYear.setFullYear(this.yearInput.nativeElement.value as number)
                    var dateLastMonthOfYear = new Date();
                    dateLastMonthOfYear.setFullYear(this.yearInput.nativeElement.value as number);
                    dateLastMonthOfYear.setMonth(11);
					dateLastMonthOfYear.setDate(31);
					console.log(dateLastMonthOfYear);
                    this.tipGraphInformationService.getTipsInformationTotalForMonths(dateFirstMonthOfYear,dateLastMonthOfYear); 					
					break;     					
			 
		  }
	  }
  }
  setSelectionOfRange(selection:string)
  {
	  this.selection = selection;
	  this.errorsOfChartToShow = "";
	  this.gotInformation = false;
  }
  
  ngOnChanges(changes)
  {
	 if(changes["showGraphObservable"])
	 {
		 this.showGraphObservable.subscribe((value:string) => {
			 if(value == "showChartBar")
			 {
				 this.chartToShow = "bar";
				 console.log("receivedToShow");
				 this.ngxSmartModalService.get("chartModal").open();
			 }
			 else
			 {
				 this.chartToShow = "line";
				 this.ngxSmartModalService.get("chartModal").open();
			 }
		 });
	 }		 
  }
  ngOnInit() {
  }
  closeModal()
  {
	  this.ngxSmartModalService.get("chartModal").close();
  }
  
  setChartType(value:string)
  {
	  console.log(`changed Chart type to ${value}`);
	 
	  this.chartToShow = value;
	  
  }
 /* getPriceOfLiteCoinInCurrency(){
  	if(this.currentCurrency.includes("USD")){
  		this.priceOfLiteCoin = this.currencyConversionService.getLTCUSDPrice(); 

  	}else{
  		  var tmp_currency_object = this.currencyConversionService.getCurrencyConversionObject() as any; 
  		  console.log(tmp_currency_object);
  			this.priceOfLiteCoin = (tmp_currency_object[this.currentCurrency.split("-")[1].trim().toLowerCase()])["rate"] * this.currencyConversionService.getLTCUSDPrice(); 
  	}
  } */
  setCurrentCurrency(value:string)
  {
	  this.currentCurrency = value;
	  this.getPriceOfLiteCoinInCurrency();
	  console.log("current curreny is " + this.currentCurrency);
  }

  resetInputedPriceOfLiteCoin(){
  	this.inputedLiteCoinPrice = this.getPriceOfLiteCoinInCurrency();
  	
  }

}
