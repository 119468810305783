import { Component, OnInit, Inject,AfterViewInit } from '@angular/core';
import {Router,ActivatedRoute,Route, RoutesRecognized} from "@angular/router";
import {NgxSmartModalService} from "ngx-smart-modal";
import {timer} from 'rxjs';
import{UserInformationService} from "./../../services/user-information.service";
import { filter,pairwise } from 'rxjs/operators';
import {DOCUMENT} from "@angular/common";
import {rotation} from "./../../../animations/rotateAnimation.animation";
import {VideoService} from "./../../services/video-service.service";
import {Subject} from "rxjs";
@Component({
  selector: 'app-primary',
  templateUrl: './primary.component.html',
  styleUrls: ['./primary.component.css','./../../../../css/semantic-ui-css-master/semantic.min.css'],
  animations:[rotation()]
})
export class PrimaryComponent implements OnInit,AfterViewInit {

  private my_timer;
  currentRoute:String = "" 
  showLoading:string = "N/A"; 
  rotationAnimation = "default";
  showAnimation:boolean = true;  
  private connectionToVideoModal:Subject<{[key:string]:string}>;
  constructor(private router:Router,private route:ActivatedRoute, @Inject(DOCUMENT)private documentD:Document, private ngxSmartModalService:NgxSmartModalService,private userInformationService:UserInformationService,
              private videoService:VideoService) {
	 this.connectionToVideoModal = new Subject<{[key:string]:string}>();
			
  }
  
  
   
  ngOnInit() {
	  
              this.router.events
            .pipe(filter((e: any) => e instanceof RoutesRecognized),
                pairwise()
            ).subscribe((e: any) => {
				this.showAnimation = false;
				this.rotationAnimation = "fini";
                console.log(e[0].urlAfterRedirects);
  				var pastUrl = e[0].urlAfterRedirects;
				console.log(pastUrl);
				
				if(pastUrl.includes("supportconversation") || pastUrl.includes("pdfView")){
				   this.currentRoute = "support";	
				   this.router.navigate(["/","support","supportmain"],{relativeTo:this.route});
				   
				}
				else{
					
				}
            });
				this.my_timer = timer(10000); 
				this.documentD.getElementById("overDiv").style.opacity = "0";	
				this.my_timer.subscribe(t=> {  
				 this.documentD.getElementById("overDiv").style.opacity = "1";	
				  this.documentD.getElementById("LoadingDiv").style.opacity = "0";
				 this.documentD.getElementById("LoadingDiv").style.display = "none";	  
				//console.log("Tick");  
				 //document.getElementById("test").click();	
				 if(this.userInformationService.shouldShowWelcomeModal())
				 {
					 
				 // this.ngxSmartModalService.getModal('WelcomeModal').open();
				 }
				 else
				 {
					 
				 }
				
				
				});
	    
				//  document.getElementById("test").click();
				 
			
			
	  
			 
 
	//this.ngxSmartModalService.getModal('LoadingModal').open()  
	
	window.scrollTo(0,0);
	
  }
  showIntroductionModalVideo()
  {
	  
  }
  ngAfterViewInit()
  {
	  this.rotationAnimation = "rotated";
  }
   continueRotation()
  {
	console.log("continue Rotation called");  
	if(this.rotationAnimation != "fini")
	{
		this.rotationAnimation = this.rotationAnimation == "default" ? "rotated" : "default";
	}	
  }
  navigateTo(component:String)
  {
	this.currentRoute = component;
	this.rotationAnimation = "rotated";
	this.rotationAnimation = "default";
	this.showAnimation = false;
    switch(component)
	{
		case "profile": this.router.navigate(["./","profile","tipllyInformation"],{relativeTo:this.route});
		                       break;
		case "tipInformation": this.router.navigate(["./","tipinformation"],{relativeTo:this.route});
		                       break;
		case "websiteInformation": this.router.navigate(["./","websiteIntegrationTutorial"],{relativeTo:this.route});
						break;				
		case "support": this.router.navigate(["./","support"],{relativeTo:this.route});	
                        break;
        case "logout": this.router.navigate(["/","landing"]);
                       break;		
		       
	}	
	
  }

}
