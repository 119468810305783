import { Injectable,Inject } from '@angular/core';
import {Http, Response} from "@angular/http";
import {UserInformationService} from "./user-information.service";
import {ChargeTip} from "./../Models/charge-tip.model";
import {Receipt} from "./../Models/receipt.model";
import {Subject,Observable} from "rxjs";
import 'rxjs/add/operator/publishReplay';

@Injectable()
export class TipExecutionService {

  private tipExecutionServiceSubjectObservable:Subject<{[key:string]:string}>;
  private tipExecutionHappended:Observable<{[key:string]:string}>;
  baseUrl_:string;
  baseUrlActualStripe_:string;
  private current_receipt:string;
  constructor(private http:Http, private userInformationService:UserInformationService, @Inject("base_href") private base_href:string) {
	  this.tipExecutionServiceSubjectObservable = new Subject<{[key:string]:string}>();
      this.tipExecutionHappended = this.tipExecutionServiceSubjectObservable.publishReplay(3).refCount();
	  this.baseUrl_ = `${base_href}/tip`;
	  this.baseUrlActualStripe_ = `${base_href}/stripeTip`;
	
	
  }
  public sendRecentTip(chargeTip:ChargeTip)
  {
	  var json_value:string = JSON.stringify(chargeTip);
	  this.current_receipt = json_value;
	  this.tipExecutionServiceSubjectObservable.next({"chargeTip":json_value});
	  this.tipExecutionServiceSubjectObservable.next({"chargeTip":json_value});
  }
  public getReceipt()
  {
	  return this.current_receipt;
  }
  public getTipExecutionHappened()
  {
	  return this.tipExecutionHappended;
  }
  public gettipExecutionServiceSubjectObservable()
  {
	  return this.tipExecutionServiceSubjectObservable;
  }
 
  public getTipUUID():void
  {
	  var url_ = `${this.baseUrl_}/getTipId`;
	  this.http.get(url_).subscribe((success) => {
			console.log(success);
			var tip_uuid = success["_body"];
	  this.tipExecutionServiceSubjectObservable.next({"tip_uuid":tip_uuid});			
	  },
	  (err) => {
		  
	  });
  }
  public getTipToken(chargeTip:ChargeTip,tipLink:string)
  {
	  var url = `${this.baseUrlActualStripe_}/createPaymentIntent?tipLinkUrl=${tipLink}`;
	  this.http.post(url,chargeTip).subscribe((success)=>{
		  //console.log("succeeded");
		  var tipToken = success["_body"];
	      this.tipExecutionServiceSubjectObservable.next({"tip_token":tipToken});
	  },
	  (err) =>{
		   this.tipExecutionServiceSubjectObservable.next({"tip_error":err});
	  });
  }
  chargeTip(chargeTip:ChargeTip,tipLink:string,clientSecret:string)
  {
	  var url = `${this.baseUrl_}/submitTip/${tipLink}?stripeTransactionId=${clientSecret}`;
	  this.http.post(url,chargeTip).subscribe((success)=>{
		  //console.log("succeeded");
		  
	      this.tipExecutionServiceSubjectObservable.next({"tip_success":"successfuly tipped"});
	  },
	  (err) =>{
		   this.tipExecutionServiceSubjectObservable.next({"tip_error":err});
	  });
  }
  
  emailReceipt(receipt:Receipt, email:string)
  {
	  var url = `${this.baseUrl_}/createReceipt?email=${email}`;
	  this.http.post(url,receipt).subscribe((success) =>{
		  console.log(success);
		  this.tipExecutionServiceSubjectObservable.next({"emailReceipt":"success"});
	  },(err) =>{
		  var error = err.status;
		  this.tipExecutionServiceSubjectObservable.next({"error":error});
		  //console.log(err);
	  });
  }
}
